define('juridico-sengerj/controllers/dashboard/relatorio-envio-ocorrencias/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: ['criadaPorAdvogado', 'porAdvogadoId', 'porTipoOcorrencia', 'naoEnviadoGrupoReclamante', 'enviadoGrupoReclamante', 'porDataCriacaoIni', 'porDataCriacaoFim'],

    columnsTitle: ["Pasta", "Processo", "Descrição", "Advogado(a)", "Data", "Criação", "Grupos enviados", "Data de envio"],

    columnsPdf: {
      "Pasta": "codigoPasta",
      "Processo": "pasta.numeroProcesso",
      "Descrição": "tipoOcorrencia.descricao",
      "Advogado(a)": "nomeAdvogado",
      "Data": "dataOcorrenciaFormatado",
      "Criação": "createdAtFormatado",
      "Grupos enviados": "gruposEnviadosSentence",
      "Data de envio": "dataGruposEnviadosSentence"
    },

    columnStylesPdf: {
      0: { columnWidth: "wrap" },
      1: { columnWidth: "wrap" },
      2: { columnWidth: "wrap" },
      3: { columnWidth: "auto" },
      4: { columnWidth: "auto" },
      5: { columnWidth: "auto" },
      6: { columnWidth: "auto" },
      7: { columnWidth: "auto" },
      8: { columnWidth: "auto" }
    },

    includePdf: "pasta, tipo_ocorrencia, grupos_notificados",

    filtersValuesToShow: null,

    actions: {
      applyFilters: function applyFilters(filters) {
        _ember['default'].get(this, "apply-filters")(filters);
      }
    }
  });
});