define('juridico-sengerj/models/juridico/homologacao/agendamento', ['exports', 'juridico-sengerj/models/abstract-model', 'ember-data', 'ember', 'ember-cp-validations'], function (exports, _juridicoSengerjModelsAbstractModel, _emberData, _ember, _emberCpValidations) {
	var computed = _ember['default'].computed;
	var alias = _ember['default'].computed.alias;

	var Validations = (0, _emberCpValidations.buildValidations)({
		profissional: [(0, _emberCpValidations.validator)('belongs-to')],
		emails: [(0, _emberCpValidations.validator)('presence-array', { message: "É necessário informar pelo menos um E-mail" })],
		telefones: [(0, _emberCpValidations.validator)('presence-array', { message: "É necessário informar pelo menos um Telefone" })]
	});

	exports['default'] = _juridicoSengerjModelsAbstractModel['default'].extend(Validations, {
		empresa: _emberData['default'].attr('string'),
		motivo: _emberData['default'].attr('string'),
		dataAdmissao: _emberData['default'].attr('date'),
		dataDesligamento: _emberData['default'].attr('date'),
		motivoDesligamento: _emberData['default'].attr('date'),
		dataHorario: _emberData['default'].attr('date'),
		createdAt: _emberData['default'].attr('date'),
		statusReserva: _emberData['default'].attr('enum'),
		dataAvisoPrevio: _emberData['default'].attr(),
		migrado: _emberData['default'].attr(),
		usuario: _emberData['default'].belongsTo(),
		profissional: _emberData['default'].belongsTo(),
		profissionalUnidade: _emberData['default'].belongsTo(),
		atendimento: _emberData['default'].belongsTo('juridico/homologacao/atendimento'),

		emails: alias("profissional.pessoa.emails"),
		telefones: alias("profissional.pessoa.telefones"),

		quemAgendou: alias("usuario.pessoa.nome"),

		cpf: alias("profissional.cpf"),
		dataAdmissao: alias("profissionalUnidade.dataAdmissao"),
		dataDesligamento: alias("profissionalUnidade.dataDesligamento"),

		nomeFantasiaEmpresa: computed("profissionalUnidade.nomeFantasia", function () {
			return this.get("profissionalUnidade.nomeFantasia") || "Ainda Não Informado";
		}),

		nomeProfissional: computed("profissional.pessoa.nome", function () {
			return this.get("profissional.pessoa.nome") || "Ainda Não Informado";
		}),

		removerOuCancelar: function removerOuCancelar(motivo) {
			if (this.belongsTo("atendimento").id()) {
				this.setProperties({
					motivo: motivo,
					statusReserva: { value: 'cancelado' }
				});
			} else {
				this.deleteRecord();
			}

			return this.save();
		}
	});
});