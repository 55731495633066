define('juridico-sengerj/transforms/date', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports['default'] = _emberData['default'].DateTransform.extend({
    deserialize: function deserialize(serialized) {
      var type = typeof serialized;

      if (type === "string" && serialized.indexOf("-") != -1 && serialized.length === 10) {
        var _serialized$split$map = serialized.split("-").map(function (n) {
          return parseInt(n);
        });

        var _serialized$split$map2 = _slicedToArray(_serialized$split$map, 3);

        var year = _serialized$split$map2[0];
        var month = _serialized$split$map2[1];
        var day = _serialized$split$map2[2];

        return new Date(year, month - 1, day);
      }

      return this._super.apply(this, arguments);
    }
  });
});