define('juridico-sengerj/controllers/dashboard/inicio/funcionario-juridico/index', ['exports', 'ember', 'juridico-sengerj/utils/format/date/short'], function (exports, _ember, _juridicoSengerjUtilsFormatDateShort) {
  exports['default'] = _ember['default'].Controller.extend({
    _currentDate: new Date(),

    agendamentoAdvogadosColumns: ["Código", "Advogado", "Tipo", "Data", "Status"],

    init: function init() {
      this._super.apply(this, arguments);
      var currentDate = this._currentDate;
      var currentDay = currentDate.getDate();
      var currentMonth = currentDate.getMonth();
      var currentYear = currentDate.getFullYear();

      this.setProperties({
        firstDayMonthDate: (0, _juridicoSengerjUtilsFormatDateShort['default'])(new Date(currentYear, currentMonth, 1)),
        lastDayMonthDate: (0, _juridicoSengerjUtilsFormatDateShort['default'])(new Date(currentYear, currentMonth + 1, 0)),
        "31DaysAgo": (0, _juridicoSengerjUtilsFormatDateShort['default'])(new Date(currentYear, currentMonth, currentDay - 31))
      });
    }
  });
});