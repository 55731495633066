define('juridico-sengerj/helpers/percentage', ['exports', 'ember-percentages/helpers/percentage'], function (exports, _emberPercentagesHelpersPercentage) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberPercentagesHelpersPercentage['default'];
    }
  });
  Object.defineProperty(exports, 'percentage', {
    enumerable: true,
    get: function get() {
      return _emberPercentagesHelpersPercentage.percentage;
    }
  });
});