define('juridico-sengerj/helpers/format-time', ['exports', 'ember', 'juridico-sengerj/utils/format/date/short-time'], function (exports, _ember, _juridicoSengerjUtilsFormatDateShortTime) {
  exports.formatTime = formatTime;

  function formatTime(params) {

    var date = params[0];
    if (_ember['default'].isPresent(date)) {
      return (0, _juridicoSengerjUtilsFormatDateShortTime['default'])(date);
    }
  }

  exports['default'] = _ember['default'].Helper.helper(formatTime);
});