define('juridico-sengerj/controllers/dashboard/pastas/detalhes', ['exports', 'ember', 'juridico-sengerj/mixins/pages-loader'], function (exports, _ember, _juridicoSengerjMixinsPagesLoader) {

  var ENTER = 13;

  exports['default'] = _ember['default'].Controller.extend(_juridicoSengerjMixinsPagesLoader['default'], {
    queryParams: ['porCpf', 'porTipoOcorrencia'],
    modelName: 'juridico/ocorrencia',

    ocorrenciaColumns: ["Tipo da Ocorrência"],

    objetosAcaoColumns: ["Nome"],

    tipoReclamacoesColumns: ["Nome"],

    reusColumns: ["Nome", "Tipo de Réu"],

    ocorrenciaEvents: [],

    selectedTab: "ocorrencias",

    actions: {
      changeSelectedTab: function changeSelectedTab(tabName) {
        this.set("selectedTab", tabName);
        this.get("router").transitionTo('dashboard.pastas.detalhes.' + tabName, this.get("model"));
      },

      removeOcorrencia: function removeOcorrencia(ocorrencia) {
        ocorrencia.destroyRecord();
      },

      removeNota: function removeNota(nota) {
        nota.destroyRecord();
      },

      loadNotas: function loadNotas(eventoOcorrencia) {
        eventoOcorrencia.toggleProperty("showNotas");

        if (_ember['default'].isBlank(eventoOcorrencia.notas)) {
          this.store.query("nota", {
            porAnotacaoId: eventoOcorrencia.model.id,
            porAnotacaoType: 'Juridico::Ocorrencia'
          }).then(function (notas) {
            eventoOcorrencia.set("notas", notas.toArray());
          });
        }
      },

      newNota: function newNota(eventoOcorrencia, _, event) {
        if (event.keyCode === ENTER) {
          var nota = this.store.createRecord("nota", {
            anotavelType: 'Juridico::Ocorrencia',
            anotavelId: eventoOcorrencia.model.id,
            descricao: eventoOcorrencia.novaNotaText
          });

          nota.save();

          eventoOcorrencia.get("notas").pushObject(nota);

          event.preventDefault();
          eventoOcorrencia.set("novaNotaText", null);
        }
        //     Ember.$(event.target).val("");
        //     let valueRemovedEnter = value.substring(0, value.length-1);
        //
        //     this.store.createRecord("nota", {
        //       anotavelType: 'Juridico::Ocorrencia',
        //       anotavelId:    ocorrencia.id,
        //       descricao:     valueRemovedEnter
        //     }
        //   ).save().then((nota) => {
        //     this.get(`comments.${ocorrencia.id}`).pushObject(nota);
        //   });
      }
    },

    init: function init() {
      this._super.apply(this, arguments);
      this.loadPages("objetosAcao", "juridico/objeto-acao");
      this.loadPages("tipoReclamacoes", "juridico/tipo-reclamacao");
      // loadPages("tipoReclamacoes");
    }
  });
});