define("juridico-sengerj/components/route-components/form-banco-pagador", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    bancoPagador: null,
    "on-saved": function onSaved() {},

    bancoPagadorValid: _ember["default"].computed.and("bancoPagador.validations.isValid"),

    actions: {
      saveBancoPagador: function saveBancoPagador() {
        var _this = this;

        this.get("bancoPagador").save().then(function (bancoPagador) {
          _ember["default"].get(_this, "on-saved")(bancoPagador);
        });
      }
    }
  });
});