define("juridico-sengerj/templates/components/panels/panel-reclamantes", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 0
            },
            "end": {
              "line": 10,
              "column": 0
            }
          },
          "moduleName": "juridico-sengerj/templates/components/panels/panel-reclamantes.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "class", "btn btn-purple btn-labeled fa fa-plus");
          var el2 = dom.createTextNode("\n    Adicionar Reclamante\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("hr");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element0, 'disabled');
          morphs[1] = dom.createElementMorph(element0);
          morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          return morphs;
        },
        statements: [["attribute", "disabled", ["get", "isAddReclamanteDisabled", ["loc", [null, [4, 109], [4, 132]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", [["subexpr", "toggle", ["isModalVisible", ["get", "this", ["loc", [null, [4, 44], [4, 48]]], 0, 0, 0, 0]], [], ["loc", [null, [4, 19], [4, 49]]], 0, 0]], [], ["loc", [null, [4, 10], [4, 51]]], 0, 0], ["inline", "tables/table-reclamantes", [], ["reclamantes", ["subexpr", "@mut", [["get", "reclamantes", ["loc", [null, [9, 41], [9, 52]]], 0, 0, 0, 0]], [], [], 0, 0], "filters", ["subexpr", "@mut", [["get", "filters", ["loc", [null, [9, 61], [9, 68]]], 0, 0, 0, 0]], [], [], 0, 0], "onlyOneReclamante", ["subexpr", "@mut", [["get", "reclamavelIndividual", ["loc", [null, [9, 87], [9, 107]]], 0, 0, 0, 0]], [], [], 0, 0], "remoteSave", ["subexpr", "@mut", [["get", "remoteSave", ["loc", [null, [9, 119], [9, 129]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [9, 2], [9, 131]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 11,
            "column": 0
          }
        },
        "moduleName": "juridico-sengerj/templates/components/panels/panel-reclamantes.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "modals/modal-new-reclamante", [], ["isVisible", ["subexpr", "@mut", [["get", "isModalVisible", ["loc", [null, [1, 40], [1, 54]]], 0, 0, 0, 0]], [], [], 0, 0], "reclamavel", ["subexpr", "@mut", [["get", "_reclamavel", ["loc", [null, [1, 66], [1, 77]]], 0, 0, 0, 0]], [], [], 0, 0], "remoteSave", ["subexpr", "@mut", [["get", "remoteSave", ["loc", [null, [1, 89], [1, 99]]], 0, 0, 0, 0]], [], [], 0, 0], "on-create", ["subexpr", "action", ["addReclamante"], [], ["loc", [null, [1, 110], [1, 134]]], 0, 0]], ["loc", [null, [1, 0], [1, 136]]], 0, 0], ["block", "panels/panel-with-header", [], ["classNames", "col-md-6", "title", ["subexpr", "concat", [["subexpr", "if", [["get", "required", ["loc", [null, [3, 68], [3, 76]]], 0, 0, 0, 0], "*"], [], ["loc", [null, [3, 64], [3, 81]]], 0, 0], " Reclamantes"], [], ["loc", [null, [3, 56], [3, 97]]], 0, 0], "color", ["subexpr", "@mut", [["get", "color", ["loc", [null, [3, 104], [3, 109]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [3, 0], [10, 29]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});