define('juridico-sengerj/components/date-picker', ['exports', 'ember', 'juridico-sengerj/templates/components/date-picker'], function (exports, _ember, _juridicoSengerjTemplatesComponentsDatePicker) {
  exports['default'] = _ember['default'].Component.extend({
    layout: _juridicoSengerjTemplatesComponentsDatePicker['default'],
    autoclose: true,
    onlyTime: false,
    disabled: false,

    actions: {
      onChangeDate: function onChangeDate() {},

      onTimeDate: function onTimeDate(textDate) {
        var _textDate = textDate.replace(/\D/g, "");

        if (_textDate.length === 4) {
          var date = this.get("value") || new Date();
          var hours = _textDate.substring(0, 2);
          var minutes = _textDate.substring(2, 4);

          date.setHours(hours);
          date.setMinutes(minutes);
          date.setSeconds(0);

          this.set("value", date);
        }
      }
    },

    timeValue: _ember['default'].computed.oneWay("valueToTime"),

    valueToTime: _ember['default'].computed("value", function () {
      var date = this.get("value");

      if (date) {
        return date.getHours() + ':' + date.getMinutes();
      }
    })
  });
});