define('juridico-sengerj/routes/dashboard/atendimentos/homologacoes/novo', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    actions: {
      atendimentoCreated: function atendimentoCreated() {
        _ember['default'].$.niftyNoty({
          type: 'success',
          icon: 'fa fa-exclamation-triangle fa-2x',
          container: "floating",
          title: 'O Atendimento foi registrado.',
          timer: 3000
        });

        this.get('router').transitionTo('dashboard.atendimentos.homologacoes');
      }
    },

    _createAtendimento: function _createAtendimento() {
      return this.store.createRecord("juridico/homologacao/atendimento");
    },

    _createProfissional: function _createProfissional() {
      var pessoa = this.store.createRecord("pessoa");
      var profissional = this.store.createRecord("profissional", { pessoa: pessoa });

      pessoa.set("enderecos", [this.store.createRecord("endereco")]);

      return profissional;
    },

    _createProfissionalUnidade: function _createProfissionalUnidade(profissional) {
      return this.store.createRecord("profissional-unidade", { profissional: profissional });
    },

    model: function model() {
      var atendimento = this._createAtendimento();
      var profissional = this._createProfissional();

      atendimento.setProperties({
        profissional: profissional,
        profissionalUnidade: this._createProfissionalUnidade(profissional)
      });

      return atendimento;
    }
  });
});