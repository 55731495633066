define("juridico-sengerj/components/route-components/form-pasta", ["exports", "ember"], function (exports, _ember) {
  var RSVP = _ember["default"].RSVP;
  exports["default"] = _ember["default"].Component.extend({
    pasta: null,
    tabIndex: 0,

    _reclamantesNotDestroyed: _ember["default"].computed.filterBy("pasta.reclamantes", "isDeleted", false),

    isAvulsa: _ember["default"].computed.alias("pasta.hasPastaAvulsa"),

    actions: {
      changeTab: function changeTab(index) {
        var _this = this;

        this.set("tabIndex", index);
        var pasta = this.get("pasta");

        if (pasta.get("isNew") && this.isPastaColetivo()) {
          this.savePasta().then(function () {
            return _this.showNotification(true);
          });
        }
      },

      savePastaAll: function savePastaAll() {
        var _this2 = this;

        var isPastaNew = this.get("pasta.isNew");

        this.savePasta().then(function () {
          return _this2.get('router').transitionTo('dashboard.pastas');
        }).then(function () {
          return _this2.showNotification(isPastaNew);
        });
      }
    },

    savePasta: function savePasta() {
      var _this3 = this;

      var pasta = this.get("pasta");

      var reclamantes = pasta.get("reclamantes").toArray();

      var dadosAtendimento = pasta.get("dadosAtendimento.content");

      return pasta.save({ force: true }).then(function () {
        return RSVP.all(Array.prototype.concat(pasta.get("objetosAcao").map(function (o) {
          return o.save();
        }), pasta.get("tipoReclamacoes").map(function (t) {
          return t.save();
        }), pasta.get("reus").map(function (r) {
          return r.save({ force: true });
        })));
      }).then(function () {
        return dadosAtendimento.save({ force: true });
      }).then(function () {
        if (!_this3.isPastaColetivo()) {
          return RSVP.all(reclamantes.map(function (reclamante) {
            reclamante.set("reclamavel", dadosAtendimento);
            return reclamante.save({ force: true }).then(reclamante.get("pessoa").save);
          }));
        }
      });
    },

    isPastaColetivo: function isPastaColetivo() {
      return this.get("pasta.tipo.value") === "coletivo";
    },

    showNotification: function showNotification(isPastaNew) {
      _ember["default"].$.niftyNoty({
        type: 'success',
        icon: 'fa fa-thumbs-up fa-2x',
        container: 'floating',
        title: isPastaNew ? "Pasta " + this.get("pasta.showCode") + " foi criada" : "Pasta " + this.get("pasta.showCode") + " foi atualizada",
        timer: 4000
      });
    }
  });
});