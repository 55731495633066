define("juridico-sengerj/components/comments-box", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    comments: [],
    newDescricao: "",
    onlyOne: true,
    showSent: false,
    showAdd: true,
    showEdit: true,
    showRemove: true,
    "on-save": function onSave() {},
    emptyMessage: "Nenhum comentário registrado",
    placeholder: "Escreva um comentário",
    groupsNotified: null,
    groupsNotifiedMessage: "Grupos já notificados:",

    actions: {
      makeEditable: function makeEditable(comment) {
        comment.set("isEditing", true);
      },

      addNewComment: function addNewComment() {
        this.comments.createRecord({
          usuario: this.get("session.currentUser")
        });
      },

      saveComment: function saveComment(comment) {
        comment.set("anotavel", this.get("comments.content.record").getRecord());
        comment.save().then(function (comment) {
          return comment.set("isEditing", false);
        }).then(function () {
          return comment.reload();
        });
        _ember["default"].get(this, "on-save")(comment);
      }
    },

    _comments: _ember["default"].computed.sort("comments", function (commentA, commentB) {
      var commentADate = commentA.get("createdAt") || new Date();
      var commentBDate = commentB.get("createdAt") || new Date();

      if (commentADate > commentBDate) {
        return -1;
      } else if (commentADate < commentBDate) {
        return 1;
      } else {
        return 0;
      }
    })
  });
});