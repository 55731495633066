define("juridico-sengerj/components/route-components/form-pasta/reus", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    reus: null,
    pasta: null,
    _reus: [],

    actions: {
      addReu: function addReu() {
        this.get("reus").createRecord({ reuzavelType: "Empresa", pasta: this.get("pasta") });

        this.set("_reus", this.get("reus").toArray());
      }
    },

    init: function init() {
      this._super.apply(this, arguments);
      this.set("_reus", this.get("reus").toArray());
    }
  });
});