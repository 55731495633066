define('juridico-sengerj/components/simple-uploader', ['exports', 'ember', 'juridico-sengerj/config/environment', 'ember-uploader'], function (exports, _ember, _juridicoSengerjConfigEnvironment, _emberUploader) {

  var SIGNING_URL = _juridicoSengerjConfigEnvironment['default'].ApiHost + '/v1/uploader/sign';
  var $ = _ember['default'].$;
  var get = _ember['default'].get;
  exports['default'] = _emberUploader['default'].FileField.extend({
    session: _ember['default'].inject.service(),
    isUploading: false,
    uploadedUrl: null,
    folder: null,
    "finished-uploading": function finishedUploading() {},

    filesDidChange: function filesDidChange(files) {
      var _this = this;

      var session = this.get("session.data.authenticated");

      var uploader = _emberUploader['default'].S3Uploader.create({
        signingUrl: SIGNING_URL,
        signingAjaxSettings: {
          headers: {
            "USUARIO-ID": session.usuarioId,
            "JWT-TOKEN": session.token
          }
        }
      });

      uploader.on('didUpload', function (response) {
        var uploadedUrl = decodeURIComponent($(response).find('Location')[0].textContent);

        get(_this, "finished-uploading")(uploadedUrl);

        _this.setProperties({
          uploadedUrl: uploadedUrl,
          isUploading: false,
          progress: 0
        });
      });

      uploader.on('progress', function (_ref) {
        var percent = _ref.percent;

        _this.set("progress", percent);
      });

      if (!_ember['default'].isEmpty(files)) {
        this.toggleProperty("isUploading");
        uploader.upload(files[0], { folder: this.folder });
      }
    }
  });
});