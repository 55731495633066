define("juridico-sengerj/components/route-components/form-pasta/acordo", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    ocorrencia: null,

    actions: {
      addDataPagamento: function addDataPagamento() {
        this.get("ocorrencia.acordo.dataPagamentos").createRecord();
      }
    }
  });
});