define('juridico-sengerj/components/tables/table-agendamentos-homologacao', ['exports', 'ember', 'sweetalert2'], function (exports, _ember, _sweetalert2) {
  exports['default'] = _ember['default'].Component.extend({
    columnsTitle: ["Empresa", "Profissional", "CPF", "Data", "Horário", "Status"],

    records: [],

    hasShowMoreButton: true,

    actions: {
      deleteAgendamento: function deleteAgendamento(agendamento) {
        (0, _sweetalert2['default'])({
          title: "Motivo Cancelamento",
          input: 'text',
          showCancelButton: true
        }).then(function (motivo) {
          return agendamento.removerOuCancelar(motivo);
        }).then(function () {
          _ember['default'].$.niftyNoty({
            type: 'success',
            icon: 'fa fa-thumbs-up fa-2x',
            container: "floating",
            title: 'Agendamento foi cancelado',
            timer: 4000
          });
        })['catch'](_sweetalert2['default'].noop);
      }
    }
  });
});