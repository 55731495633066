define('juridico-sengerj/components/route-components/form-pasta/herdeiros', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),
    pastaId: null,
    _filters: {},
    _herdeiros: [],

    columns: ['Nome', 'CPF', 'Reclamante'],

    _loadHerdeiros: function _loadHerdeiros() {
      var store = this.get("store");

      this.set("_herdeiros", store.query("relacionamento-habilitado", Object.assign({ pastaId: this.get('pasta').get('id') }, this.filters)));
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this._loadHerdeiros();
    }
  });
});