define('juridico-sengerj/components/route-components/form-homologacao/calendario', ['exports', 'ember', 'juridico-sengerj/utils/format/date/short', 'juridico-sengerj/mixins/components/load-holidays-calendar', 'juridico-sengerj/helpers/format-date'], function (exports, _ember, _juridicoSengerjUtilsFormatDateShort, _juridicoSengerjMixinsComponentsLoadHolidaysCalendar, _juridicoSengerjHelpersFormatDate) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend(_juridicoSengerjMixinsComponentsLoadHolidaysCalendar['default'], {

    currentCalendarDate: null,
    holidays: [],
    blockedDates: [],
    notAvaliableDaysWeek: [],
    profissionalsSchedules: [],
    isHolidaysLoading: true,
    isNotAvaliableDaysWeekLoading: true,
    isProfissionalsSchedulesLoading: true,

    store: _ember['default'].inject.service(),

    actions: {
      calendarMonthChanged: function calendarMonthChanged(date) {
        this.set("currentCalendarDate", date);

        this.setProperties({
          isHolidaysLoading: true,
          isBlockedDaysLoading: true,
          isNotAvaliableDaysWeekLoading: true,
          isProfissionalsSchedulesLoading: true
        });

        this._loadHolidays();

        this._loadBlockedDates();

        this._loadProfissionalsSchedules();

        this._loadNotAvaliableDaysWeek();
      }
    },

    blockedDays: computed("blockedDates", "holidays", "notAvaliableDaysWeek", function () {
      var _getProperties = this.getProperties("blockedDates", "holidays", "notAvaliableDaysWeek");

      var blockedDates = _getProperties.blockedDates;
      var holidays = _getProperties.holidays;
      var notAvaliableDaysWeek = _getProperties.notAvaliableDaysWeek;

      return [].concat(holidays, notAvaliableDaysWeek, blockedDates);
    }),

    isLoadingDates: computed.or("isHolidaysLoading", "isNotAvaliableDaysWeekLoading", "isProfissionalsSchedulesLoading", "isBlockedDaysLoading"),

    _loadProfissionalsSchedules: function _loadProfissionalsSchedules() {
      var _this = this;

      var store = this.get("store");

      var date = this.currentCalendarDate;
      var year = date.getFullYear();
      var month = date.getMonth();

      var dateFilters = {
        porDataIni: (0, _juridicoSengerjUtilsFormatDateShort['default'])(new Date(year, month, 1)),
        porDataFim: (0, _juridicoSengerjUtilsFormatDateShort['default'])(new Date(year, month + 1, 0))
      };

      store.query("juridico/homologacao/agendamento-ocupado", dateFilters).then(function (schedules) {
        var profissionalsSchedules = schedules.map(function (schedule) {
          return {
            date: schedule.get("dataHorario"),
            message: "Ocupado",
            isBlocked: true
          };
        });

        _this.setProperties({
          profissionalsSchedules: profissionalsSchedules,
          isProfissionalsSchedulesLoading: false
        });
      });
    },

    _loadNotAvaliableDaysWeek: function _loadNotAvaliableDaysWeek() {
      var _this2 = this;

      this.get("config").then(function (config) {
        var calendarDate = _this2.currentCalendarDate;
        var year = calendarDate.getFullYear();
        var month = calendarDate.getMonth();

        var lastDate = new Date(year, month + 1, 0);

        var avaibleDaysWeek = config.get(".avaibleDaysWeek");

        var notAvaliableDaysWeek = [];

        for (var day = 1; day <= lastDate.getDate(); day++) {
          var date = new Date(year, month, day);
          var currentDate = new Date();
          var dateLimite = config.get("dataLimite");

          if (date >= config.get("dataLimite")) {
            notAvaliableDaysWeek.push({
              day: day,
              reason: "Escolha uma data anterior"
            });
          } else if (date.toDateString() === currentDate.toDateString()) {
            notAvaliableDaysWeek.push({
              day: day,
              reason: "Não é possivel marcar no mesmo dia"
            });
          } else if (!avaibleDaysWeek[date.getDay()]) {
            notAvaliableDaysWeek.push({
              day: day,
              reason: "Indisponível esse dia de semana"
            });
          }
        }

        _this2.setProperties({
          notAvaliableDaysWeek: notAvaliableDaysWeek,
          isNotAvaliableDaysWeekLoading: false
        });
      });
    },

    _loadHolidays: function _loadHolidays() {
      var _this3 = this;

      this.loadHolidays(this.currentCalendarDate).then(function (holidays) {
        _this3.setProperties({
          holidays: holidays,
          isHolidaysLoading: false
        });
      });
    },

    _loadBlockedDates: function _loadBlockedDates() {
      var _this4 = this;

      return this.get("store").query("data-sem-homologacao", { porMes: (0, _juridicoSengerjHelpersFormatDate.formatDate)([this.currentCalendarDate]) }).then(function (blockedDates) {
        var _blockedDates = blockedDates.map(function (blockedDay) {
          return { day: blockedDay.get("dataSemHomologacao").getUTCDate(), reason: blockedDay.get("nome") };
        });

        _this4.setProperties({
          blockedDates: _blockedDates,
          isBlockedDaysLoading: false
        });
      });
    },

    init: function init() {
      this._super.apply(this, arguments);
      var store = this.get("store");

      this.set("config", store.findRecord("juridico/homologacao/configuracao-homologacao", 1));

      this._loadHolidays();
      this._loadBlockedDates();
      this._loadProfissionalsSchedules();
      this._loadNotAvaliableDaysWeek();
    }
  });
});