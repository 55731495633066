define('juridico-sengerj/helpers/sum', ['exports', 'ember'], function (exports, _ember) {
  exports.sum = sum;

  function sum(params) {
    return params.reduce(function (total, number) {
      return total + number;
    }, 0);
  }

  exports['default'] = _ember['default'].Helper.helper(sum);
});