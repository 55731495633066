define('juridico-sengerj/mixins/routes/table-listing-with-filters', ['exports', 'ember', 'ember-query-params-reset/mixins/query-params-reset-route'], function (exports, _ember, _emberQueryParamsResetMixinsQueryParamsResetRoute) {
  exports['default'] = _ember['default'].Mixin.create(_emberQueryParamsResetMixinsQueryParamsResetRoute['default'], {
    oldTransition: null,

    actions: {
      applyFilters: function applyFilters(filters) {
        if (this.oldTransition) {
          this.oldTransition.abort();
        }
        this.transitionTo({ queryParams: filters });
        return this.refresh();
      }
    },

    afterModel: function afterModel(_, transition) {
      this._super.apply(this, arguments);
      this.set("oldTransition", transition);
    },

    filtersParams: function filtersParams(_ref) {
      var _ref$params = _ref.params;
      var params = _ref$params === undefined ? {} : _ref$params;
      var _ref$include = _ref.include;
      var include = _ref$include === undefined ? '' : _ref$include;

      return _ember['default'].assign({ page: 1, include: include }, params);
    }
  });
});