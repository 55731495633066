define("juridico-sengerj/components/context-menu", ["exports", "ember"], function (exports, _ember) {
  var $ = _ember["default"].$;
  var run = _ember["default"].run;
  exports["default"] = _ember["default"].Component.extend({
    parent: "*:first",
    isInsideTable: false,
    disabled: false,
    toggled: false,
    left: 0,
    top: 0,
    slideDuration: 100,
    _hideWhenClickOutsideHandler: function _hideWhenClickOutsideHandler() {},

    actions: {
      showContextMenu: function showContextMenu() {
        var offset = this.$("button").offset();

        // debugger;

        this.setProperties({
          toggled: !this.get("toggled"),
          left: offset.left + this.$("button").outerWidth(),
          top: offset.top + this.$().height()
        });

        _ember["default"].run.scheduleOnce('afterRender', this, function () {
          this.set("left", this.get("left") - this.get("menu").outerWidth());
        });

        this._slideDownContextMenu();
      }
    },

    position: _ember["default"].computed("top", "left", function () {
      return "top:" + this.top + "px;left:" + this.left + "px;z-index:2000";
    }),

    _showContextMenu: function _showContextMenu(event) {
      if (!this.disabled) {
        this.set("toggled", true);

        this.setProperties({
          left: event.pageX,
          top: event.pageY
        });

        event.preventDefault();

        this._slideDownContextMenu();
      }
    },

    menu: _ember["default"].computed("elementId", "toggled", function () {
      return _ember["default"].$("#context-menu-box ul.dropdown-" + this.elementId);
    }),

    _slideDownContextMenu: function _slideDownContextMenu() {
      _ember["default"].run.scheduleOnce('afterRender', this, function () {
        var $menu = this.get("menu");

        $menu.velocity("slideDown", {
          duration: this.slideDuration
        });
      });
    },

    _hideWhenClickOutside: function _hideWhenClickOutside() {
      if (!this.isDestroyed) {
        this.set("toggled", false);
      }
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.$().parents(this.parent).off("contextmenu");
      _ember["default"].$("body").off("click", this.get("_hideWhenClickOutsideHandler"));
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.$().parents(this.parent).contextmenu(run.bind(this, this._showContextMenu));
      _ember["default"].$("body").click(this.get("_hideWhenClickOutsideHandler"));
    },

    init: function init() {
      this._super.apply(this, arguments);
      this.set("_hideWhenClickOutsideHandler", run.bind(this, this._hideWhenClickOutside));

      if (this.isInsideTable) {
        this.set("tagName", "td");
      }
    }
  });
});