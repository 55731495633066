define('juridico-sengerj/controllers/login/esqueceu-senha', ['exports', 'ember', 'ember-cp-validations'], function (exports, _ember, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      type: 'email',
      allowNonTld: true
    })]
  });

  exports['default'] = _ember['default'].Controller.extend(Validations, {
    email: '',
    isSending: false,

    actions: {
      sendEmailResetSenha: function sendEmailResetSenha() {
        var _this = this;

        this.set("isSending", true);
        var adapter = this.get("store").adapterFor();

        var email = this.get("email");

        adapter.ajax(adapter.host + '/usuario/enviar_email_reset_senha', 'POST', {
          data: { email: email }
        }).then(function () {
          _ember['default'].$.niftyNoty({
            type: 'success',
            icon: 'fa fa-exclamation-triangle fa-2x',
            container: 'div.cls-header',
            title: 'E-mail para redefinir sua senha foi enviado para o endereço ' + email,
            message: 'Agora basta entrar no seu e-mail e clicar no link "Criar/alterar minha senha" para redefinir sua senha',
            timer: 15000
          });

          _this.set("isSending", false);
          _this.get("router").transitionTo("login");
        });
      }
    }
  });
});