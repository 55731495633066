define('juridico-sengerj/routes/dashboard/agendamentos/homologacoes/novo', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    actions: {
      newAgendamentoSaved: function newAgendamentoSaved(agendamento) {
        _ember['default'].$.niftyNoty({
          type: 'success',
          icon: 'fa fa-exclamation-triangle fa-2x',
          container: "floating",
          title: 'O Agendamento foi criado.',
          timer: 3000
        });

        this.get('router').transitionTo('dashboard.agendamentos.homologacoes');
      }
    },

    _createAgendamento: function _createAgendamento() {
      var dataAvisoPrevio = new Date();
      dataAvisoPrevio.setDate(dataAvisoPrevio.getDate() - 1);

      return this.store.createRecord("juridico/homologacao/agendamento", { dataAvisoPrevio: dataAvisoPrevio });
    },

    _createProfissional: function _createProfissional() {
      var pessoa = this.store.createRecord("pessoa");
      var profissional = this.store.createRecord("profissional", { pessoa: pessoa });

      return profissional;
    },

    _createProfissionalUnidade: function _createProfissionalUnidade(profissional) {
      return this.store.createRecord("profissional-unidade", { profissional: profissional });
    },

    _createAtendimento: function _createAtendimento(agendamento) {
      return this.store.createRecord("juridico/homologacao/atendimento", { agendamento: agendamento });
    },

    model: function model() {
      var agendamento = this._createAgendamento();
      var profissional = this._createProfissional();

      agendamento.setProperties({
        profissional: this._createProfissional(),
        profissionalUnidade: this._createProfissionalUnidade(profissional),
        atendimento: this._createAtendimento(agendamento)
      });

      return agendamento;
    }
  });
});