define('juridico-sengerj/components/button-export-agendamentos-advogado', ['exports', 'ember', 'juridico-sengerj/components/button-export-model', 'juridico-sengerj/helpers/format-date', 'juridico-sengerj/helpers/format-time'], function (exports, _ember, _juridicoSengerjComponentsButtonExportModel, _juridicoSengerjHelpersFormatDate, _juridicoSengerjHelpersFormatTime) {
  exports['default'] = _juridicoSengerjComponentsButtonExportModel['default'].extend({
    columns: {
      "Código": "mostrarCodigo",
      Advogado: "nomeAdvogado",
      Tipo: "tipo.text",
      Data: function Data(a) {
        return (0, _juridicoSengerjHelpersFormatDate.formatDate)([a.get("dataHorario")]);
      },
      "Horário": function HorRio(a) {
        return (0, _juridicoSengerjHelpersFormatTime.formatTime)([a.get("dataHorario")]);
      },
      Status: "status",
      Reclamante: 'reclamanteIndividual'
    },

    longTextColumns: ["Advogado"],

    title: "Agendamentos Advogado",

    include: "advogado.pessoa"
  });
});