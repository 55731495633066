define('juridico-sengerj/components/filters/filter-autocomplete', ['exports', 'ember', 'ember-data', 'juridico-sengerj/components/autocomplete-model', 'juridico-sengerj/mixins/components/filter'], function (exports, _ember, _emberData, _juridicoSengerjComponentsAutocompleteModel, _juridicoSengerjMixinsComponentsFilter) {
  var isBlank = _ember['default'].isBlank;
  var isPresent = _ember['default'].isPresent;
  var computed = _ember['default'].computed;
  exports['default'] = _juridicoSengerjComponentsAutocompleteModel['default'].extend(_juridicoSengerjMixinsComponentsFilter['default'], {
    actions: {
      search: function search(query) {
        if (isBlank(query)) {
          this.clearFilterValue();
        }
        this._super(query);
      }
    },

    _selectResult: function _selectResult(select) {
      this.saveFilterValue(select.get(this.valueField));
      this._super(select);
    },

    valueToShow: computed.alias("searchValue"),

    searchValue: computed.alias("_promise.content"),

    _promise: computed("filterValue", function () {
      var _this = this;

      var promise = null;

      var _getProperties = this.getProperties("store", "filterValue", "include");

      var store = _getProperties.store;
      var filterValue = _getProperties.filterValue;
      var include = _getProperties.include;

      if (isPresent(filterValue)) {
        promise = store.findRecord(this.modelName, filterValue, { include: include }).then(function (record) {
          var text = record.get(_this.label);

          return text;
        });
      } else {
        promise = Promise.resolve(null);
      }

      return _emberData['default'].PromiseObject.create({ promise: promise });
    })
  });
});