define("juridico-sengerj/templates/dashboard/menu/-funcionario-juridico", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 0
            },
            "end": {
              "line": 13,
              "column": 0
            }
          },
          "moduleName": "juridico-sengerj/templates/dashboard/menu/-funcionario-juridico.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          return morphs;
        },
        statements: [["inline", "dashboard-menu", ["dashboard.agendamentos.advogados.index"], ["parent", ["subexpr", "@mut", [["get", "acao", ["loc", [null, [7, 67], [7, 71]]], 0, 0, 0, 0]], [], [], 0, 0], "icon", "fa fa-calendar", "title", "Agendamentos"], ["loc", [null, [7, 2], [7, 116]]], 0, 0], ["inline", "dashboard-menu", ["dashboard.atendimentos.advogados.index"], ["parent", ["subexpr", "@mut", [["get", "acao", ["loc", [null, [9, 67], [9, 71]]], 0, 0, 0, 0]], [], [], 0, 0], "icon", "fa fa-pencil-square-o", "title", "Atendimentos"], ["loc", [null, [9, 2], [9, 123]]], 0, 0], ["inline", "dashboard-menu", ["dashboard.pastas.index"], ["icon", "fa-folder", "parent", ["subexpr", "@mut", [["get", "acao", ["loc", [null, [11, 68], [11, 72]]], 0, 0, 0, 0]], [], [], 0, 0], "title", "Pastas"], ["loc", [null, [11, 2], [11, 90]]], 0, 0]],
        locals: ["acao"],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 0
            },
            "end": {
              "line": 20,
              "column": 0
            }
          },
          "moduleName": "juridico-sengerj/templates/dashboard/menu/-funcionario-juridico.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["inline", "dashboard-menu", ["dashboard.agendamentos.homologacoes.index"], ["parent", ["subexpr", "@mut", [["get", "homologacao", ["loc", [null, [17, 70], [17, 81]]], 0, 0, 0, 0]], [], [], 0, 0], "icon", "fa fa-calendar", "title", "Agendamentos"], ["loc", [null, [17, 2], [17, 126]]], 0, 0], ["inline", "dashboard-menu", ["dashboard.atendimentos.homologacoes.index"], ["parent", ["subexpr", "@mut", [["get", "homologacao", ["loc", [null, [18, 70], [18, 81]]], 0, 0, 0, 0]], [], [], 0, 0], "icon", "fa fa-pencil-square-o", "title", "Realizadas"], ["loc", [null, [18, 2], [18, 131]]], 0, 0]],
        locals: ["homologacao"],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 22,
              "column": 0
            },
            "end": {
              "line": 26,
              "column": 0
            }
          },
          "moduleName": "juridico-sengerj/templates/dashboard/menu/-funcionario-juridico.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "dashboard-menu", ["dashboard.contratos.index"], ["parent", ["subexpr", "@mut", [["get", "financeiro", ["loc", [null, [24, 54], [24, 64]]], 0, 0, 0, 0]], [], [], 0, 0], "icon", "fa fa-file", "title", "Contratos"], ["loc", [null, [24, 2], [24, 102]]], 0, 0]],
        locals: ["financeiro"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 27,
            "column": 0
          }
        },
        "moduleName": "juridico-sengerj/templates/dashboard/menu/-funcionario-juridico.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        morphs[4] = dom.createMorphAt(fragment, 8, 8, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "dashboard-menu", ["dashboard.inicio.funcionario-juridico.index"], ["icon", "fa-home", "title", "Página Inicial"], ["loc", [null, [1, 0], [1, 102]]], 0, 0], ["inline", "dashboard-menu", ["dashboard.usuarios.index"], ["icon", "fa-group", "title", "Usuários"], ["loc", [null, [3, 0], [3, 79]]], 0, 0], ["block", "dashboard-menu", [], ["icon", "fa fa-gavel", "title", "Ações"], 0, null, ["loc", [null, [5, 0], [13, 19]]]], ["block", "dashboard-menu", [], ["icon", "fa fa-book", "title", "Homologações"], 1, null, ["loc", [null, [15, 0], [20, 19]]]], ["block", "dashboard-menu", [], ["icon", "fa fa-university", "title", "Financeiro"], 2, null, ["loc", [null, [22, 0], [26, 19]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});