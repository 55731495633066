define("juridico-sengerj/components/tables/table-reus", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    columns: ["Nome", "Tipo", "CPF/CNPJ"],

    remoteSave: true,

    store: _ember["default"].inject.service(),

    foundReuzavel: null,

    newTipoReu: "Pessoa",

    newReus: [],

    reus: [],

    "on-create": function onCreate() {},

    "on-delete": function onDelete() {},

    actions: {
      setTipoReu: function setTipoReu(reu) {
        if (this.newTipoReu !== reu.get("reuzavelType")) {
          reu.setProperties({
            reuzavelType: this.newTipoReu,
            reuzavelId: null
          });

          this._save(reu);
        }
      },

      removeReu: function removeReu(reu) {
        _ember["default"].get(this, "on-delete")(reu);
        reu.destroyRecord();
      },

      searchPessoa: function searchPessoa(porNome) {
        return this.get("store").query("pessoa", { porNome: porNome });
      },

      searchEmpresa: function searchEmpresa(porEmpresa) {
        return this.get("store").query("empresa", { porEmpresa: porEmpresa });
      },

      setNewTipoReu: function setNewTipoReu(reu) {
        this.set("newTipoReu", reu.get("reuzavelType"));
      },

      setFoundReuzavel: function setFoundReuzavel(reu) {
        reu.set("reuzavel", this.foundReuzavel);
        this._save(reu);
      },

      selectedReuzavel: function selectedReuzavel(reuzavel) {
        this.set("foundReuzavel", reuzavel);
      }
    },

    _save: function _save(reu) {
      if (this.remoteSave) {
        if (reu.get("isNew")) {
          _ember["default"].get(this, "on-create")(reu);
        }
        reu.save();
      }
    }
  });
});