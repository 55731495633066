define('juridico-sengerj/routes/dashboard', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  var Route = _ember['default'].Route;
  var on = _ember['default'].on;
  exports['default'] = Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    firstTimeLoading: true,

    onUserLoad: _ember['default'].observer("session.currentUser.isLoaded", function () {
      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        _ember['default'].$(document).trigger("nifty.ready");
      });
    }),

    captureMessageOnActivate: on('activate', function () {
      var _this = this;

      this.get("session.currentUser").then(function (usuario) {
        var _usuario$getProperties = usuario.getProperties('id', 'email', 'permissoes', 'nome');

        var id = _usuario$getProperties.id;
        var email = _usuario$getProperties.email;
        var permissoes = _usuario$getProperties.permissoes;
        var nome = _usuario$getProperties.nome;

        var permissoesValues = permissoes.mapBy("value");

        _this.get('raven').callRaven('setUserContext', {
          id: id, email: email, nome: nome, permissoes: permissoesValues
        });
      });
    }),

    actions: {
      showHideNavMenu: function showHideNavMenu() {
        _ember['default'].$.niftyNav('colExpToggle');
      },

      didTransition: function didTransition() {
        if (this.firstTimeLoading) {
          _ember['default'].$('body').velocity("fadeIn", { duration: 200 });
          _ember['default'].run.scheduleOnce('afterRender', this, function () {
            _ember['default'].$(document).trigger("nifty.templateReady");
            _ember['default'].$(document).trigger("nifty.ready");
            this.set('firstTimeLoading', false);
          });
        }
        return true;
      }
    }
  });
});