define('juridico-sengerj/models/juridico/reu', ['exports', 'ember', 'juridico-sengerj/utils/has-one-polymorphic', 'juridico-sengerj/models/abstract-model', 'ember-data'], function (exports, _ember, _juridicoSengerjUtilsHasOnePolymorphic, _juridicoSengerjModelsAbstractModel, _emberData) {
  exports['default'] = _juridicoSengerjModelsAbstractModel['default'].extend({
    reuzavelType: _emberData['default'].attr('string'),
    reuzavelId: _emberData['default'].attr('number'),

    tipo: _ember['default'].computed("reuzavelType", "reuzavelId", function () {
      if (this.get("reuzavelType")) {
        return this.get("isPessoa") ? "Pessoa" : "Empresa";
      }
    }),

    isPessoa: _ember['default'].computed("reuzavel", "reuzavelType", "reuzavelId", function () {
      return this.get("reuzavelType") === "Pessoa";
    }),

    reuzavel: (0, _juridicoSengerjUtilsHasOnePolymorphic['default'])("reuzavel"),

    pasta: _emberData['default'].belongsTo("juridico/pasta"),

    nome: _ember['default'].computed("reuzavel.isFulfilled", "reuzavel", "tipo", function () {
      switch (this.get("tipo")) {
        case "Pessoa":
          return this.get("reuzavel.nome");

        case "Empresa":
          return this.get("reuzavel.nomeFantasia");
      }
    })
  });
});