define('juridico-sengerj/components/filters-with-table/atendimentos-advogado', ['exports', 'ember', 'npm:multi-download', 'juridico-sengerj/utils/pages-loader'], function (exports, _ember, _npmMultiDownload, _juridicoSengerjUtilsPagesLoader) {
  exports['default'] = _ember['default'].Component.extend({
    atendimentos: [],
    tagName: '',
    "apply-filters": function applyFilters() {},
    _atendimentoBaixarFichasModalVisible: false,
    _paginasCarregadas: 0,
    _totalPaginas: 1,

    actions: {
      applyFilters: function applyFilters(filters) {
        _ember['default'].get(this, "apply-filters")(filters);
      },

      baixarFichasAtendimento: function baixarFichasAtendimento(atendimentos) {
        var _this = this;

        this.setProperties({
          _paginasCarregadas: 0,
          _totalPaginas: atendimentos.meta["total-pages"],
          _atendimentoBaixarFichasModalVisible: true
        });

        var promise = _ember['default'].RSVP.resolve(atendimentos);

        _juridicoSengerjUtilsPagesLoader['default'].loadPages(promise).then(function (_ref) {
          var promises = _ref.promises;

          promises.forEach(function (promise) {
            promise.then(function (atendimentos) {

              (0, _npmMultiDownload['default'])(atendimentos.mapBy("fichaAtendimentoAssinadaUrl").reject(_ember['default'].isBlank));

              _this.incrementProperty("_paginasCarregadas");
            });
          });
        });
      }
    }
  });
});