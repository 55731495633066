define('juridico-sengerj/models/financeiro/conta-bancaria', ['exports', 'juridico-sengerj/models/abstract-model', 'ember-data', 'ember-cp-validations'], function (exports, _juridicoSengerjModelsAbstractModel, _emberData, _emberCpValidations) {

    var Validations = (0, _emberCpValidations.buildValidations)({});

    exports['default'] = _juridicoSengerjModelsAbstractModel['default'].extend(Validations, {
        numeroBanco: _emberData['default'].attr('string'),
        nomeBanco: _emberData['default'].attr('string'),
        agencia: _emberData['default'].attr('string'),
        numeroConta: _emberData['default'].attr('string'),
        finalidade: _emberData['default'].attr('string'),
        ordenacao: _emberData['default'].attr('string'),
        nomeBancoComDadosBancarios: _emberData['default'].attr('string')
    });
});