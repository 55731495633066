define('juridico-sengerj/components/modals/modal-new-reclamante', ['exports', 'ember', 'ember-cp-validations', 'juridico-sengerj/utils/format/date/short'], function (exports, _ember, _emberCpValidations, _juridicoSengerjUtilsFormatDateShort) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    pessoa: (0, _emberCpValidations.validator)('presence', true)
  });

  exports['default'] = _ember['default'].Component.extend(Validations, {
    store: _ember['default'].inject.service(),
    isVisible: false,
    reclamavel: null,
    remoteSave: true,
    _created: false,
    "on-create": function onCreate() {},

    actions: {
      setPessoaCPF: function setPessoaCPF(cpf) {
        this.get("_newReclamante").set("pessoa.cpf", cpf);
      },

      selectedPessoa: function selectedPessoa(pessoa) {
        this.get("_newReclamante").set("pessoa", pessoa);
      },

      searchPessoa: function searchPessoa(porPessoaCpf) {
        return this.get("store").query("pessoa", { porPessoaCpf: porPessoaCpf });
      },

      saveReclamante: function saveReclamante() {
        var _this = this;

        var reclamante = this.get("_newReclamante");

        if (this.remoteSave) {
          reclamante.save().then(this.get("pessoa").save).then(function () {
            _ember['default'].get(_this, "on-create")(reclamante);
          });
        } else {
          _ember['default'].get(this, "on-create")(reclamante);
        }
      },

      showNotification: function showNotification() {
        _ember['default'].$.niftyNoty({
          type: 'success',
          icon: 'fa fa-thumbs-up fa-2x',
          container: 'floating',
          title: 'Reclamante foi registrado',
          timer: 4000
        });
      }
    },

    _newReclamante: _ember['default'].computed("reclamavel", "isVisible", function () {
      var _getProperties = this.getProperties("reclamavel", "store");

      var reclamavel = _getProperties.reclamavel;
      var store = _getProperties.store;

      var pessoa = store.createRecord("pessoa");

      return store.createRecord("juridico/reclamante", { reclamavel: reclamavel, pessoa: pessoa });
    }),

    nomeMae: _ember['default'].computed.alias("_newReclamante.nomeMae"),

    pessoa: _ember['default'].computed.alias("_newReclamante.pessoa"),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.isVisible) {
        this.set("_created", true);
      }
    }
  });
});