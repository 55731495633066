define("juridico-sengerj/utils/simple-pdf-table", ["exports"], function (exports) {
    exports["default"] = simpleTable;

    function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

    var HEADER_FONT_SIZE = 10;
    var VALUE_FONT_SIZE = 10;

    function simpleTable(doc, _ref) {
        var _ref$x = _ref.x;
        var x = _ref$x === undefined ? 0 : _ref$x;
        var _ref$y = _ref.y;
        var y = _ref$y === undefined ? 0 : _ref$y;
        var _ref$group = _ref.group;
        var group = _ref$group === undefined ? 1 : _ref$group;
        var _ref$startRowIndex = _ref.startRowIndex;
        var startRowIndex = _ref$startRowIndex === undefined ? 0 : _ref$startRowIndex;
        var _ref$lineHeight = _ref.lineHeight;
        var lineHeight = _ref$lineHeight === undefined ? 8 : _ref$lineHeight;
        var rows = arguments.length <= 2 || arguments[2] === undefined ? [] : arguments[2];

        var minWidths = [];
        var cursorX = x;
        var cursorY = y;

        rangeArray(group).forEach(function (i) {
            minWidths[i] = {};

            doc.setFontSize(VALUE_FONT_SIZE);
            minWidths[i].value = getLargestText(doc, rangeArray(rows.length).map(function (j) {
                return rows[j][i].value;
            })) + 2;

            doc.setFontSize(HEADER_FONT_SIZE);
            doc.setFontStyle('bold');
            minWidths[i].header = getLargestText(doc, rangeArray(rows.length).map(function (j) {
                return rows[j][i].header;
            })) + 2;
        });

        var pageWidth = doc.internal.pageSize.width;

        rows.forEach(function (row) {
            var cellValuesLines = [];
            var cellValuesWidth = [];
            row.forEach(function (cell) {
                var index = row.indexOf(cell);

                if (index == group - 1) {
                    cellValuesWidth[index] = pageWidth - (doc.marginRight + cursorX + minWidths[index].header);
                } else {
                    cellValuesWidth[index] = minWidths[index].value;
                }

                cursorX += minWidths[index].value;
                cursorX += minWidths[index].header;

                cellValuesLines.push(doc.splitTextToSize(cell.value || "", cellValuesWidth[index] - 2));
            });

            cursorX = x;

            row.forEach(function (cell) {
                var index = row.indexOf(cell);
                var rowIndex = rows.indexOf(row) + startRowIndex;
                var cellValueWidth = cellValuesWidth[index];

                var cellValueLines = cellValuesLines[index];
                var cellHeight = Math.max.apply(Math, _toConsumableArray(cellValuesLines.map(function (lines) {
                    return lines.length;
                }))) * lineHeight;

                createHeaderCell(doc, cell.header, cursorX, cursorY, cellHeight, minWidths[index].header);
                cursorX += minWidths[index].header;
                createCell(doc, cellValueLines, rowIndex, cursorX, cursorY, cellHeight, cellValueWidth);
                cursorX += minWidths[index].value;
            });

            cursorY += Math.max.apply(Math, _toConsumableArray(cellValuesLines.map(function (lines) {
                return lines.length;
            }))) * lineHeight;
            cursorX = x;
        });

        return cursorY;
    }

    function rangeArray(n) {
        return [].concat(_toConsumableArray(Array(n).keys()));
    }

    function getLargestText(doc, texts) {
        return Math.max.apply(Math, _toConsumableArray(texts.map(function (text) {
            return doc.getTextWidth(text || "");
        }))) + 2;
    }

    function createHeaderCell(doc, text, x, y, height, width) {
        doc.setFontStyle('bold');
        doc.setFillColor(255, 255, 255);
        doc.rect(x, y, width, height, 'F');

        doc.setFontSize(HEADER_FONT_SIZE);
        doc.setTextColor(0, 0, 0);

        var fontHeight = HEADER_FONT_SIZE / doc.internal.scaleFactor * 1.15;

        doc.text(x + 2, y + height / 2 + fontHeight / 2 - 0.5, text);
    }

    function createCell(doc, text, rowIndex, x, y, height, width) {
        doc.setFontStyle('normal');

        if (rowIndex % 2 == 0) {
            doc.setFillColor(244, 243, 243);
        } else {
            doc.setFillColor(255, 255, 255);
        }

        doc.rect(x, y, width, height, 'F');

        doc.setFontSize(VALUE_FONT_SIZE);
        doc.setTextColor(0, 0, 0);

        var fontHeight = VALUE_FONT_SIZE / doc.internal.scaleFactor * 1.15;

        doc.text(x + 2, y + (height / 2 + fontHeight / 2) / text.length, text);
    }
});