define('juridico-sengerj/components/simple-select-upload', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    progress: 0,
    uploadedUrl: null,
    isUploading: false,
    readonly: false,
    folder: 'default',
    classNames: 'simple-select-upload',
    removeImage: function removeImage() {},
    "finished-uploading": function finishedUploading() {},

    actions: {
      openFileDialog: function openFileDialog() {
        this.$().find("input.uploader").click();
      },

      removeImage: function removeImage(uploadedUrl) {
        this.set("uploadedUrl", null);
        _ember['default'].get(this, "removeImage")(uploadedUrl);
      },

      openFile: function openFile() {
        window.open(this.get("uploadedUrl"), '_blank');
      }
    },

    fileName: _ember['default'].computed("uploadedUrl", function () {
      var uploadedUrl = this.get("uploadedUrl");

      return uploadedUrl.substr(uploadedUrl.lastIndexOf('/') + 1).replace(/\+/g, " ");
    }),

    isImage: _ember['default'].computed("uploadedUrl", function () {
      var formato = /.+\.(.+)$/gm.exec(this.get("uploadedUrl"))[1];

      return _ember['default'].String.w("png jpg jpeg gif svg").includes(formato);
    })
  });
});