define('juridico-sengerj/controllers/dashboard/tipo-documentos/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: ['porId'],

    columnsTitle: ["Nome"],

    tipoDocumento: null,
    encerrarTipoDocumentoModalVisible: false,

    actions: {

      openModalTipoDocumento: function openModalTipoDocumento() {

        var tipoDocumento = this.store.createRecord('juridico/tipo-documento');

        this.setProperties({ encerrarTipoDocumentoModalVisible: true, tipoDocumento: tipoDocumento });
      },

      criarTipoDocumento: function criarTipoDocumento() {
        var _this = this;

        this.tipoDocumento.save().then(function () {
          _this.set("encerrarTipoDocumentoModalVisible", false);

          _this.container.lookup("route:dashboard.tipo-documentos").refresh();

          _ember['default'].$.niftyNoty({
            type: 'success',
            icon: 'fa fa-exclamation-triangle fa-2x',
            container: "floating",
            title: 'O tipo de documento ' + _this.tipoDocumento.get('nome') + ' foi criado com sucesso.',
            timer: 3000
          });
        });
      },

      removeTipoDocumento: function removeTipoDocumento(tipoDocumento) {
        tipoDocumento.destroyRecord().then(function () {

          _ember['default'].$.niftyNoty({
            type: 'success',
            icon: 'fa fa-exclamation-triangle fa-2x',
            container: "floating",
            title: 'O tipo de documento foi removido com sucesso.',
            timer: 3000
          });
        });
      },

      changeValue: function changeValue(tipoDocumento) {
        this.store.find('juridico/tipo-documento', tipoDocumento.id).then(function (tipoDocumento) {
          tipoDocumento.set('nome', tipoDocumento.get('nome'));
          tipoDocumento.save();

          _ember['default'].$.niftyNoty({
            type: 'success',
            icon: 'fa fa-exclamation-triangle fa-2x',
            container: "floating",
            title: 'O tipo de documento foi atualizado com sucesso.',
            timer: 3000
          });
        });
      },

      rollbackValue: function rollbackValue(tipoDocumento) {
        this.store.peekRecord('juridico/tipo-documento', tipoDocumento.id).rollbackAttributes();
      }
    }
  });
});