define("juridico-sengerj/components/filters-with-table/agendamentos-advogado", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    tagName: "",
    agendamentos: [],
    "apply-filters": function applyFilters() {},

    actions: {
      applyFilters: function applyFilters(filters) {
        _ember["default"].get(this, "apply-filters")(filters);
      }
    }
  });
});