define('juridico-sengerj/routes/dashboard/inicio/escritorio/index', ['exports', 'ember', 'juridico-sengerj/utils/format/date/short'], function (exports, _ember, _juridicoSengerjUtilsFormatDateShort) {
  exports['default'] = _ember['default'].Route.extend({
    _currentDate: (0, _juridicoSengerjUtilsFormatDateShort['default'])(),

    model: function model() {
      return _ember['default'].RSVP.hash({
        notify: this.store.ajaxDashboard('escritorio'),

        agendamentos: this.set("agendamentosAdvogados", this.get('store').query("juridico/advogado/agendamento", {
          dataAgendamentoIni: this._currentDate,
          dataAgendamentoFim: this._currentDate,
          include: 'advogado.pessoa'
        }))
      });
    }
  });
});