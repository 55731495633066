define('juridico-sengerj/components/select-enum', ['exports', 'ember', 'juridico-sengerj/templates/components/select-enum'], function (exports, _ember, _juridicoSengerjTemplatesComponentsSelectEnum) {
  exports['default'] = _ember['default'].Component.extend({
    layout: _juridicoSengerjTemplatesComponentsSelectEnum['default'],
    store: _ember['default'].inject.service(),
    disabled: false,
    allowClear: true,
    searchEnabled: true,
    selected: {},
    only: null,
    loadPromise: null,
    enums: _ember['default'].A(),
    placeholder: "",

    enumsText: _ember['default'].computed.mapBy('enums', 'text'),

    actions: {
      changeEnum: function changeEnum(text) {
        var selectedEnum = this.getEnumByText(text);

        this.set("selected", selectedEnum);

        return selectedEnum;
      }
    },

    getEnumByText: function getEnumByText(text) {
      if (text) {
        return {
          value: this.get("enums").findBy("text", text).get("value"),
          text: text
        };
      }

      return null;
    },

    modelName: _ember['default'].computed("enum", function () {
      return 'enum/' + this.enumModelName;
    }),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var loadPromise = this.get("store").findAll(this.get("modelName")).then(function (enums) {
        if (_this.only) {
          _this.set("enums", _ember['default'].A(enums).filter(function (enumVar) {
            return _this.only.includes(enumVar.get("value"));
          }));
        } else {
          _this.set("enums", enums);
        }
      });

      this.set("loadPromise", loadPromise);
    }
  });
});