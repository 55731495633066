define("juridico-sengerj/mixins/components/filter", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Mixin.create({
    name: null,
    filtersValues: {},
    "col-xs": "12",
    "col-sm": "4",
    "col-md": "4",
    "col-lg": "4",
    saveFilter: function saveFilter() {},
    saveFilterDataToShow: function saveFilterDataToShow() {},

    saveFilterValue: function saveFilterValue(value) {
      _ember["default"].get(this, "saveFilter")(this.name, value);
      this.updateFiltersToShow();
    },

    clearFilterValue: function clearFilterValue() {
      this.saveFilterValue(undefined);
    },

    updateFiltersToShow: function updateFiltersToShow() {
      if (_ember["default"].isPresent(this.get("valueToShow"))) {
        _ember["default"].get(this, "saveFilterDataToShow")(this.placeholder, this.get("valueToShow"));
      }
    },

    _updateFiltersToShow: _ember["default"].observer('valueToShow', function () {
      this.updateFiltersToShow();
    }),

    filterValue: _ember["default"].computed("filtersValues", function () {
      return this.filtersValues[this.name];
    }),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      ["col-sm", "col-xs", "col-md", "col-lg"].forEach(function (col) {
        _this.classNames.pushObject(col + "-" + _this.get(col));
      });
    }
  });
});