define('juridico-sengerj/mixins/change-password', ['exports', 'ember', 'ember-cp-validations'], function (exports, _ember, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    password: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', { min: 8 })],

    passwordConfirm: [(0, _emberCpValidations.validator)('confirmation', { on: 'password' })]
  });

  exports['default'] = _ember['default'].Mixin.create(Validations, {
    password: '',
    passwordConfirm: '',

    isNewPasswordValid: _ember['default'].computed.and("validations.attrs.password.isValid", "validations.attrs.passwordConfirm.isValid"),

    changePassword: function changePassword(userId, token) {
      var adapter = this.get("store").adapterFor();

      var patchData = { data: { id: userId, type: "usuarios", email: "tester" } };

      var success = function success() {
        _ember['default'].$.niftyNoty({
          type: 'success',
          icon: 'fa fa-exclamation-triangle fa-2x',
          container: 'div.cls-header',
          title: "A senha do seu Usuário foi redefinida",
          message: '',
          timer: 6000
        });
      };

      var error = function error() {
        _ember['default'].$.niftyNoty({
          type: 'danger',
          icon: 'fa fa-thumbs-o-down fa-2x',
          container: 'div.cls-header',
          title: 'Token expirado',
          message: 'É necessario um token válido para redefinir a senha, para conseguir um novo token clique em "Reenviar E-mail"',
          timer: 12000
        });
      };

      var url = adapter.buildURL("usuario", userId);

      if (token) {
        patchData.data.token = token;
      }

      return adapter.ajax(url, "PATCH", { data: patchData }).then(success)['catch'](error);
    }
  });
});