define("juridico-sengerj/components/panels/juridico/panel-show-valores", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    panelClasses: "",
    color: "first",
    valores: [],

    actions: {
      removeValor: function removeValor(valor) {
        if (!valor.get("isSaving")) {
          valor.destroyRecord();
        }
      },

      addValor: function addValor() {
        var valor = this.get("valores.content.record").getRecord();
        this.get("valores").createRecord({ valor: valor });
      }
    },

    valorTabs: _ember["default"].computed.mapBy("valores", "id")
  });
});