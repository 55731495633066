define('juridico-sengerj/transforms/numero-processo', ['exports', 'ember-data/transform', 'ember'], function (exports, _emberDataTransform, _ember) {
  exports['default'] = _emberDataTransform['default'].extend({
    deserialize: function deserialize(numero) {
      if (_ember['default'].isPresent(numero)) {
        return numero.slice(0, 7) + '-' + numero.slice(7, 9) + '.' + numero.slice(9, 13) + '.' + numero.slice(13, 14) + '.' + numero.slice(14, 16) + '.' + numero.slice(16, 20);
      }
    },

    serialize: function serialize() {
      var deserialized = arguments.length <= 0 || arguments[0] === undefined ? '' : arguments[0];

      return deserialized.replace(/\D/g, "");
    }
  });
});