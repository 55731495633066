define("juridico-sengerj/templates/components/comments-box", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 10,
                      "column": 12
                    },
                    "end": {
                      "line": 12,
                      "column": 12
                    }
                  },
                  "moduleName": "juridico-sengerj/templates/components/comments-box.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("i");
                  dom.setAttribute(el1, "class", "fa fa-send fa-fw");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" Salvar\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 9,
                    "column": 10
                  },
                  "end": {
                    "line": 13,
                    "column": 10
                  }
                },
                "moduleName": "juridico-sengerj/templates/components/comments-box.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "button-with-loading", [], ["click", ["subexpr", "action", ["saveComment", ["get", "comment", ["loc", [null, [10, 63], [10, 70]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 41], [10, 71]]], 0, 0], "loadingMessage", "Salvando", "isLoading", ["subexpr", "@mut", [["get", "comment.isSaving", ["loc", [null, [10, 108], [10, 124]]], 0, 0, 0, 0]], [], [], 0, 0], "classNames", "btn btn-primary pull-left"], 0, null, ["loc", [null, [10, 12], [12, 36]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 6,
                  "column": 8
                },
                "end": {
                  "line": 15,
                  "column": 8
                }
              },
              "moduleName": "juridico-sengerj/templates/components/comments-box.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              return morphs;
            },
            statements: [["inline", "textarea", [], ["value", ["subexpr", "@mut", [["get", "comment.descricao", ["loc", [null, [7, 27], [7, 44]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "@mut", [["get", "placeholder", ["loc", [null, [7, 57], [7, 68]]], 0, 0, 0, 0]], [], [], 0, 0], "autoresize", true, "required", true, "classNames", "form-control input-comment mar-btm"], ["loc", [null, [7, 10], [7, 149]]], 0, 0], ["block", "if", [["get", "showSent", ["loc", [null, [9, 16], [9, 24]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [9, 10], [13, 17]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 27,
                      "column": 12
                    },
                    "end": {
                      "line": 29,
                      "column": 12
                    }
                  },
                  "moduleName": "juridico-sengerj/templates/components/comments-box.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1, "class", "tag label label-primary");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                  return morphs;
                },
                statements: [["content", "group", ["loc", [null, [28, 52], [28, 61]]], 0, 0, 0, 0]],
                locals: ["group"],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 25,
                    "column": 10
                  },
                  "end": {
                    "line": 33,
                    "column": 10
                  }
                },
                "moduleName": "juridico-sengerj/templates/components/comments-box.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("h5");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "clearfix");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                return morphs;
              },
              statements: [["content", "groupsNotifiedMessage", ["loc", [null, [26, 16], [26, 41]]], 0, 0, 0, 0], ["block", "each", [["get", "groupsNotified", ["loc", [null, [27, 20], [27, 34]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [27, 12], [29, 21]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 35,
                    "column": 10
                  },
                  "end": {
                    "line": 39,
                    "column": 10
                  }
                },
                "moduleName": "juridico-sengerj/templates/components/comments-box.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1, "class", "btn btn-sm btn-primary btn-labeled fa fa-pencil");
                var el2 = dom.createTextNode("\n              Editar\n            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element3 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createElementMorph(element3);
                return morphs;
              },
              statements: [["element", "action", ["makeEditable", ["get", "comment", ["loc", [null, [36, 95], [36, 102]]], 0, 0, 0, 0]], [], ["loc", [null, [36, 71], [36, 104]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child2 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 41,
                    "column": 10
                  },
                  "end": {
                    "line": 43,
                    "column": 10
                  }
                },
                "moduleName": "juridico-sengerj/templates/components/comments-box.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "yield", [["get", "comment", ["loc", [null, [42, 20], [42, 27]]], 0, 0, 0, 0], ["subexpr", "action", ["makeEditable", ["get", "comment", ["loc", [null, [42, 51], [42, 58]]], 0, 0, 0, 0]], [], ["loc", [null, [42, 28], [42, 59]]], 0, 0]], [], ["loc", [null, [42, 12], [42, 61]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child3 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 45,
                    "column": 10
                  },
                  "end": {
                    "line": 49,
                    "column": 10
                  }
                },
                "moduleName": "juridico-sengerj/templates/components/comments-box.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1, "class", "btn btn-sm btn-danger btn-labeled fa fa-trash");
                var el2 = dom.createTextNode("\n              Remover\n            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element2 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createElementMorph(element2);
                return morphs;
              },
              statements: [["element", "action", [["subexpr", "invoke", ["destroyRecord", ["get", "comment", ["loc", [null, [46, 102], [46, 109]]], 0, 0, 0, 0]], [], ["loc", [null, [46, 78], [46, 110]]], 0, 0]], [], ["loc", [null, [46, 69], [46, 112]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 15,
                  "column": 8
                },
                "end": {
                  "line": 50,
                  "column": 8
                }
              },
              "moduleName": "juridico-sengerj/templates/components/comments-box.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "mar-btm");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2, "class", "text-semibold media-heading box-inline");
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("p");
              dom.setAttribute(el2, "class", "text-muted text-sm");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n          \n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element4 = dom.childAt(fragment, [1]);
              var morphs = new Array(7);
              morphs[0] = dom.createMorphAt(dom.childAt(element4, [1]), 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(element4, [3]), 0, 0);
              morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              morphs[3] = dom.createMorphAt(fragment, 5, 5, contextualElement);
              morphs[4] = dom.createMorphAt(fragment, 7, 7, contextualElement);
              morphs[5] = dom.createMorphAt(fragment, 9, 9, contextualElement);
              morphs[6] = dom.createMorphAt(fragment, 11, 11, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["content", "comment.nomeQuemCriou", ["loc", [null, [18, 14], [18, 39]]], 0, 0, 0, 0], ["inline", "format-date", [["get", "comment.createdAt", ["loc", [null, [20, 56], [20, 73]]], 0, 0, 0, 0], "full"], [], ["loc", [null, [20, 42], [20, 82]]], 0, 0], ["inline", "textarea", [], ["value", ["subexpr", "@mut", [["get", "comment.descricao", ["loc", [null, [23, 27], [23, 44]]], 0, 0, 0, 0]], [], [], 0, 0], "readonly", "true", "placeholder", ["subexpr", "@mut", [["get", "placeholder", ["loc", [null, [23, 73], [23, 84]]], 0, 0, 0, 0]], [], [], 0, 0], "autoresize", true, "required", true, "classNames", "form-control input-comment mar-btm"], ["loc", [null, [23, 10], [23, 165]]], 0, 0], ["block", "if", [["get", "groupsNotified", ["loc", [null, [25, 16], [25, 30]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [25, 10], [33, 17]]]], ["block", "if", [["get", "showEdit", ["loc", [null, [35, 16], [35, 24]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [35, 10], [39, 17]]]], ["block", "if", [["get", "hasBlock", ["loc", [null, [41, 16], [41, 24]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [41, 10], [43, 17]]]], ["block", "if", [["get", "showRemove", ["loc", [null, [45, 16], [45, 26]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [45, 10], [49, 17]]]]],
            locals: [],
            templates: [child0, child1, child2, child3]
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 54,
                  "column": 4
                },
                "end": {
                  "line": 56,
                  "column": 4
                }
              },
              "moduleName": "juridico-sengerj/templates/components/comments-box.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("hr");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 2
              },
              "end": {
                "line": 57,
                "column": 2
              }
            },
            "moduleName": "juridico-sengerj/templates/components/comments-box.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "media-block");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2, "class", "media-left");
            var el3 = dom.createElement("img");
            dom.setAttribute(el3, "class", "img-circle img-sm");
            dom.setAttribute(el3, "alt", "Perfil");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "media-body");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            var element6 = dom.childAt(element5, [1, 0]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element6, 'src');
            morphs[1] = dom.createMorphAt(dom.childAt(element5, [3]), 1, 1);
            morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["attribute", "src", ["get", "comment.fotoPerfil", ["loc", [null, [4, 79], [4, 97]]], 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["subexpr", "or", [["get", "comment.isEditing", ["loc", [null, [6, 18], [6, 35]]], 0, 0, 0, 0], ["get", "comment.isNew", ["loc", [null, [6, 36], [6, 49]]], 0, 0, 0, 0]], [], ["loc", [null, [6, 14], [6, 50]]], 0, 0]], [], 0, 1, ["loc", [null, [6, 8], [50, 15]]]], ["block", "if", [["subexpr", "gt", [["get", "comments.length", ["loc", [null, [54, 14], [54, 29]]], 0, 0, 0, 0], 1], [], ["loc", [null, [54, 10], [54, 32]]], 0, 0]], [], 2, null, ["loc", [null, [54, 4], [56, 11]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 59,
              "column": 0
            }
          },
          "moduleName": "juridico-sengerj/templates/components/comments-box.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "not", [["get", "comment.isDeleted", ["loc", [null, [2, 13], [2, 30]]], 0, 0, 0, 0]], [], ["loc", [null, [2, 8], [2, 31]]], 0, 0]], [], 0, null, ["loc", [null, [2, 2], [57, 9]]]]],
        locals: ["comment"],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 60,
                "column": 2
              },
              "end": {
                "line": 62,
                "column": 2
              }
            },
            "moduleName": "juridico-sengerj/templates/components/comments-box.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("h5");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["content", "emptyMessage", ["loc", [null, [61, 8], [61, 24]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 64,
                "column": 2
              },
              "end": {
                "line": 68,
                "column": 2
              }
            },
            "moduleName": "juridico-sengerj/templates/components/comments-box.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1, "class", "btn btn-purple btn-labeled fa fa-plus");
            var el2 = dom.createTextNode("\n      Adicionar Comentário\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element1);
            return morphs;
          },
          statements: [["element", "action", ["addNewComment"], [], ["loc", [null, [65, 53], [65, 79]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 59,
              "column": 0
            },
            "end": {
              "line": 69,
              "column": 0
            }
          },
          "moduleName": "juridico-sengerj/templates/components/comments-box.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "emptyMessage", ["loc", [null, [60, 8], [60, 20]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [60, 2], [62, 9]]]], ["block", "if", [["get", "showAdd", ["loc", [null, [64, 8], [64, 15]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [64, 2], [68, 9]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 71,
              "column": 0
            },
            "end": {
              "line": 75,
              "column": 0
            }
          },
          "moduleName": "juridico-sengerj/templates/components/comments-box.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "class", "btn btn-purple btn-labeled fa fa-plus");
          var el2 = dom.createTextNode("\n    Adicionar Comentário\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element0);
          return morphs;
        },
        statements: [["element", "action", ["addNewComment"], [], ["loc", [null, [72, 51], [72, 77]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 76,
            "column": 0
          }
        },
        "moduleName": "juridico-sengerj/templates/components/comments-box.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "each", [["get", "_comments", ["loc", [null, [1, 8], [1, 17]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [1, 0], [69, 9]]]], ["block", "if", [["subexpr", "and", [["subexpr", "not", [["get", "onlyOne", ["loc", [null, [71, 16], [71, 23]]], 0, 0, 0, 0]], [], ["loc", [null, [71, 11], [71, 24]]], 0, 0], ["get", "showAdd", ["loc", [null, [71, 25], [71, 32]]], 0, 0, 0, 0]], [], ["loc", [null, [71, 6], [71, 33]]], 0, 0]], [], 2, null, ["loc", [null, [71, 0], [75, 7]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});