define('juridico-sengerj/components/route-components/profile', ['exports', 'ember', 'juridico-sengerj/mixins/change-password'], function (exports, _ember, _juridicoSengerjMixinsChangePassword) {
  var computed = _ember['default'].computed;
  var $ = _ember['default'].$;
  var isBlank = _ember['default'].isBlank;
  var isPresent = _ember['default'].isPresent;
  exports['default'] = _ember['default'].Component.extend(_juridicoSengerjMixinsChangePassword['default'], {
    store: _ember['default'].inject.service(),

    user: null,

    usuarioEmpresasColumns: ["Empresa", "Cargo"],

    isAdvogadoValid: computed.alias("user.advogado.validations.isValid"),

    isUserValid: computed.alias("user.validations.isValid"),

    isPersonValid: computed.alias("person.validations.isValid"),

    userValid: computed("isUserValid", "isAdvogadoValid", "isPersonValid", function () {
      if (this.get("user.isAdvogado")) {
        return this.get("isUserValid") && this.get("isAdvogadoValid") && this.get("isPersonValid");
      }

      return this.get("isUserValid") && this.get("isPersonValid");
    }),

    userPermissions: computed.alias('user.permissoes'),

    person: computed.alias('user.pessoa'),

    personId: computed.alias('person.id'),

    changePasswordModalVisible: false,

    actions: {
      newProfilePic: function newProfilePic(imageUrl) {
        var person = this.get("person.content");

        person.set("fotoUrl", imageUrl);

        if (!person.get("isNew")) {
          person.save();
        }
      },

      resetPassword: function resetPassword() {
        var _this = this;

        this.changePassword(this.get("user.id"))['finally'](function () {
          _this.toggleProperty("changePasswordModalVisible");
        });
      },

      addUsuarioEmpresa: function addUsuarioEmpresa() {
        this.get("user.usuarioEmpresas").createRecord();
      },

      saveUser: function saveUser() {
        var isNewUse, person, user, advogado;
        return regeneratorRuntime.async(function saveUser$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              isNewUse = this.get("user.isNew");
              context$1$0.next = 3;
              return regeneratorRuntime.awrap(this.get("person"));

            case 3:
              person = context$1$0.sent;
              context$1$0.next = 6;
              return regeneratorRuntime.awrap(person.saveAll());

            case 6:
              context$1$0.next = 8;
              return regeneratorRuntime.awrap(this.get("user").save());

            case 8:
              user = context$1$0.sent;

              if (!user.get("isAdvogado")) {
                context$1$0.next = 17;
                break;
              }

              context$1$0.next = 12;
              return regeneratorRuntime.awrap(user.get("advogado"));

            case 12:
              advogado = context$1$0.sent;
              context$1$0.next = 15;
              return regeneratorRuntime.awrap(advogado.save());

            case 15:
              context$1$0.next = 20;
              break;

            case 17:
              if (!user.get("isFuncionarioEmpresa")) {
                context$1$0.next = 20;
                break;
              }

              context$1$0.next = 20;
              return regeneratorRuntime.awrap(this._saveUsuarioEmpresas());

            case 20:

              _ember['default'].$.niftyNoty({
                type: 'success',
                icon: 'fa fa-exclamation-triangle fa-2x',
                container: "floating",
                title: 'O usuário foi ' + (isNewUse ? 'criado' : 'alterado'),
                timer: 3000
              });

              history.back();

            case 22:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      },

      resetUserPassword: function resetUserPassword() {
        this.toggleProperty("changePasswordModalVisible");
      },

      toogleUser: function toogleUser() {
        this.user.toggleProperty("ativo");
        this.user.save();
      },

      togglePermission: function togglePermission(permissoesValues) {
        var permissions = this.get("allPermissions").filter(function (permission) {
          return permissoesValues.contains(permission.get("value"));
        });

        this.user.set("permissoes", permissions);
      }
    },

    _saveUsuarioEmpresas: function _saveUsuarioEmpresas() {
      this.get("user.usuarioEmpresas").map(function (u) {
        return u.save();
      });
    },

    init: function init() {
      this._super.apply(this, arguments);
      this.set("allPermissions", this.get("store").findAll("enum/usuario/permissao"));

      if (this.user.get("isNew")) {
        var advogado = this.get("store").createRecord("juridico/advogado");
        var session = this.get("session");

        if (session.get("isEscritorio")) {
          advogado.set("escritorio", session.get("currentUser.escritorioResponsavel"));
        }

        this.user.set("advogado", advogado);
      }
    }
  });
});