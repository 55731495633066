define('juridico-sengerj/services/store', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Store.extend({
    adapterFor: function adapterFor() {
      var modelName = arguments.length <= 0 || arguments[0] === undefined ? 'application' : arguments[0];

      if (modelName.startsWith("enum")) {
        return this._super("enum");
      }
      return this._super(modelName);
    },

    ajaxDashboard: function ajaxDashboard(profile) {
      var adapter = this.adapterFor();
      return adapter.ajax(adapter.host + '/dashboard/' + profile);
    }
  });
});