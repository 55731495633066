define('juridico-sengerj/models/juridico/advogado/atendimento/documento-necessario', ['exports', 'juridico-sengerj/models/abstract-model', 'ember-data', 'ember'], function (exports, _juridicoSengerjModelsAbstractModel, _emberData, _ember) {
	exports['default'] = _juridicoSengerjModelsAbstractModel['default'].extend({
		arquivoUrl: _emberData['default'].attr('string'),
		atendimento: _emberData['default'].belongsTo('juridico/advogado/atendimento'),
		tipoDocumento: _emberData['default'].belongsTo('juridico/tipo-documento'),

		enviado: _ember['default'].computed("arquivoUrl", function () {
			return _ember['default'].isPresent(this.get("arquivoUrl"));
		})
	});
});