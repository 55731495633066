define('juridico-sengerj/models/juridico/acordo', ['exports', 'juridico-sengerj/models/abstract-model', 'ember-data', 'ember', 'ember-cp-validations'], function (exports, _juridicoSengerjModelsAbstractModel, _emberData, _ember, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    formaPagamento: (0, _emberCpValidations.validator)('presence', true)
  });

  exports['default'] = _juridicoSengerjModelsAbstractModel['default'].extend(Validations, {
    ocorrencia: _emberData['default'].belongsTo('juridico/ocorrencia'),
    formaPagamento: _emberData['default'].attr('enum'),

    dataPagamentos: _emberData['default'].hasMany('juridico/data-pagamento')
  });
});