define("juridico-sengerj/routes/dashboard/inicio/advogado/pastas", ["exports", "juridico-sengerj/routes/dashboard/pastas/index"], function (exports, _juridicoSengerjRoutesDashboardPastasIndex) {
  exports["default"] = _juridicoSengerjRoutesDashboardPastasIndex["default"].extend({
    queryParams: {
      porAdvogadoId: {
        replace: true,
        refreshModel: true
      },

      semOcorrenciasNovas60Dias: {
        replace: true,
        refreshModel: true
      },

      porStatus: {
        replace: true,
        refreshModel: true
      }
    }
  });
});