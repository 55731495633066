define('juridico-sengerj/components/user-dropdown', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['dropdown'],
    tagName: 'li',
    elementId: 'dropdown-user',

    actions: {
      logout: function logout() {
        this.get('session').invalidate();
      }
    }
  });
});