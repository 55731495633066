define('juridico-sengerj/controllers/login/index', ['exports', 'ember', 'ember-cp-validations'], function (exports, _ember, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    identification: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: ' '
    })],
    password: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: ' '
    })]
  });

  exports['default'] = _ember['default'].Controller.extend(Validations, {
    isSigningIn: false,
    identification: '',
    password: '',

    actions: {
      authenticate: function authenticate() {
        var _this = this;

        this.set("isSigningIn", true);
        var credentials = this.getProperties('identification', 'password');

        this.get('session').authenticate('authenticator:token', credentials).then(function () {
          _this.set("isSigningIn", true);
          _ember['default'].$('body').velocity("fadeOut", { duration: 200, complete: function complete() {
              _this.transitionToRoute('dashboard');
            } });
        })['catch'](function (reason) {
          _this.set("isSigningIn", false);
          _ember['default'].$.niftyNoty({
            type: 'danger',
            icon: 'fa fa-thumbs-o-down fa-2x',
            container: 'div.cls-header',
            title: 'Login Incorreto',
            message: 'E-mail ou senha está incorreto',
            timer: 3000
          });
        });
      }
    }
  });
});