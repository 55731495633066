define("juridico-sengerj/templates/dashboard/menu/-interno-senge", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 20,
                  "column": 4
                },
                "end": {
                  "line": 27,
                  "column": 4
                }
              },
              "moduleName": "juridico-sengerj/templates/dashboard/menu/-interno-senge.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(6);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
              morphs[3] = dom.createMorphAt(fragment, 7, 7, contextualElement);
              morphs[4] = dom.createMorphAt(fragment, 9, 9, contextualElement);
              morphs[5] = dom.createMorphAt(fragment, 11, 11, contextualElement);
              return morphs;
            },
            statements: [["inline", "dashboard-menu", ["dashboard.objeto-acoes.index"], ["parent", ["subexpr", "@mut", [["get", "configuracao", ["loc", [null, [21, 61], [21, 73]]], 0, 0, 0, 0]], [], [], 0, 0], "title", "Tipo de Objeto"], ["loc", [null, [21, 6], [21, 98]]], 0, 0], ["inline", "dashboard-menu", ["dashboard.ramo-direitos.index"], ["parent", ["subexpr", "@mut", [["get", "configuracao", ["loc", [null, [22, 62], [22, 74]]], 0, 0, 0, 0]], [], [], 0, 0], "title", "Tipo de Ramo"], ["loc", [null, [22, 6], [22, 97]]], 0, 0], ["inline", "dashboard-menu", ["dashboard.tipo-reclamacoes.index"], ["parent", ["subexpr", "@mut", [["get", "configuracao", ["loc", [null, [23, 65], [23, 77]]], 0, 0, 0, 0]], [], [], 0, 0], "title", "Tipo de Ação"], ["loc", [null, [23, 6], [23, 100]]], 0, 0], ["inline", "dashboard-menu", ["dashboard.tipo-documentos.index"], ["parent", ["subexpr", "@mut", [["get", "configuracao", ["loc", [null, [24, 64], [24, 76]]], 0, 0, 0, 0]], [], [], 0, 0], "title", "Tipo de Documento"], ["loc", [null, [24, 6], [24, 104]]], 0, 0], ["inline", "dashboard-menu", ["dashboard.tipo-ocorrencias.index"], ["parent", ["subexpr", "@mut", [["get", "configuracao", ["loc", [null, [25, 65], [25, 77]]], 0, 0, 0, 0]], [], [], 0, 0], "title", "Tipo de Ocorrência"], ["loc", [null, [25, 6], [25, 106]]], 0, 0], ["inline", "dashboard-menu", ["dashboard.tipo-encerramentos.index"], ["parent", ["subexpr", "@mut", [["get", "configuracao", ["loc", [null, [26, 67], [26, 79]]], 0, 0, 0, 0]], [], [], 0, 0], "title", "Tipo de Arquivamento de Processo"], ["loc", [null, [26, 6], [26, 122]]], 0, 0]],
            locals: ["configuracao"],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 19,
                "column": 2
              },
              "end": {
                "line": 28,
                "column": 2
              }
            },
            "moduleName": "juridico-sengerj/templates/dashboard/menu/-interno-senge.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "dashboard-menu", [], ["parent", ["subexpr", "@mut", [["get", "acao", ["loc", [null, [20, 29], [20, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "icon", "fa fa-cog", "title", "Configurações"], 0, null, ["loc", [null, [20, 4], [27, 23]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 0
            },
            "end": {
              "line": 30,
              "column": 0
            }
          },
          "moduleName": "juridico-sengerj/templates/dashboard/menu/-interno-senge.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          morphs[3] = dom.createMorphAt(fragment, 7, 7, contextualElement);
          morphs[4] = dom.createMorphAt(fragment, 9, 9, contextualElement);
          morphs[5] = dom.createMorphAt(fragment, 11, 11, contextualElement);
          morphs[6] = dom.createMorphAt(fragment, 13, 13, contextualElement);
          return morphs;
        },
        statements: [["inline", "dashboard-menu", ["dashboard.escritorios.index"], ["parent", ["subexpr", "@mut", [["get", "acao", ["loc", [null, [7, 56], [7, 60]]], 0, 0, 0, 0]], [], [], 0, 0], "icon", "fa fa-suitcase", "title", "Escritórios"], ["loc", [null, [7, 2], [7, 104]]], 0, 0], ["inline", "dashboard-menu", ["dashboard.agendamentos.advogados.index"], ["parent", ["subexpr", "@mut", [["get", "acao", ["loc", [null, [9, 67], [9, 71]]], 0, 0, 0, 0]], [], [], 0, 0], "icon", "fa fa-calendar", "title", "Agendamentos"], ["loc", [null, [9, 2], [9, 116]]], 0, 0], ["inline", "dashboard-menu", ["dashboard.atendimentos.advogados.index"], ["parent", ["subexpr", "@mut", [["get", "acao", ["loc", [null, [11, 67], [11, 71]]], 0, 0, 0, 0]], [], [], 0, 0], "icon", "fa fa-pencil-square-o", "title", "Atendimentos"], ["loc", [null, [11, 2], [11, 123]]], 0, 0], ["inline", "dashboard-menu", ["dashboard.pastas.index"], ["icon", "fa-folder", "parent", ["subexpr", "@mut", [["get", "acao", ["loc", [null, [13, 68], [13, 72]]], 0, 0, 0, 0]], [], [], 0, 0], "title", "Pastas"], ["loc", [null, [13, 2], [13, 90]]], 0, 0], ["inline", "dashboard-menu", ["dashboard.alvaras.index"], ["icon", "fa-folder", "parent", ["subexpr", "@mut", [["get", "acao", ["loc", [null, [15, 69], [15, 73]]], 0, 0, 0, 0]], [], [], 0, 0], "title", "Ocorrências Financeira"], ["loc", [null, [15, 2], [15, 107]]], 0, 0], ["inline", "dashboard-menu", ["dashboard.reclamante-contratos.index"], ["icon", "fa-folder", "parent", ["subexpr", "@mut", [["get", "acao", ["loc", [null, [17, 82], [17, 86]]], 0, 0, 0, 0]], [], [], 0, 0], "title", "Contratos Reclamantes"], ["loc", [null, [17, 2], [17, 119]]], 0, 0], ["block", "if", [["get", "session.isAdmins", ["loc", [null, [19, 8], [19, 24]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [19, 2], [28, 9]]]]],
        locals: ["acao"],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 38,
                  "column": 4
                },
                "end": {
                  "line": 41,
                  "column": 4
                }
              },
              "moduleName": "juridico-sengerj/templates/dashboard/menu/-interno-senge.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              return morphs;
            },
            statements: [["inline", "dashboard-menu", ["dashboard.configuracao-homologacoes.index"], ["parent", ["subexpr", "@mut", [["get", "configuracao", ["loc", [null, [39, 74], [39, 86]]], 0, 0, 0, 0]], [], [], 0, 0], "title", "Agendamento"], ["loc", [null, [39, 6], [39, 108]]], 0, 0], ["inline", "dashboard-menu", ["dashboard.feriados.index"], ["parent", ["subexpr", "@mut", [["get", "configuracao", ["loc", [null, [40, 57], [40, 69]]], 0, 0, 0, 0]], [], [], 0, 0], "title", "Feriados"], ["loc", [null, [40, 6], [40, 88]]], 0, 0]],
            locals: ["configuracao"],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 37,
                "column": 2
              },
              "end": {
                "line": 42,
                "column": 2
              }
            },
            "moduleName": "juridico-sengerj/templates/dashboard/menu/-interno-senge.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "dashboard-menu", [], ["parent", ["subexpr", "@mut", [["get", "homologacao", ["loc", [null, [38, 29], [38, 40]]], 0, 0, 0, 0]], [], [], 0, 0], "icon", "fa fa-cog", "title", "Configurações"], 0, null, ["loc", [null, [38, 4], [41, 23]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 32,
              "column": 0
            },
            "end": {
              "line": 44,
              "column": 0
            }
          },
          "moduleName": "juridico-sengerj/templates/dashboard/menu/-interno-senge.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          return morphs;
        },
        statements: [["inline", "dashboard-menu", ["dashboard.agendamentos.homologacoes.index"], ["parent", ["subexpr", "@mut", [["get", "homologacao", ["loc", [null, [34, 70], [34, 81]]], 0, 0, 0, 0]], [], [], 0, 0], "icon", "fa fa-calendar", "title", "Agendamentos"], ["loc", [null, [34, 2], [34, 126]]], 0, 0], ["inline", "dashboard-menu", ["dashboard.atendimentos.homologacoes.index"], ["parent", ["subexpr", "@mut", [["get", "homologacao", ["loc", [null, [35, 70], [35, 81]]], 0, 0, 0, 0]], [], [], 0, 0], "icon", "fa fa-pencil-square-o", "title", "Realizadas"], ["loc", [null, [35, 2], [35, 131]]], 0, 0], ["block", "if", [["get", "session.isAdmins", ["loc", [null, [37, 8], [37, 24]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [37, 2], [42, 9]]]]],
        locals: ["homologacao"],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 49,
                "column": 2
              },
              "end": {
                "line": 51,
                "column": 2
              }
            },
            "moduleName": "juridico-sengerj/templates/dashboard/menu/-interno-senge.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "dashboard-menu", ["dashboard.banco-pagadores.index"], ["parent", ["subexpr", "@mut", [["get", "configuracao", ["loc", [null, [50, 62], [50, 74]]], 0, 0, 0, 0]], [], [], 0, 0], "title", "Bancos"], ["loc", [null, [50, 4], [50, 91]]], 0, 0]],
          locals: ["configuracao"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 46,
              "column": 0
            },
            "end": {
              "line": 53,
              "column": 0
            }
          },
          "moduleName": "juridico-sengerj/templates/dashboard/menu/-interno-senge.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["inline", "dashboard-menu", ["dashboard.contrato-escritorios.index"], ["parent", ["subexpr", "@mut", [["get", "financeiro", ["loc", [null, [48, 65], [48, 75]]], 0, 0, 0, 0]], [], [], 0, 0], "icon", "fa fa-file", "title", "Contratos"], ["loc", [null, [48, 2], [48, 113]]], 0, 0], ["block", "dashboard-menu", [], ["parent", ["subexpr", "@mut", [["get", "financeiro", ["loc", [null, [49, 27], [49, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "icon", "fa fa-cog", "title", "Configurações"], 0, null, ["loc", [null, [49, 2], [51, 21]]]]],
        locals: ["financeiro"],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 54,
            "column": 0
          }
        },
        "moduleName": "juridico-sengerj/templates/dashboard/menu/-interno-senge.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        morphs[4] = dom.createMorphAt(fragment, 8, 8, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "dashboard-menu", ["dashboard.inicio.admin.index"], ["icon", "fa-home", "title", "Página Inicial"], ["loc", [null, [1, 0], [1, 89]]], 0, 0], ["inline", "dashboard-menu", ["dashboard.usuarios.index"], ["icon", "fa-group", "title", "Usuários"], ["loc", [null, [3, 0], [3, 79]]], 0, 0], ["block", "dashboard-menu", [], ["icon", "fa fa-gavel", "title", "Ações"], 0, null, ["loc", [null, [5, 0], [30, 19]]]], ["block", "dashboard-menu", [], ["icon", "fa fa-book", "title", "Homologações"], 1, null, ["loc", [null, [32, 0], [44, 19]]]], ["block", "dashboard-menu", [], ["icon", "fa fa-university", "title", "Financeiro"], 2, null, ["loc", [null, [46, 0], [53, 19]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});