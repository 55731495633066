define('juridico-sengerj/components/route-components/form-pasta/detalhes', ['exports', 'ember', 'juridico-sengerj/mixins/pages-loader'], function (exports, _ember, _juridicoSengerjMixinsPagesLoader) {
  exports['default'] = _ember['default'].Component.extend(_juridicoSengerjMixinsPagesLoader['default'], {
    pasta: null,

    isAvulsa: false,

    dadosAtendimento: _ember['default'].computed.alias("pasta.dadosAtendimento"),

    reclamante: _ember['default'].computed.reads("dadosAtendimento.reclamantes.firstObject"),

    actions: {
      selectedAdvogado: function selectedAdvogado(advogado) {
        if (!this.get("pasta.hasPastaAvulsa")) {
          this.get("pasta").set("escritorio", advogado.get("escritorio"));
        }
      },

      toggleGanhoPecuniario: function toggleGanhoPecuniario() {
        pasta.toggleProperty(pasta.ganhoPecuniario);
        pasta.save();
      },

      cleanReclamantes: function cleanReclamantes() {
        this.get("pasta").set("reclamantes", []);
      },

      selectedReclamante: function selectedReclamante(pessoa) {
        var dadosAtendimento = this.get("dadosAtendimento.content");
        var reclamantes = dadosAtendimento.get("reclamantes");

        reclamantes.then(function (reclamantes) {
          var reclamante = reclamantes.get("firstObject");

          if (reclamante) {
            reclamante.set("pessoa", pessoa);
          } else {
            dadosAtendimento.get("reclamantes").createRecord({ pessoa: pessoa, reclamavel: dadosAtendimento });
          }
        });
      },

      changeObjetosAcao: function changeObjetosAcao(objetos) {
        var pasta = this.get("pasta");

        this.set("pasta.objetosAcao", objetos);

        if (!pasta.get("isNew")) {
          pasta.save({ force: true });
        }
      },

      changetipoReclamacoes: function changetipoReclamacoes(tipos) {
        var pasta = this.get("pasta");

        pasta.set("tipoReclamacoes", tipos);

        if (!pasta.get("isNew")) {
          pasta.save({ force: true });
        }
      }
    },

    naoPossuiPermissaoParaEditar: _ember['default'].computed("session.currentUser.id", function () {
      return this.get("session.isSocio") || this.get("session.isAdvogado");
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.loadPages("objetosAcao", "juridico/objeto-acao");
      this.loadPages("tipoReclamacoes", "juridico/tipo-reclamacao");
    }
  });
});