define('juridico-sengerj/routes/dashboard/contrato-escritorios/contrato-escritorio', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(_ref) {
      var contrato_id = _ref.contrato_id;

      return this.store.findRecord("juridico/contrato-escritorio", contrato_id, {
        include: 'escritorio'
      });
    },

    actions: {
      contratoEscritorioSaved: function contratoEscritorioSaved() {
        _ember['default'].$.niftyNoty({
          type: 'success',
          icon: 'fa fa-exclamation-triangle fa-2x',
          container: "floating",
          title: 'O Contrato foi alterado.',
          timer: 3000
        });

        this.get('router').transitionTo('dashboard.contrato-escritorios.index');
      }
    }
  });
});