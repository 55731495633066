define('juridico-sengerj/routes/dashboard/configuracao-homologacoes/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    actions: {
      toggleDiaHomologacao: function toggleDiaHomologacao(dayWeek) {
        this.currentModel.toggleProperty(dayWeek);
      },

      saveConfig: function saveConfig() {
        this.currentModel.save();
      }
    },

    model: function model(params) {
      return this.get('store').findRecord('juridico/homologacao/configuracao-homologacao', 1);
    }
  });
});