define("juridico-sengerj/services/session", ["exports", "ember", "ember-simple-auth/services/session"], function (exports, _ember, _emberSimpleAuthServicesSession) {
  exports["default"] = _emberSimpleAuthServicesSession["default"].extend({

    store: _ember["default"].inject.service(),

    isAdvogado: _ember["default"].computed.alias("currentUser.isAdvogado"),
    isAdvogadoPleno: _ember["default"].computed.alias("currentUser.isAdvogadoPleno"),
    isAdmin: _ember["default"].computed.alias("currentUser.isAdmin"),
    isFuncionarioEmpresa: _ember["default"].computed.alias("currentUser.isFuncionarioEmpresa"),
    isFuncionarioJuridico: _ember["default"].computed.alias("currentUser.isFuncionarioJuridico"),
    isPessoa: _ember["default"].computed.alias("currentUser.isPessoa"),
    isEscritorio: _ember["default"].computed.alias("currentUser.isEscritorio"),
    isInternoSenge: _ember["default"].computed.alias("currentUser.isInternoSenge"),
    isAdmins: _ember["default"].computed.alias("currentUser.isAdmins"),
    isSocio: _ember["default"].computed.alias("currentUser.isSocio"),

    currentUser: _ember["default"].computed('data.authenticated', function () {
      if (this.get('isAuthenticated')) {
        return this.get('store').findRecord('usuario', this.get('data').authenticated.usuarioId);
      }
    })
  });
});