define("juridico-sengerj/utils/pdf-header-pasta-info", ["exports", "juridico-sengerj/utils/simple-pdf-table"], function (exports, _juridicoSengerjUtilsSimplePdfTable) {
    exports["default"] = pdfHeaderPastaInfo;

    function pdfHeaderPastaInfo(doc, pasta, totalReclamantes) {
        doc.marginLeft = 20;
        doc.marginRight = 20;

        var finalY = (0, _juridicoSengerjUtilsSimplePdfTable["default"])(doc, { x: 20, y: 30, group: 2 }, [[{ header: "Pasta", value: pasta.get("showCode") }, { header: "Nª do Processo", value: pasta.get("numeroProcesso") }], [{ header: "Comarca", value: pasta.get("orgaoJustica.jurisdicao.text") }, { header: "Vara", value: pasta.get("orgaoJustica.nome") }], [{ header: "Reclamantes      ", value: totalReclamantes.toString() }, { header: "Cabeça do Processo", value: pasta.get("cabecaProcesso") }]]);

        finalY = (0, _juridicoSengerjUtilsSimplePdfTable["default"])(doc, { x: 20, y: finalY, group: 1, startRowIndex: 1 }, [[{ header: "Réu Principal", value: pasta.get("reusEmpresas.firstObject.reuzavel.razaoSocial") }], [{ header: "Objeto Principal", value: pasta.get("objetosAcao.firstObject.nome") }], [{ header: "Advogado", value: pasta.get("advogado.nome") }]]);

        doc.setDrawColor(169, 169, 169);
        doc.line(20, finalY + 6, 190, finalY + 6);
    }
});