define('juridico-sengerj/adapters/enum', ['exports', 'ember', 'juridico-sengerj/adapters/application'], function (exports, _ember, _juridicoSengerjAdaptersApplication) {
  var String = _ember['default'].String;
  exports['default'] = _juridicoSengerjAdaptersApplication['default'].extend({
    pathForType: function pathForType(type) {
      if (type === 'enum/usuario/permissao') {
        return String.pluralize(type);
      }
      return String.underscore(type);
    }
  });
});