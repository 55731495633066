define("juridico-sengerj/components/panels/panel-toggleable-panel", ["exports", "juridico-sengerj/components/panels/panel-simple"], function (exports, _juridicoSengerjComponentsPanelsPanelSimple) {
  exports["default"] = _juridicoSengerjComponentsPanelsPanelSimple["default"].extend({
    classNames: ["toggleable-panel"],
    animationDuration: 150,
    toggled: false,
    disabled: false,
    isValid: false,
    disabledClass: "",
    _isLoaded: false,
    "on-click": function onClick() {},

    actions: {
      togglePanel: function togglePanel() {
        if (!this.disabled) {
          this.toggleProperty("toggled");
          Ember.get(this, "on-click")();
        }
      }
    }
  });
});