define("juridico-sengerj/routes/dashboard/pastas/novo", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Route.extend({
    model: function model(_ref) {
      var atendimento_id = _ref.atendimento_id;

      var store = this.get("store");
      var atendimento = store.findRecord("juridico/advogado/atendimento", atendimento_id, { include: "agendamento" });

      return atendimento.then(function (atendimento) {
        return store.createRecord("juridico/pasta", { atendimento: atendimento });
      });
    }
  });
});