define('juridico-sengerj/routes/dashboard/agendamentos/homologacoes/agendamento', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    actions: {
      agendamentoSaved: function agendamentoSaved(agendamento) {
        _ember['default'].$.niftyNoty({
          type: 'success',
          icon: 'fa fa-exclamation-triangle fa-2x',
          container: "floating",
          title: 'O Agendamento foi salvo.',
          timer: 3000
        });

        this.get('router').transitionTo('dashboard.agendamentos.homologacoes');
      }
    },

    model: function model(_ref) {
      var agendamento_id = _ref.agendamento_id;

      return this.store.findRecord("juridico/homologacao/agendamento", agendamento_id);
    }
  });
});