define('juridico-sengerj/components/route-components/agendamento-advogado', ['exports', 'ember', 'juridico-sengerj/utils/format/date/short', 'juridico-sengerj/mixins/components/load-holidays-calendar'], function (exports, _ember, _juridicoSengerjUtilsFormatDateShort, _juridicoSengerjMixinsComponentsLoadHolidaysCalendar) {

  var propertiesToLoad = ["holidays", "profissionalsSchedules"];
  var isEmpty = _ember['default'].isEmpty;
  var get = _ember['default'].get;
  var isPresent = _ember['default'].isPresent;
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend(_juridicoSengerjMixinsComponentsLoadHolidaysCalendar['default'], {
    "on-saved": function onSaved() {},
    agendamento: null,
    currentIndex: 0,
    currentCalendarDate: new Date(),
    holidays: [],
    profissionalsSchedules: [],
    calendarioToggled: false,
    dadosAgendamentoToggled: false,
    isHolidaysLoading: true,
    isProfissionalsSchedulesLoading: true,
    store: _ember['default'].inject.service(),

    actions: {
      setCalendarChoosenDate: function setCalendarChoosenDate(dataHorario) {
        this.set("currentCalendarDate", dataHorario);
        this.agendamento.set("dataHorario", dataHorario);
        this.setProperties({
          isHolidaysLoading: true,
          isProfissionalsSchedulesLoading: true,
          calendarioToggled: false,
          dadosAgendamentoToggled: true
        });
      },

      selectedAdvogado: function selectedAdvogado(advogado) {
        this.agendamento.set("escritorio", advogado.get("escritorio"));
      },

      adicionarReclamante: function adicionarReclamante() {
        var store = this.get("store");
        var reclamante = store.createRecord("juridico/reclamante", {
          reclamavelType: 'Juridico::Agendamento'
        });

        this.agendamento.get("reclamantes").pushObject(reclamante);
      },

      removerReclamante: function removerReclamante(reclamante) {
        reclamante.destroyRecord();
      },

      calendarMonthChanged: function calendarMonthChanged(date) {
        this._loadHolidays();
        this._loadProfissionalsSchedules();
      },

      finishAgendamento: function finishAgendamento() {
        var _this = this;

        this.agendamento.save().then(function (agendamento) {

          get(_this, "on-saved")(agendamento);

          return agendamento.get("reclamantes");
        }).then(function (reclamantes) {
          return _ember['default'].RSVP.all(reclamantes.map(function (r) {
            r.set("reclamavel", _this.agendamento);
            return r.save();
          }));
        }).then(function () {
          _this.get('router').transitionTo('dashboard.agendamentos.advogados');
        });
      }
    },

    dataHorarioPresent: computed("agendamento.dataHorario", function () {
      return _ember['default'].isPresent(this.get("agendamento.dataHorario"));
    }),

    agendamentoIsValid: computed.and("agendamento.validations.isValid", "allReclamantesIsValid", "dataHorarioPresent"),

    reclamantes: computed.alias("agendamento.reclamantes"),

    reclamantesValidations: computed.mapBy("reclamantes", "validations"),

    allReclamantesIsValid: computed("reclamantesValidations.@each.isValid", function () {
      return this.get("reclamantesValidations").every(function (reclamanteValidation) {
        return reclamanteValidation.get("isValid");
      });
    }),

    _loadProfissionalsSchedules: function _loadProfissionalsSchedules() {
      var _this2 = this;

      var store = this.get("store");

      var date = this.currentCalendarDate;
      var year = date.getFullYear();
      var month = date.getMonth();

      var dateFilters = {
        dataAgendamentoIni: (0, _juridicoSengerjUtilsFormatDateShort['default'])(new Date(year, month, 1)),
        dataAgendamentoFim: (0, _juridicoSengerjUtilsFormatDateShort['default'])(new Date(year, month + 1, 0))
      };

      store.query("juridico/advogado/agendamento-ocupado", dateFilters).then(function (schedules) {
        var profissionalsSchedules = schedules.map(function (schedule) {
          return { date: schedule.get("dataHorario"), message: "Já possui agendamento marcado", isOcupado: true };
        });

        _this2.setProperties({
          profissionalsSchedules: profissionalsSchedules,
          isProfissionalsSchedulesLoading: false
        });
      });
    },

    _loadHolidays: function _loadHolidays() {
      var _this3 = this;

      this.loadHolidays(this.currentCalendarDate).then(function (holidays) {
        _this3.setProperties({
          holidays: holidays,
          isHolidaysLoading: false
        });
      });
    },

    init: function init() {
      this._super.apply(this, arguments);
      var store = this.get("store");

      if (this.agendamento.get("isNew")) {
        this.toggleProperty("calendarioToggled");
      } else {
        this.toggleProperty("dadosAgendamentoToggled");
      }

      this._loadHolidays();
      this._loadProfissionalsSchedules();
    }
  });
});