define('juridico-sengerj/components/elements-table', ['exports', 'ember', 'juridico-sengerj/mixins/components/table-fixed-headers'], function (exports, _ember, _juridicoSengerjMixinsComponentsTableFixedHeaders) {
  exports['default'] = _ember['default'].Component.extend(_juridicoSengerjMixinsComponentsTableFixedHeaders['default'], {
    columnsTitle: [],
    newRecords: [],
    hasShowMoreButton: true,
    records: null,
    hasActions: false,
    _modelName: null,
    _queryParams: {},
    _listLoading: false,
    _loadingNextPage: false,
    _totalPages: 1,
    _totalRecords: 0,
    _page: 1,
    _perPage: 0,
    _records: [],
    store: _ember['default'].inject.service(),

    actions: {
      showMore: function showMore() {
        this.toggleProperty("_loadingNextPage");
        this._nextPageRecords();
      }
    },

    visibleShowMoreButton: _ember['default'].computed('_page', '_totalPages', function () {
      return this._page < this._totalPages;
    }),

    recordsToShow: _ember['default'].computed('newRecords.[]', '_records.[]', function () {
      var _getProperties = this.getProperties('newRecords', '_records');

      var newRecords = _getProperties.newRecords;
      var _records = _getProperties._records;

      return newRecords.concat(_records);
    }),

    remaningRecordsToShow: _ember['default'].computed('recordsToShow', '_totalRecords', function () {
      var count = this.get("_totalRecords") - this.get("recordsToShow").length;

      if (count > 0) {
        return '(+ ' + count + ')';
      }
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      var records = this.records;

      if (records) {
        if (records.then) {
          this.set("_listLoading", true);

          records.then(function (_records) {
            _this._setTablePropertiesFromPromise(_records);
            _this.set("_listLoading", false);
          });
        } else {
          this._setTablePropertiesFromPromise(records);
          this.set("_listLoading", false);
        }
      } else {
        this.set("_listLoading", false);
      }
    },

    _setTablePropertiesFromPromise: function _setTablePropertiesFromPromise(records) {
      this.setProperties({
        _modelName: records.get("firstObject._internalModel.modelName"),
        _queryParams: records.query || {},
        _records: records.toArray(),
        _perPage: records.meta && records.meta['per-page'] || 0,
        _page: records.meta && records.meta['current-page'] || 0,
        _totalPages: records.meta && records.meta['total-pages'] || 0,
        _totalRecords: records.meta && records.meta['total-count'] || 0
      });
    },

    _queryParamsWithPage: function _queryParamsWithPage() {
      return _ember['default'].assign(this._queryParams, { page: this._page + 1 });
    },

    _makeQuery: function _makeQuery() {
      return this.get("store").query(this._modelName, this._queryParamsWithPage());
    },

    _nextPageRecords: function _nextPageRecords() {
      var _this2 = this;

      this._makeQuery().then(function (records) {
        _this2.toggleProperty("_loadingNextPage");
        _this2.incrementProperty("_page");
        _this2.get('_records').pushObjects(records.toArray());
      });
    }
  });
});