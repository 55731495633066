define('juridico-sengerj/components/select-model', ['exports', 'ember', 'juridico-sengerj/templates/components/select-model', 'juridico-sengerj/utils/pages-loader'], function (exports, _ember, _juridicoSengerjTemplatesComponentsSelectModel, _juridicoSengerjUtilsPagesLoader) {
  exports['default'] = _ember['default'].Component.extend({
    layout: _juridicoSengerjTemplatesComponentsSelectModel['default'],
    modelName: '',
    label: 'label',
    disabled: false,
    valueField: 'id',
    placeholder: '',
    store: _ember['default'].inject.service(),
    include: '',
    query: {},
    "on-change": function onChange() {},

    actions: {
      selectRecord: function selectRecord(record) {
        if (_ember['default'].isPresent(record)) {
          this.setSelected(record);
        } else {
          this.clearSelect();
        }
      }
    },

    setSelected: function setSelected(value) {
      _ember['default'].get(this, "on-change")(value);
      this.set("value", value);
    },

    clearSelect: function clearSelect() {
      _ember['default'].get(this, "on-change")('');
      this.set("value", '');
    },

    records: _ember['default'].computed("modelName", "include", "query", function () {
      var query = Object.assign({ include: this.include }, this.query);

      return (0, _juridicoSengerjUtilsPagesLoader.loadPages)(this.get("store").query(this.modelName, query)).then(function (_ref) {
        var records = _ref.records;
        return records;
      });
    })
  });
});