define("juridico-sengerj/components/emails-tags", ["exports", "ember"], function (exports, _ember) {
  var $ = _ember["default"].$;
  exports["default"] = _ember["default"].Component.extend({
    emails: [],
    attrValidations: _ember["default"].Object.create(),

    actions: {
      addEmail: function addEmail(email) {
        if (this._emailIsValid(email)) {
          var emailable = this.get("emails.content.record").getRecord();

          var emailRecord = this.get("emails").createRecord({
            email: email,
            emailable: emailable
          });

          if (!emailable.get("isNew")) {
            emailRecord.save();
          }
        } else {
          this._showInvalidEmailError(email);
        }
      },

      removeEmail: function removeEmail(email) {
        return this.get("emails").findBy('email', email).destroyRecord();
      },

      onFocusOut: function onFocusOut() {
        this.set("showValidation", true);
      }
    },

    _emailIsValid: function _emailIsValid(email) {
      var emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      return emailRegex.test(email);
    },

    _showInvalidEmailError: function _showInvalidEmailError(email) {
      $.niftyNoty({
        type: 'danger',
        icon: 'fa fa-exclamation-triangle fa-2x',
        container: "floating",
        title: "E-mail " + email + " é inválido",
        timer: 3000
      });
    }
  });
});