define('juridico-sengerj/instance-initializers/session', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(appInstance) {
    appInstance.inject('controller', 'session', 'service:session');
    appInstance.inject('component', 'session', 'service:session');
    appInstance.inject('route', 'session', 'service:session');
  }

  exports['default'] = {
    name: 'session',
    initialize: initialize
  };
});