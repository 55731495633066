define("juridico-sengerj/utils/exibir-reclamantes-computed", ["exports", "ember"], function (exports, _ember) {
	exports["default"] = exibirReclamantesComputed;

	function exibirReclamantesComputed() {
		return _ember["default"].computed("reclamantes.isLoaded", function () {
			var reclamantes = this.get("reclamantes.content");

			if (!reclamantes.get("isLoaded")) {
				return "";
			}

			if (reclamantes.length === 1) {
				return reclamantes.get("firstObject.nome");
			} else if (reclamantes.length <= 8) {
				return reclamantes.mapBy("nome").join(", ");
			} else {
				return "Muitos Reclamantes";
			}
		});
	}
});