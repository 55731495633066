define('juridico-sengerj/models/juridico/info-financeiro', ['exports', 'juridico-sengerj/models/abstract-model', 'ember-data', 'ember-cp-validations'], function (exports, _juridicoSengerjModelsAbstractModel, _emberData, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    documentoUrl: (0, _emberCpValidations.validator)('presence', true),

    pessoa: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed('model.formaPagamento', function () {
        return this.get("model.formaPagamento.value") !== "deposito_reclamante";
      })
    })],

    infoFinanceiroVinculado: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed('model.ocorrencia.tipoOcorrencia', function () {
        return this.get("model.ocorrencia.tipoOcorrencia.descricao") !== "Recebimento de Honorário de êxito";
      })
    })],

    formaPagamento: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed('model.ocorrencia.tipoOcorrencia', function () {
        return this.get("model.ocorrencia.tipoOcorrencia.descricao") === "Recebimento de Honorário de êxito";
      })
    })],

    // contratoEscritorioContabil: [
    //   validator('presence', {
    //     presence: true,
    //     disabled: Ember.computed('model.ocorrencia.tipoOcorrencia', function() {
    //       return (this.get("model.ocorrencia.tipoOcorrencia.descricao") === "Recebimento de Honorário de êxito" || this.get("model.formaPagamento.value") !== "deposito_senge")
    //     })
    //   })
    // ],

    contratoEscritorio: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed('model.ocorrencia.tipoOcorrencia', function () {
        return this.get("model.ocorrencia.tipoOcorrencia.descricao") === "Recebimento de Honorário de êxito";
      })
    })],

    bancoPagador: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed('model.ocorrencia.tipoOcorrencia', function () {
        return this.get("model.ocorrencia.tipoOcorrencia.descricao") === "Recebimento de Honorário de êxito";
      })
    })],

    numero: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed('model.ocorrencia.tipoOcorrencia', function () {
        return this.get("model.ocorrencia.tipoOcorrencia.descricao") === "Recebimento de Honorário de êxito";
      })
    })],

    tipo: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed('model.ocorrencia.tipoOcorrencia', function () {
        return this.get("model.ocorrencia.tipoOcorrencia.descricao") === "Recebimento de Honorário de êxito";
      })
    })],

    comprovanteDigitalizadoUrl: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed('model.formaPagamento', function () {
        return this.get("model.formaPagamento.value") !== "deposito_reclamante";
      })
    })],

    valorLevantado: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), // disabled: Ember.computed('model.formaPagamento', function() {
    //   return this.get("model.formaPagamento.value") !== "deposito_reclamante"
    // })
    (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gte: Ember.computed('model.ocorrencia.valor', function () {
        return this.get("model.ocorrencia.valor");
      })
    })],

    // disabled: Ember.computed('model.formaPagamento', function() {
    //   return this.get("model.formaPagamento.value") !== "deposito_reclamante"
    // })
    dataLevantamento: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), // disabled: Ember.computed('model.formaPagamento', function() {
    //   return this.get("model.formaPagamento.value") !== "deposito_reclamante"
    // })
    (0, _emberCpValidations.validator)('date', {
      after: Ember.computed('model.ocorrencia.dataOcorrencia', function () {
        return this.get("model.ocorrencia.dataOcorrencia");
      }),
      onOrBefore: 'now',
      precision: 'day',
      format: 'D/M/YYYY',
      errorFormat: 'D/M/YYYY',
      disabled: Ember.computed('model.formaPagamento', function () {
        return this.get("model.formaPagamento.value") !== "deposito_reclamante";
      })
    })],

    bancoRecebedor: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })]

  });

  // disabled: Ember.computed('model.formaPagamento', function() {
  //   return this.get("model.formaPagamento.value") !== "deposito_reclamante"
  // })
  exports['default'] = _juridicoSengerjModelsAbstractModel['default'].extend(Validations, {
    dataLevantamento: _emberData['default'].attr('date'),
    valorLevantado: _emberData['default'].attr("number"),
    valorLevantadoString: _emberData['default'].attr("string"),
    saldoPraPagar: _emberData['default'].attr("string"),
    numero: _emberData['default'].attr("string"),
    tipo: _emberData['default'].attr("enum"),
    formaPagamento: _emberData['default'].attr('enum'),
    informacaoCompleta: _emberData['default'].attr('string'),
    infoVinculadoDataLevantamento: _emberData['default'].attr('date'),
    infoVinculadoValorLevantado: _emberData['default'].attr('number'),
    valorDevidoReclamante: _emberData['default'].attr('number'),
    alvarasIndividuais: _emberData['default'].attr('boolean'),
    calculoAtualizaValores: _emberData['default'].attr('boolean'),
    efeitoOficio: _emberData['default'].attr('boolean'),

    comprovanteDigitalizadoUrl: _emberData['default'].attr("string"),
    documentoUrl: _emberData['default'].attr("string"),

    bancoPagador: _emberData['default'].belongsTo('juridico/banco-pagador'),
    bancoRecebedor: _emberData['default'].belongsTo('financeiro/conta-bancaria'),
    contratoEscritorio: _emberData['default'].belongsTo('juridico/contrato-escritorio'),
    contratoEscritorioContabil: _emberData['default'].belongsTo('juridico/contratoEscritorio'),
    pessoa: _emberData['default'].belongsTo('pessoa'),

    ocorrencia: _emberData['default'].belongsTo('juridico/ocorrencia'),
    planilhaPagamento: _emberData['default'].belongsTo('juridico/planilha-pagamento'),
    infoFinanceiroVinculado: _emberData['default'].belongsTo('juridico/info-financeiro')
  });
});