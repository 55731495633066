define("juridico-sengerj/routes/dashboard/banco-pagadores/novo", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Route.extend({
    model: function model() {
      var bancoPagador = this.store.createRecord("juridico/banco-pagador");

      return this.store.createRecord("juridico/banco-pagador", { bancoPagador: bancoPagador });
    },

    actions: {
      newBancoPagadorCreated: function newBancoPagadorCreated() {
        _ember["default"].$.niftyNoty({
          type: 'success',
          icon: 'fa fa-exclamation-triangle fa-2x',
          container: "floating",
          title: 'O Banco foi adicionado.',
          timer: 3000
        });

        this.get('router').transitionTo('dashboard.banco-pagadores.index');
      }
    }
  });
});