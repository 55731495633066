define('juridico-sengerj/components/filters/filter-date', ['exports', 'juridico-sengerj/mixins/components/filter', 'ember', 'juridico-sengerj/components/date-picker', 'juridico-sengerj/helpers/format-time'], function (exports, _juridicoSengerjMixinsComponentsFilter, _ember, _juridicoSengerjComponentsDatePicker, _juridicoSengerjHelpersFormatTime) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var isPresent = _ember['default'].isPresent;
  var computed = _ember['default'].computed;
  var isBlank = _ember['default'].isBlank;
  exports['default'] = _juridicoSengerjComponentsDatePicker['default'].extend(_juridicoSengerjMixinsComponentsFilter['default'], {
    actions: {
      onChangeDate: function onChangeDate() {
        var filterValue = this.$("input")[0].value;
        var filterWithoutMask = filterValue.replace(/\D/g, '');

        if (filterWithoutMask.length === 8) {
          this.saveFilterValue(filterValue);
        }
      }
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.$().off("keypress", "keyup");
    },

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      this.$("input").keyup(function (_ref) {
        var target = _ref.target;

        var value = target.value.replace(/\D/g, "");

        if (isBlank(value)) {
          _this.clearFilterValue();
        }
      });
    },

    valueToShow: computed("value", function () {
      if (this.get("value")) {
        return (0, _juridicoSengerjHelpersFormatTime.formatTime)([this.get("value")]);
      }
    }),

    value: computed("filterValue", function () {
      var filterValue = this.get("filterValue");

      if (isPresent(filterValue)) {
        var _filterValue$split = filterValue.split("/");

        var _filterValue$split2 = _slicedToArray(_filterValue$split, 3);

        var day = _filterValue$split2[0];
        var month = _filterValue$split2[1];
        var year = _filterValue$split2[2];

        return new Date(year, month - 1, day);
      }
    })
  });
});