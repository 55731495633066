define('juridico-sengerj/routes/dashboard/atendimentos/advogados/novo', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(_ref) {
      var agendamento_id = _ref.agendamento_id;

      var store = this.store;
      var agendamento = store.findRecord('juridico/advogado/agendamento', agendamento_id);

      return agendamento.then(function (agendamento) {
        return store.createRecord('juridico/advogado/atendimento', { agendamento: agendamento });
      });
    }
  });
});