define('juridico-sengerj/components/dashboard-menu', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var isPresent = _ember['default'].isPresent;
  var get = _ember['default'].get;
  var run = _ember['default'].run;

  var DashBoardMenu = _ember['default'].Component.extend({
    icon: null,
    title: "",
    children: [],
    menuParent: null,

    isAnyChildrenActive: computed.gt('activeChildren.length', 0),

    activeChildren: computed.filterBy("children", "isActive", true),

    isActive: computed('isAnyChildrenActive', 'router.currentRouteName', function () {
      if (isPresent(this.params)) {
        return this.get('router.currentRouteName') === this.params[0];
      } else {
        return this.get("isAnyChildrenActive");
      }
    }),

    init: function init() {
      this._super.apply(this, arguments);
      run.schedule("afterRender", this, function () {
        this.set("children", _ember['default'].A());
        if (this.parent) {
          this.get('parent.children').addObject(this);
        }
      });
    }
  });

  DashBoardMenu.reopenClass({
    positionalParams: 'params'
  });

  exports['default'] = DashBoardMenu;
});