define('juridico-sengerj/components/modal-export-data', ['exports', 'ember', 'juridico-sengerj/config/environment', 'npm:jspdf', 'npm:jspdf-autotable', 'juridico-sengerj/utils/json-to-csv-parser', 'juridico-sengerj/utils/pages-loader', 'juridico-sengerj/templates/components/modal-export-data', 'juridico-sengerj/utils/simple-pdf-table'], function (exports, _ember, _juridicoSengerjConfigEnvironment, _npmJspdf, _npmJspdfAutotable, _juridicoSengerjUtilsJsonToCsvParser, _juridicoSengerjUtilsPagesLoader, _juridicoSengerjTemplatesComponentsModalExportData, _juridicoSengerjUtilsSimplePdfTable) {
  var isBlank = _ember['default'].isBlank;
  var computed = _ember['default'].computed;

  var TOTAL_PAGES_EXP = "{total_pages_count_string}";

  exports['default'] = _ember['default'].Component.extend({
    layout: _juridicoSengerjTemplatesComponentsModalExportData['default'],
    pdfOverflow: "ellipsize",
    csvDelimiter: ";",
    open: false,
    filters: {},
    include: "",
    title: "",
    emptyValue: "-",
    records: [],
    columns: {},
    longTextColumns: [],
    isConcurrency: false,
    "custom-pdf-header": null,
    classNames: ['hidden'],
    theme: 'striped',
    headerStyle: {},
    showHeader: 'everyPage',
    columnStyles: {},
    marginPdf: { top: 40, bottom: 20, left: 20, right: 20 },
    filtersPdf: null,
    _currentPage: 0,

    _loadedPagesCount: 0,
    _totalPages: 1,
    _totalRecords: 0,

    store: _ember['default'].inject.service(),

    actions: {
      exportCSV: function exportCSV() {
        var _this2 = this;

        this.loadAllRecords().then(function (records) {
          var rows = _this2._getTableData(records);

          var parser = _this2.createCsv(rows);

          parser.saveCSV(_this2.title);
        });
      },

      exportPDF: function exportPDF() {
        var _this3 = this;

        this.loadAllRecords().then(function (records) {
          var doc = new _npmJspdf['default']();

          var rows = _this3._getTableData(records);

          _this3.set("_currentPage", 0);

          var startY = _this3.firstHeaderPdf(doc);

          _this3.createPdfTable(doc, rows, _this3.get("headers"), startY);

          doc.putTotalPages(TOTAL_PAGES_EXP);

          doc.save(_this3.title + '.pdf');
        });
      }
    },

    headers: computed('columns', function () {
      return Object.keys(this.columns);
    }),

    createCsv: function createCsv(rows) {
      var parser = _juridicoSengerjUtilsJsonToCsvParser['default'].create();

      parser.parse({
        headers: this.get("headers"),
        rows: rows,
        delimiter: this.csvDelimiter,
        bottomExtraRows: ["Total Registros", this._totalRecords]
      });

      return parser;
    },

    createPdfTable: function createPdfTable(doc, rows, headers, startY) {
      var _this4 = this;

      var longColumnStyle = this.longTextColumns.reduce(function (style, longColumn) {
        var index = Object.keys(_this4.columns).indexOf(longColumn);
        style[index] = {
          columnWidth: "auto",
          overflow: "linebreak"
        };
        return style;
      }, {});

      doc.autoTable(headers, rows, {
        addPageContent: this._pdfPageContent.bind(doc, this),
        startY: doc.autoTable.previous.finalY || startY,
        margin: this.marginPdf,
        theme: this.theme,
        showHeader: this.showHeader,
        styles: { overflow: this.pdfOverflow, columnWidth: "wrap" },
        headerStyles: Object.assign({ fillColor: [91, 91, 91] }, this.headerStyle),
        columnStyles: Object.assign({}, this.columnStyles, longColumnStyle)
      });
    },

    _pdfPageContent: function _pdfPageContent(_this, _ref) {
      var doc = _ref.doc;
      var settings = _ref.settings;

      var totalPages = doc.internal.getNumberOfPages();
      var _currentPage = _this._currentPage;

      if (_currentPage < totalPages) {
        // HEADER
        doc.addImage(_juridicoSengerjConfigEnvironment['default']['logoDataURL'], 'JPEG', 20, 5, 25, 20);

        _this.headerPDF.bind(_this)(doc, _currentPage);

        // FOOTER
        var str = 'Página ' + (_currentPage + 1) + ' de ' + TOTAL_PAGES_EXP;
        doc.setFontSize(10);
        doc.text(str, settings.margin.left, doc.internal.pageSize.height - 10);
        _this.set("_currentPage", doc.internal.getNumberOfPages());
      }
    },

    firstHeaderPdf: function firstHeaderPdf(doc) {
      var _this5 = this;

      doc.setFontSize(11);
      doc.text(105, 43, 'Total Registros: ' + this._totalRecords, 'center');

      var finalY = 40;

      if (_ember['default'].isPresent(this.filtersPdf)) {
        var _filters = Object.keys(this.filtersPdf).map(function (filterName) {
          return { header: filterName, value: _this5.filtersPdf[filterName] };
        });

        doc.setFontSize(14);
        doc.text(20, 50, 'Filtros Aplicados:');

        finalY = (0, _juridicoSengerjUtilsSimplePdfTable['default'])(doc, { x: 20, y: 55, group: 1 }, [_filters]);

        doc.line(20, finalY + 6, 190, finalY + 6);

        finalY += 6;
      }

      return finalY;
    },

    headerPDF: function headerPDF(doc, currentPage) {
      doc.setDrawColor(169, 169, 169);
      doc.line(20, 25, 190, 25);

      if (_ember['default'].isPresent(this.get('custom-pdf-header'))) {
        _ember['default'].get(this, "custom-pdf-header")(doc, this._totalRecords);
      } else {
        doc.setFontSize(18);

        doc.text(105, 35, this.title, "center");
      }
    },

    loadAllRecords: function loadAllRecords() {
      var _this6 = this;

      var _getProperties = this.getProperties("records", "include", "store");

      var records = _getProperties.records;
      var include = _getProperties.include;
      var store = _getProperties.store;

      var _filters = Object.assign({}, records.query || records.content.query || this.filters, { include: include });

      var _records = store.query(records.get("firstObject._internalModel.modelName"), _filters);

      return _juridicoSengerjUtilsPagesLoader['default'].loadPages(_records).then(function (_ref2) {
        var records = _ref2.records;
        var promises = _ref2.promises;

        _this6.setProperties({
          _totalPages: promises.length,
          _loadedPagesCount: 0
        });

        promises.forEach(function (p) {
          return p.then(function () {
            return _this6.incrementProperty("_loadedPagesCount");
          });
        });

        records.then(function (_ref3) {
          var length = _ref3.length;
          return _this6.set("_totalRecords", length);
        });

        return records;
      });
    },

    _getTableData: function _getTableData(records) {
      var _this7 = this;

      var _getProperties2 = this.getProperties("columns", "headers");

      var columns = _getProperties2.columns;
      var headers = _getProperties2.headers;

      var rows = records.map(function (record) {
        return headers.map(function (header) {
          var column = columns[header];

          var value = null;

          if (typeof column === "function") {
            value = column(record);
          } else {
            value = record.get(column);
          }

          if (isBlank(value)) {
            value = _this7.emptyValue;
          }

          return value.replace(/\t/g, "    ");
        });
      });

      return rows;
    }
  });
});