define("juridico-sengerj/components/toggleable-row-table", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    tagName: "tbody",
    isHidden: true,

    columnsLength: _ember["default"].computed.alias("columns.length"),

    actions: {
      onRowClick: function onRowClick() {
        this.toggleProperty("isHidden");
      }
    }
  });
});