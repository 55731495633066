define('juridico-sengerj/components/list-group', ['exports', 'ember'], function (exports, _ember) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    options: [],
    "options-values": [],
    "selected-options": [],
    "on-change": function onChange() {},

    actions: {
      onChange: function onChange(optionIndex) {
        var newOptions = this.get("selected-options");

        var optionValue = this.get("options-values")[optionIndex];

        var optionPos = newOptions.indexOf(optionValue);

        if (optionPos === -1) {
          newOptions = [].concat(_toConsumableArray(newOptions), [optionValue]);
        } else {
          newOptions = newOptions.filter(function (_option) {
            return _option !== optionValue;
          });
        }

        _ember['default'].get(this, "on-change")(_ember['default'].A(newOptions));
      }
    },

    _selectedOptionsIndex: _ember['default'].computed("selected-options", function () {
      var _this = this;

      return this.get("selected-options").map(function (selectedOption) {
        return _this.get("options-values").indexOf(selectedOption);
      });
    }),

    _optionsIndexs: _ember['default'].computed("options", function () {
      var _this2 = this;

      return this.get("options").map(function (option) {
        return _this2.get("options").indexOf(option);
      });
    }),

    init: function init() {
      this._super.apply(this, arguments);
      if (_ember['default'].isBlank(this.get("options-values"))) {
        this.set("options-values", this.get("options"));
      }
    }
  });
});