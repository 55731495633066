define("juridico-sengerj/components/editable-text-input", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    mask: null,
    value: null,
    valueToShow: null,
    _isVisible: false,
    "on-save": function onSave() {},

    _newValue: _ember["default"].computed.oneWay("value"),

    actions: {
      revertNewValue: function revertNewValue() {
        this.set("_newValue", this.value);
      },

      changeValue: function changeValue() {
        this.set("_isVisible", false);
        this.set("value", this._newValue);
        _ember["default"].get(this, "on-save")();
      }
    }
  });
});