define("juridico-sengerj/components/filters-with-table/pastas", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    store: _ember["default"].inject.service(),
    pastas: [],
    "apply-filters": function applyFilters() {},

    columnsTitle: ["Pasta", "Status", "Advogado", "Número Processo", "Reclamada Principal", "Objeto Principal"],

    columnsPdf: {
      "Pasta": "showCode",
      "Status": "status.text",
      "Processo": "numeroProcesso",
      "Advogado": "advogado.nome",
      "Reclamada": "reclamadaPrincipal",
      "Objeto": "objetosAcaoPrincipal",
      "Reclamantes": "reclamantesCountString",
      "NomeReclamante": "reclamantesExibeNome"
    },

    columnStylesPdf: {
      0: { columnWidth: "wrap" },
      1: { columnWidth: "wrap" },
      2: { columnWidth: "wrap" },
      3: { columnWidth: "auto" },
      4: { columnWidth: "auto" },
      5: { columnWidth: "auto" },
      5: { columnWidth: "auto" }
    },

    includePdf: "objetos_acao,reus.reuzavel, pasta_avulsa.advogado.pessoa, atendimento.agendamento.advogado.pessoa",

    filtersValuesToShow: null,
    pastaModal: null,
    pastaEncerrada: null,
    pastaRestaurada: null,
    encerrarPastaModalVisible: false,
    restaurarPastaModalVisible: false,
    novaOcorrenciaModalVisible: false,

    actions: {
      applyFilters: function applyFilters(filters) {
        _ember["default"].get(this, "apply-filters")(filters);
      },

      openModalEncerrarPasta: function openModalEncerrarPasta(pasta) {
        var pastaEncerrada = this.get("store").createRecord('juridico/pasta-encerrada', { pasta: pasta });

        this.setProperties({ encerrarPastaModalVisible: true, pastaEncerrada: pastaEncerrada, pastaModal: pasta });
      },

      openModalRestaurarPasta: function openModalRestaurarPasta(pasta) {
        var pastaRestaurada = this.get("store").createRecord('juridico/restaurar-pasta', { pasta: pasta });

        this.setProperties({ restaurarPastaModalVisible: true, pastaRestaurada: pastaRestaurada, pastaModal: pasta });
      },

      openModalAddOcorrencia: function openModalAddOcorrencia(pasta) {
        this.setProperties({
          novaOcorrenciaModalVisible: true,
          pastaModal: pasta
        });
      },

      encerrarPasta: function encerrarPasta() {
        var _this = this;

        this.pastaEncerrada.save().then(function () {
          _this.set("encerrarPastaModalVisible", false);
          _this.pastaModal.reload();

          _ember["default"].$.niftyNoty({
            type: 'success',
            icon: 'fa fa-exclamation-triangle fa-2x',
            container: "floating",
            title: 'A pasta foi encerrada com sucesso.',
            timer: 3000
          });
        });
      },

      restaurarPasta: function restaurarPasta() {
        var _this2 = this;

        this.pastaRestaurada.save().then(function () {
          _this2.set("restaurarPastaModalVisible", false);
          _this2.pastaModal.reload();

          _ember["default"].$.niftyNoty({
            type: 'success',
            icon: 'fa fa-exclamation-triangle fa-2x',
            container: "floating",
            title: 'A pasta foi restaurada com sucesso.',
            timer: 3000
          });
        });
      }
    },

    encerrarModalTitle: _ember["default"].computed("pastaModal", function () {
      return "Encerrar Pasta - " + this.get("pastaModal.showCode");
    }),

    restaurarModalTitle: _ember["default"].computed("pastaModal", function () {
      return "Restaurar Pasta - " + this.get("pastaModal.showCode");
    }),

    init: function init() {
      this._super.apply(this, arguments);

      var queryParams = this.get("pastas.query") || [];
      var showAdvancedFilters = Object.keys(queryParams).filter(function (f) {
        return _ember["default"].isPresent(queryParams[f]) && !["porCodigo", "page", "include"].includes(f);
      }).length > 0;

      this.set("showAdvancedFilters", showAdvancedFilters);
    }
  });
});