define('juridico-sengerj/models/custom-inflector-rules', ['exports', 'ember-inflector'], function (exports, _emberInflector) {

  var inflector = _emberInflector['default'].inflector;

  inflector.irregular('pasta', 'pastas');
  inflector.irregular('ocorrencia', 'ocorrencias');
  inflector.irregular('enum/usuario/permissao', 'enum/usuario/permissoes');
  inflector.irregular('profissional', 'profissionais');
  inflector.irregular('objeto-acao', 'objeto-acoes');
  inflector.irregular('nota', 'notas');
  inflector.irregular('contrato-assinado', 'contratos-assinados');
  inflector.irregular('documento-necessario', 'documentos-necessarios');
  inflector.irregular('agendamento-ocupado', 'agendamentos-ocupados');
  inflector.irregular('usuario-empresa', 'usuarios-empresas');
  inflector.irregular('orgao-justica', 'orgaos-justica');
  inflector.irregular('banco-pagador', 'banco-pagadores');
  inflector.irregular('beneficiario-pessoa-juridica', 'beneficiario-pessoa-juridicas');
  inflector.irregular('contrato-escritorio', 'contrato-escritorios');
  inflector.irregular('data-sem-homologacao', 'data-sem-homologacoes');
  inflector.irregular('juridico/reclamante-autorizacao', 'juridico/reclamante-autorizacoes');
  inflector.irregular('juridico/valor-por-demanda', 'juridico/valor-por-demandas');
  inflector.irregular('financeiro/conta-bancaria', 'financeiro/conta-bancarias');

  exports['default'] = {};
});