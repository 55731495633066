define("juridico-sengerj/components/modal-reclamantes-massa", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    store: _ember["default"].inject.service(),
    isAdicionar: true,
    pastaAvulsa: null,
    isVisible: false,
    _isSaving: false,
    _filters: {},

    _columns: ["Nome", "CPF", "Matrícula"],

    actions: {
      applyFilters: function applyFilters(filter) {
        this.set("_filters", filter);
      },

      saveReclamanteEmMassa: function saveReclamanteEmMassa() {
        var store, pastaAvulsa, tipo, filtros;
        return regeneratorRuntime.async(function saveReclamanteEmMassa$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              store = this.get("store");
              context$1$0.next = 3;
              return regeneratorRuntime.awrap(this.get("pastaAvulsa"));

            case 3:
              pastaAvulsa = context$1$0.sent;
              tipo = this.isAdicionar ? "adicionar" : "remover";
              filtros = this._filters;
              context$1$0.next = 8;
              return regeneratorRuntime.awrap(store.createRecord("juridico/reclamante-em-massa", { filtros: filtros, pastaAvulsa: pastaAvulsa, tipo: { value: tipo } }).save());

            case 8:

              this._showNotification();

            case 9:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      }

    },

    _showNotification: function _showNotification() {
      _ember["default"].$.niftyNoty({
        type: 'success',
        icon: 'fa fa-thumbs-up fa-2x',
        container: 'floating',
        title: this.isAdicionar ? "Adicionando Reclamantes" : "Removendo Reclamantes",
        timer: 4000
      });
    },

    _profissionais: _ember["default"].computed("_filters", "isVisible", function () {
      if (this.isVisible) {
        return this.get("store").query("profissional", Object.assign({}, this._filters, { include: 'pessoa,socio' }));
      } else {
        return [];
      }
    })
  });
});