define('juridico-sengerj/components/autocomplete-field', ['exports', 'ember', 'juridico-sengerj/templates/components/autocomplete-field'], function (exports, _ember, _juridicoSengerjTemplatesComponentsAutocompleteField) {
  var isBlank = _ember['default'].isBlank;
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var isPresent = _ember['default'].isPresent;
  var get = _ember['default'].get;
  var $ = _ember['default'].$;
  exports['default'] = Component.extend({
    layout: _juridicoSengerjTemplatesComponentsAutocompleteField['default'],
    placeholder: null,
    disabled: false,
    dropDownVisible: false,
    results: [],
    label: 'label',
    valueField: 'id',
    searchValue: '',
    autoSelectFirstOne: true,
    top: 0,
    left: 0,
    inputDelay: 600,
    "on-clean": function onClean() {},
    "key-up": function keyUp() {},
    selectedResult: function selectedResult() {},
    search: function search() {
      return new Promise(function () {});
    },
    _currentSearchValue: '',
    _inputTimer: null,

    actions: {
      search: function search(query) {
        var _this = this;

        get(this, "key-up")(query);

        if (isBlank(query)) {
          this.setResults([]);
          _ember['default'].get(this, "on-clean")();
        } else if (this._currentSearchValue !== query) {
          _ember['default'].run.cancel(this._inputTimer);
          this._updateSearchField(query);
          this.set('_inputTimer', _ember['default'].run.later(function () {
            _this._search(query);
          }, this.inputDelay));
        }
      },

      selectResult: function selectResult(select) {
        this._selectResult(select);
      },

      hideDropDown: function hideDropDown() {
        this._hideDropDown();
      }
    },

    position: computed("top", "left", function () {
      return 'top:' + (this.top + this.$().height()) + 'px;left:' + this.left + 'px';
    }),

    setResults: function setResults(results) {
      this.set("results", results);
    },
    _hideDropDown: function _hideDropDown() {
      this.set("dropDownVisible", false);
    },

    _searchValue: computed.oneWay("searchValue"),

    _hasResults: computed.notEmpty("results"),

    _selectResult: function _selectResult(select) {
      get(this, "selectedResult")(select);
      this._updateSearchField(select.get(this.label));
      this._hideDropDown();
    },

    _showDropDown: function _showDropDown() {
      var $element = this.$();
      var parentLeftPos = $element.offset().left + parseInt($element.css("padding-left").replace("px", ""), 10);
      var parentTopPos = $element.offset().top;

      this.setProperties({
        top: parentTopPos,
        left: parentLeftPos,
        dropDownVisible: true
      });
    },

    _search: function _search(query) {
      var _this2 = this;

      this._showDropDown();
      this.set("_isLoading", true);
      get(this, "search")(query).then(function (results) {

        if (_this2.autoSelectFirstOne && results.get("length") === 1) {
          _this2._selectResult(results.get("firstObject"));
        } else {
          _this2.setResults(results);
        }

        _ember['default'].run.cancel(_this2._inputTimer);
        _this2.set("_isLoading", false);
      });
    },

    _updateSearchField: function _updateSearchField(value) {
      this.$("input").val(value);
      this.set("_currentSearchValue", value);
    },

    _hideWhenClickOutside: function _hideWhenClickOutside(_ref) {
      var target = _ref.target;

      if (this.get("dropDownVisible") && target && this.$("input").attr("id") != target.id) {
        this._hideDropDown();
      }
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      $("body").click(this.get("_hideWhenClickOutsideHandler"));
    },

    didDestroyElement: function didDestroyElement() {
      this._super.apply(this, arguments);
      $("body").off("click", this.get("_hideWhenClickOutsideHandler"));
    },

    init: function init() {
      this._super.apply(this, arguments);
      this.set("_hideWhenClickOutsideHandler", _ember['default'].run.bind(this, this._hideWhenClickOutside));
    }
  });
});