define('juridico-sengerj/models/unidade', ['exports', 'juridico-sengerj/models/abstract-model', 'ember-data', 'ember-cp-validations'], function (exports, _juridicoSengerjModelsAbstractModel, _emberData, _emberCpValidations) {

	var Validations = (0, _emberCpValidations.buildValidations)({
		cnpj: [(0, _emberCpValidations.validator)('presence', true)]
	});

	exports['default'] = _juridicoSengerjModelsAbstractModel['default'].extend(Validations, {
		nome: _emberData['default'].attr('string'),
		nomeFantasia: _emberData['default'].attr('string', { readOnly: true }),
		cnpj: _emberData['default'].attr('cnpj'),
		empresa: _emberData['default'].belongsTo(),

		informacaoCompleta: Ember.computed("empresa.razaoSocial", "nome", function () {
			return this.get("empresa.razaoSocial") + ' (Unidade: ' + this.get("nome") + ')';
		})
	});
});