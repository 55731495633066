define('juridico-sengerj/components/filters/filter-text-field', ['exports', 'ember', 'juridico-sengerj/mixins/components/filter'], function (exports, _ember, _juridicoSengerjMixinsComponentsFilter) {
  exports['default'] = _ember['default'].Component.extend(_juridicoSengerjMixinsComponentsFilter['default'], {
    placeholder: null,
    mask: null,
    inputDelay: 600,
    _inputTimer: null,

    actions: {
      changeFilterValue: function changeFilterValue() {
        var _this = this;

        _ember['default'].run.cancel(this._inputTimer);
        this.set('_inputTimer', _ember['default'].run.later(function () {
          return _this.fieldUpdatedOnTime();
        }, this.inputDelay));
      }
    },

    valueToShow: _ember['default'].computed.alias("_filterValue"),

    _filterValue: _ember['default'].computed.reads("filterValue"),

    fieldUpdatedOnTime: function fieldUpdatedOnTime() {
      this.saveFilterValue(this.$("input").val());
    }
  });
});