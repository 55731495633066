define("juridico-sengerj/mixins/components/table-fixed-headers", ["exports", "ember"], function (exports, _ember) {
  var run = _ember["default"].run;
  var $ = _ember["default"].$;

  var SCROLL_TIMEOUT = 95;

  exports["default"] = _ember["default"].Mixin.create({
    _scrollTimeout: null,
    _$fixedTable: null,
    _$table: null,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.setProperties({
        _$table: this.$("table.main-table"),
        _$fixedTable: this.$("table.header-fixed")
      });

      $(window).on("scroll", run.bind(this, this._onTableScroll));
      $(window).on("resize", run.bind(this, this._updateColumnHeaderSize));
    },

    _onTableScroll: function _onTableScroll() {
      run.cancel(this._scrollTimeout);

      var topTableOffset = this._$table.offset().top;
      var bottomTableOffset = this._$table.innerHeight() + topTableOffset;
      var startPosY = this._$table.position().top;
      var offset = $(window).scrollTop();
      var $fixedTable = this._$fixedTable;

      this.set("_scrollTimeout", run.later(function () {
        if (offset >= bottomTableOffset) {
          $fixedTable.velocity("fadeOut", { duration: 200 });
        } else if (offset >= topTableOffset) {
          $fixedTable.velocity({
            top: startPosY + (offset - topTableOffset)
          }, {
            begin: function begin() {
              $fixedTable.show();
            }
          });
        } else if (offset < topTableOffset) {
          $fixedTable.velocity("fadeOut", {
            duration: 200,
            complete: function complete() {
              $fixedTable.css({ top: startPosY, opacity: 1, display: "none" });
            }
          });
        }
      }, SCROLL_TIMEOUT));
    },

    _updateColumnHeaderSize: function _updateColumnHeaderSize() {
      run.later(this, function () {
        var _this = this;

        if (this.$()) {
          (function () {
            var firstThs = _this.$("table thead th:visible");
            var fixedTable = _this.$("table.header-fixed");
            var headerFixedThs = fixedTable.find("th");

            var widths = firstThs.map(function (i) {
              return $(firstThs[i]).width();
            });

            fixedTable.css({ width: _this.$("table thead").width() });

            headerFixedThs.each(function (index) {
              $(headerFixedThs[index]).width(widths[index]);
            });
          })();
        }
      });
    },

    didUpdate: function didUpdate() {
      this._super.apply(this, arguments);
      this._updateColumnHeaderSize();
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      $(window).off("scroll");
      $(window).off("resize");
    }
  });
});