define('juridico-sengerj/services/-gestures', ['exports', 'ember', 'juridico-sengerj/config/environment', 'ember-gestures/services/-gestures'], function (exports, _ember, _juridicoSengerjConfigEnvironment, _emberGesturesServicesGestures) {

  var merge = _ember['default'].assign || _ember['default'].merge;

  var gestures = merge({}, {
    useCapture: false
  });
  gestures = merge(gestures, _juridicoSengerjConfigEnvironment['default'].gestures);

  exports['default'] = _emberGesturesServicesGestures['default'].extend({
    useCapture: gestures.useCapture
  });
});