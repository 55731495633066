define("juridico-sengerj/utils/has-one-polymorphic", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = hasOnePolymorphic;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function hasOnePolymorphic(polymorphicName) {
    var modelNamesToDataBasePolymorphic = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

    var getModelNameFromDataBasePolymorphic = function getModelNameFromDataBasePolymorphic(dataBasePolymorphic) {
      var modelName = Object.keys(modelNamesToDataBasePolymorphic).find(function (modelName) {
        return modelNamesToDataBasePolymorphic[modelName] === dataBasePolymorphic;
      });

      return modelName || dataBasePolymorphic.dasherize();
    };

    var getDataBasePolymorphicFromModelName = function getDataBasePolymorphicFromModelName(modelName) {
      return modelNamesToDataBasePolymorphic[modelName] || modelName.classify().replace(/\//g, "::");
    };

    var typeColumn = polymorphicName + "Type";
    var idColumn = polymorphicName + "Id";

    return _ember["default"].computed(idColumn, {
      get: function get(key) {
        var type = this.get(typeColumn);
        var id = this.get(idColumn);

        if (type && id) {
          var modelName = getModelNameFromDataBasePolymorphic(type);
          var store = this.get("store");
          return store.peekRecord(modelName, id) || store.findRecord(modelName, id);
        }
      },
      set: function set(key, record) {
        var _setProperties;

        if (record.then) {
          record = record.content;
        }

        this.setProperties((_setProperties = {}, _defineProperty(_setProperties, idColumn, record.get("id")), _defineProperty(_setProperties, typeColumn, getDataBasePolymorphicFromModelName(record._internalModel.modelName)), _setProperties));

        return record;
      }
    });
  }
});