define('juridico-sengerj/components/bs-form-element-checkbox', ['exports', 'ember-bootstrap/components/bs-form-element'], function (exports, _emberBootstrapComponentsBsFormElement) {
  exports['default'] = _emberBootstrapComponentsBsFormElement['default'].extend({
    property: null,

    actions: {
      onChangeCheckBox: function onChangeCheckBox() {
        this.toggleProperty('model.' + this.property);
      }
    }
  });
});