define('juridico-sengerj/routes/dashboard/escritorios/index', ['exports', 'ember', 'juridico-sengerj/mixins/routes/table-listing-with-filters'], function (exports, _ember, _juridicoSengerjMixinsRoutesTableListingWithFilters) {
  exports['default'] = _ember['default'].Route.extend(_juridicoSengerjMixinsRoutesTableListingWithFilters['default'], {
    model: function model(params) {
      return this.get("store").query("juridico/escritorio", this.filtersParams({ params: params }));
    }
    // ,
    //
    // actions: {
    //   saveEmpresa(empresa) {
    //     empresa.then((empresa) => empresa.save());
    //   }
    // }
  });
});