define('juridico-sengerj/models/entidade', ['exports', 'juridico-sengerj/models/abstract-model', 'ember-data'], function (exports, _juridicoSengerjModelsAbstractModel, _emberData) {
	exports['default'] = _juridicoSengerjModelsAbstractModel['default'].extend({
		nome: _emberData['default'].attr('string'),
		sigla: _emberData['default'].attr('string'),
		recebeJornal: _emberData['default'].attr('boolean'),
		createdAt: _emberData['default'].attr(),
		status: _emberData['default'].attr(),
		tipoEntidade: _emberData['default'].belongsTo(),
		grupoEntidade: _emberData['default'].belongsTo(),
		setorEmpresa: _emberData['default'].belongsTo()
	});
});