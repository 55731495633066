define('juridico-sengerj/controllers/login/novo-usuario', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var isBlank = _ember['default'].isBlank;
  var isEmpty = _ember['default'].isEmpty;
  var isPresent = _ember['default'].isPresent;
  exports['default'] = _ember['default'].Controller.extend({
    usuario: null,
    cpf: '',
    selectedEmail: '',

    actions: {
      selectEmail: function selectEmail(email) {
        var _this = this;

        var _getProperties = this.getProperties("pessoa", "store");

        var pessoa = _getProperties.pessoa;
        var store = _getProperties.store;

        this.setProperties({
          selectedEmail: email,
          usuario: store.createRecord("usuario", { pessoa: pessoa, email: email, novoSistema: true })
        });

        this.get("usuario").save().then(function () {
          _this.get("router").transitionTo("login");
          _this._showSuccessMessage();
        })['catch'](function () {
          _this._showErrorMessage("Tente utilizar outro e-mail para se registrar, se não tiver por favor entre em contato com o setor de cadastro do SENGE");
        });
      }
    },

    noneEmails: computed.empty("pessoa.emails"),

    searchingEmails: computed.alias("pessoas.isPending"),

    isCPFValid: computed("cpf", function () {
      return this.get("cpf").replace(/\D/g, "").length === 11;
    }),

    pessoa: computed("pessoas.isFulfilled", function () {
      return this.get("pessoas.firstObject");
    }),

    pessoas: computed("isCPFValid", function () {
      var _getProperties2 = this.getProperties("isCPFValid", "cpf");

      var isCPFValid = _getProperties2.isCPFValid;
      var cpf = _getProperties2.cpf;

      if (isCPFValid) {
        return this.get("store").query("pessoa", { porCpf: cpf, include: "emails" });
      }
    }),

    isValidToCreateUsuario: computed("pessoa", "isCPFValid", function () {
      var _getProperties3 = this.getProperties("pessoa", "isCPFValid");

      var pessoa = _getProperties3.pessoa;
      var isCPFValid = _getProperties3.isCPFValid;

      if (pessoa && isCPFValid) {
        if (isPresent(pessoa.belongsTo("usuario").id())) {
          this.get("router").transitionTo("login");
          this._showErrorMessage('Este CPF já tem um usuário, se você esqueceu a senha aperte em "Esqueceu a senha?"');
          return false;
        } else if (isEmpty(pessoa.emails)) {
          this._showErrorMessage('Existe um profissional com esse CPF no sistema, porém não há nenhum e-mail cadastrado para o mesmo. Por favor, entre em contato com o setor de cadastro do SENGE-RJ.');
          return false;
        }

        return true;
      } else if (isCPFValid && isBlank(pessoa) && this.get("pessoas.isFulfilled")) {
        this._showErrorMessage('Não existe nenhum profissional em nossa base de dados com este CPF. Por favor, entre em contato com o setor de cadastro do SENGE.');
        return false;
      }

      return false;
    }),

    _showSuccessMessage: function _showSuccessMessage() {
      _ember['default'].$.niftyNoty({
        type: 'success',
        icon: 'fa fa-exclamation-triangle fa-2x',
        container: 'div.cls-header',
        title: "Usuário foi criado no sistema, agora é necessário confirmar o E-mail",
        message: 'Um e-mail de confirmação foi enviado para ' + this.get("usuario.email") + ', dentro deste e-mail terá um link chamado "Criar/alterar minha senha" para confirmar o e-mail é necessário clicar no mesmo',
        timer: 15000
      });
    },

    _showErrorMessage: function _showErrorMessage(message) {
      _ember['default'].$.niftyNoty({
        type: 'danger',
        icon: 'fa fa-thumbs-o-down fa-2x',
        container: 'div.cls-header',
        title: "Não foi possível registrar seu Usuário",
        message: message,
        timer: 15000
      });
    }
  });
});