define('juridico-sengerj/components/filters/filter-checkbox', ['exports', 'ember', 'juridico-sengerj/mixins/components/filter'], function (exports, _ember, _juridicoSengerjMixinsComponentsFilter) {
  var isPresent = _ember['default'].isPresent;
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend(_juridicoSengerjMixinsComponentsFilter['default'], {
    labelText: "",
    valueToSend: "true",

    actions: {
      onChangeCheckBox: function onChangeCheckBox() {
        var filterValue = this.get("filterValue");

        if (filterValue) {
          this.clearFilterValue();
        } else {
          this.saveFilterValue(this.valueToSend);
        }
      }
    },

    valueToShow: computed("filterValue", function () {
      if (this.get("filterValue")) {
        return "Marcado";
      }
    }),

    filterValue: computed("filtersValues", function () {
      var filterValue = this.filtersValues[this.name];

      if (isPresent(filterValue)) {
        return filterValue === this.valueToSend;
      } else {
        return false;
      }
    })
  });
});