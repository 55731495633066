define("juridico-sengerj/components/toggleable-row-table/hideable-row", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    tagName: "tr",
    animationDuration: 250,
    columnsLength: 0,
    preloadHidden: false,
    isHidden: true,
    _isHidden: true,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.$().hide();
      this.$("div.slide-up-down").hide();
    },

    didUpdateAttrs: function didUpdateAttrs(_ref) {
      var isHidden = _ref.newAttrs.isHidden;

      this._super.apply(this, arguments);
      this._slideToggle(isHidden.value);
    },

    _slideToggle: function _slideToggle(isHidden) {
      if (isHidden) {
        this._slideUp();
      } else {
        this._slideDown();
      }
    },

    _slideUp: function _slideUp() {
      this.$("div.slide-up-down").velocity("slideUp", {
        duration: this.animationDuration
      });
    },

    _slideDown: function _slideDown() {
      this.set("_isHidden", false);

      _ember["default"].run.schedule("afterRender", this, function () {
        this.$().show();
        this.$("div.slide-up-down").velocity("slideDown", {
          duration: this.animationDuration
        });
      });
    }
  });
});