define("juridico-sengerj/templates/components/emails-tags", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 9,
              "column": 0
            }
          },
          "moduleName": "juridico-sengerj/templates/components/emails-tags.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "editable-tags", [], ["placeholder", "Adicionar E-mail", "tags", ["subexpr", "map-by", ["email", ["get", "emails", ["loc", [null, [4, 25], [4, 31]]], 0, 0, 0, 0]], [], ["loc", [null, [4, 9], [4, 32]]], 0, 0], "onTagAdd", ["subexpr", "action", ["addEmail"], [], ["loc", [null, [5, 13], [5, 32]]], 0, 0], "onTagRemove", ["subexpr", "action", ["removeEmail"], [], ["loc", [null, [6, 16], [6, 38]]], 0, 0], "onFocusOut", ["subexpr", "action", ["onFocusOut"], [], ["loc", [null, [7, 15], [7, 36]]], 0, 0]], ["loc", [null, [2, 2], [8, 4]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 0
          }
        },
        "moduleName": "juridico-sengerj/templates/components/emails-tags.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "bs-form-element", [], ["label", "E-mails", "property", "emails", "attrValidations", ["subexpr", "@mut", [["get", "attrValidations", ["loc", [null, [1, 69], [1, 84]]], 0, 0, 0, 0]], [], [], 0, 0], "showValidation", ["subexpr", "@mut", [["get", "showValidation", ["loc", [null, [1, 100], [1, 114]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [1, 0], [9, 20]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});