/* jshint ignore:start */


if (typeof FastBoot === 'undefined') {
  if (!runningTests) {
    require('juridico-sengerj/app')['default'].create({"name":"juridico-sengerj","version":"1.4.0+"});
  }
}

define('~fastboot/app-factory', ['juridico-sengerj/app', 'juridico-sengerj/config/environment'], function(App, config) {
  App = App['default'];
  config = config['default'];

  return {
    'default': function() {
      return App.create(config.APP);
    }
  };
});


/* jshint ignore:end */
