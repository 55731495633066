define('juridico-sengerj/components/profissional-degrees', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service('store'),
    especialidades: [],
    actions: {
      changeDegrees: function changeDegrees(especialidadesDescricao) {
        var _this = this;

        var especialidades = especialidadesDescricao.map(function (descricao) {
          return _this.especialidades.findBy("descricao", descricao);
        });

        this.profissional.set("especialidades", especialidades);
      }
    },

    especialidadesEfetivas: _ember['default'].computed.filterBy("profissional.especialidades", "efetivo", true),

    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);
      this.get("store").findAll("especialidade").then(function (especialidades) {
        _this2.set("especialidades", especialidades);
      });
    }
  });
});