define('juridico-sengerj/services/loading-slider', ['exports', 'ember'], function (exports, _ember) {
  var Service = _ember['default'].Service;
  var Evented = _ember['default'].Evented;
  exports['default'] = Service.extend(Evented, {
    startLoading: function startLoading() {
      this.trigger('startLoading');
    },

    endLoading: function endLoading() {
      this.trigger('endLoading');
    },

    changeAttrs: function changeAttrs(attrs) {
      this.trigger('changeAttrs', attrs);
    }
  });
});