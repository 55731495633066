define('juridico-sengerj/models/juridico/advogado', ['exports', 'juridico-sengerj/models/abstract-model', 'ember-data', 'ember', 'ember-cp-validations'], function (exports, _juridicoSengerjModelsAbstractModel, _emberData, _ember, _emberCpValidations) {

	var Validations = (0, _emberCpValidations.buildValidations)({
		oab: [(0, _emberCpValidations.validator)('presence', true)],

		escritorio: [(0, _emberCpValidations.validator)('presence', true)]
	});

	exports['default'] = _juridicoSengerjModelsAbstractModel['default'].extend(Validations, {
		oab: _emberData['default'].attr('string'),
		createdAt: _emberData['default'].attr(),
		usuario: _emberData['default'].belongsTo(),
		pessoa: _emberData['default'].belongsTo(),
		escritorio: _emberData['default'].belongsTo('juridico/escritorio'),

		nome: _ember['default'].computed('pessoa.nome', function () {
			return (this.get("pessoa.nome") || "").trim();
		})
	});
});