define('juridico-sengerj/components/filters/filter-select-enum', ['exports', 'juridico-sengerj/components/select-enum', 'juridico-sengerj/mixins/components/filter', 'ember'], function (exports, _juridicoSengerjComponentsSelectEnum, _juridicoSengerjMixinsComponentsFilter, _ember) {
  exports['default'] = _juridicoSengerjComponentsSelectEnum['default'].extend(_juridicoSengerjMixinsComponentsFilter['default'], {
    actions: {
      changeEnum: function changeEnum(text) {
        var selected = this._super(text);

        if (selected) {
          this.saveFilterValue(selected.value);
        } else {
          this.clearFilterValue();
        }
      }
    },

    getEnumByValue: function getEnumByValue(value) {
      if (value) {
        return {
          text: this.get("enums").findBy("value", value).get("text"),
          value: value
        };
      }

      return null;
    },

    valueToShow: _ember['default'].computed.alias("selected.text"),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var filterValue = this.get("filterValue");

      this.get("loadPromise").then(function () {
        if (_ember['default'].isPresent(filterValue)) {
          _this.set("selected", _this.getEnumByValue(filterValue));
        }
      });
    }
  });
});