define("juridico-sengerj/components/tables/table-usuarios", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    usuarios: [],
    columnsTitle: ["E-mail", "Nome", "Ultima Alteração", "Ultimo Login"],

    actions: {
      toggleUser: function toggleUser(usuario) {
        usuario.toggleProperty("ativo");
        usuario.save();
      }
    }
  });
});