define("juridico-sengerj/routes/dashboard/inicio/advogado/atendimentos", ["exports", "juridico-sengerj/routes/dashboard/atendimentos/advogados/index"], function (exports, _juridicoSengerjRoutesDashboardAtendimentosAdvogadosIndex) {
  exports["default"] = _juridicoSengerjRoutesDashboardAtendimentosAdvogadosIndex["default"].extend({
    queryParams: {
      comPedenciaDocumentos: {
        replace: true,
        refreshModel: true
      },

      dataAtendimentoFim: {
        replace: true,
        refreshModel: true
      },

      porStatus: {
        replace: true,
        refreshModel: true
      }
    }
  });
});