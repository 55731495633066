define("juridico-sengerj/controllers/dashboard/alvaras/index", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Controller.extend({
    queryParams: ["porCodigoPasta", "porObjetoAcao", "porStatusPasta", "porEmpresaIdReclamada", "porTipoOcorrenciaId"],

    columnsTitle: ["Pasta", "Tipo", "Status", "N. Processo", "Advogado", "Reclamada", "Objeto", "Descrição", "Número", "Data", "Levantado"],

    columnsPdf: {
      "Pasta": "ocorrencia.pasta.showCode",
      "Tipo": "ocorrencia.pasta.tipo.text",
      "Status": "ocorrencia.pasta.status.text",
      "N.Processo": "ocorrencia.pasta.numeroProcesso",
      "Advogado": "ocorrencia.pasta.advogado.nome",
      "Reclamada": "ocorrencia.pasta.reclamadaPrincipal",
      "Objeto": "ocorrencia.pasta.objetosAcaoPrincipal",
      "Descrição": "ocorrencia.tipoOcorrencia.descricao",
      "Número": "numero",
      "Data": "ocorrencia.dataOcorrenciaFormatado",
      "Levantado": "valorLevantadoString",
      "Saldo a Pagar": "saldoPraPagar",
      "Sócios Em Dia": "ocorrencia.pasta.sociosEmDiaCountString",
      "Sócios Em Débito": "ocorrencia.pasta.sociosNaoEmDiaCountString",
      "Não Sócios": "ocorrencia.pasta.naoSociosCountString",
      "Total Reclamantes": "ocorrencia.pasta.reclamantesCountString"
    },

    columnStylesPdf: {
      0: { columnWidth: "wrap" },
      1: { columnWidth: "wrap" },
      2: { columnWidth: "wrap" },
      3: { columnWidth: "auto" },
      4: { columnWidth: "auto" },
      5: { columnWidth: "auto" },
      6: { columnWidth: "auto" },
      7: { columnWidth: "auto" },
      8: { columnWidth: "auto" },
      9: { columnWidth: "auto" },
      10: { columnWidth: "auto" },
      11: { columnWidth: "auto" },
      12: { columnWidth: "auto" },
      13: { columnWidth: "auto" },
      14: { columnWidth: "auto" },
      15: { columnWidth: "auto" },
      16: { columnWidth: "auto" }
    },

    includePdf: "ocorrencia",

    filtersValuesToShow: null,

    actions: {
      applyFilters: function applyFilters(filters) {
        _ember["default"].get(this, "apply-filters")(filters);
      }
    }
  });
});