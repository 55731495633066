define('juridico-sengerj/components/x-toggle', ['exports', 'ember-toggle/components/x-toggle/component', 'juridico-sengerj/config/environment'], function (exports, _emberToggleComponentsXToggleComponent, _juridicoSengerjConfigEnvironment) {

  var config = _juridicoSengerjConfigEnvironment['default']['ember-toggle'] || {};

  exports['default'] = _emberToggleComponentsXToggleComponent['default'].extend({
    theme: config.defaultTheme || 'default',
    defaultOffLabel: config.defaultOffLabel || 'Off::off',
    defaultOnLabel: config.defaultOnLabel || 'On::on',
    showLabels: config.defaultShowLabels || false,
    size: config.defaultSize || 'medium'
  });
});