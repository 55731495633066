define('juridico-sengerj/mixins/controllers/basic-table-listing', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    include: '',

    init: function init() {
      this._super.apply(this, arguments);
      this.set("queryParams", {});
      _ember['default'].set(this.queryParams, "include", this.include);
    }
  });
});