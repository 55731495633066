define('juridico-sengerj/models/juridico/homologacao/atendimento', ['exports', 'juridico-sengerj/models/abstract-model', 'ember-data', 'ember', 'ember-cp-validations'], function (exports, _juridicoSengerjModelsAbstractModel, _emberData, _ember, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    salarioRescisorio: [(0, _emberCpValidations.validator)('presence', true)],
    tipoDemissao: [(0, _emberCpValidations.validator)('presence', true)],
    tipoContrato: [(0, _emberCpValidations.validator)('presence', true)],
    jornada: [(0, _emberCpValidations.validator)('presence', true)],
    salarioRescisorio: [(0, _emberCpValidations.validator)('presence', true)],
    salarioBase: [(0, _emberCpValidations.validator)('presence', true)],
    valorRescisao: [(0, _emberCpValidations.validator)('presence', true)]
  });

  exports['default'] = _juridicoSengerjModelsAbstractModel['default'].extend(Validations, {
    dataHomologacao: _emberData['default'].attr('date'),
    dataHomologacaoRealizado: _emberData['default'].attr('date'),
    createdAt: _emberData['default'].attr('date'),
    ultimaRemuneracao: _emberData['default'].attr('string'),
    tipoDemissao: _emberData['default'].attr('enum'),
    salarioRescisorio: _emberData['default'].attr('number'),
    salarioBase: _emberData['default'].attr('number'),
    jornada: _emberData['default'].attr('string'),
    tipoContrato: _emberData['default'].attr('enum'),
    valorRescisao: _emberData['default'].attr('number'),
    observacao: _emberData['default'].attr('string'),
    uploadRescisao: _emberData['default'].attr('string'),
    uploadRescisaoUrl: _emberData['default'].attr('string'),
    uploadRescisaoIdentifier: _emberData['default'].attr('string'),
    registro: _emberData['default'].attr('string'),
    agendamento: _emberData['default'].belongsTo('juridico/homologacao/agendamento'),
    profissional: _emberData['default'].belongsTo(),
    profissionalUnidade: _emberData['default'].belongsTo(),

    codigoHomologacao: _ember['default'].computed('registro', 'dataHomologacao', function () {
      var _getProperties = this.getProperties('registro', 'dataHomologacao');

      var registro = _getProperties.registro;
      var dataHomologacao = _getProperties.dataHomologacao;

      if (registro && dataHomologacao) {
        return ("0000" + registro).slice(-5) + '/' + dataHomologacao.getFullYear();
      }
    })
  });
});