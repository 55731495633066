define('juridico-sengerj/initializers/router', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(application) {
    application.inject('route', 'router', 'router:main');
    application.inject('component', 'router', 'router:main');
    application.inject('controller', 'router', 'router:main');
  }

  exports['default'] = {
    name: 'router',
    initialize: initialize
  };
});