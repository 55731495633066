define('juridico-sengerj/models/juridico/reclamante-contrato', ['exports', 'juridico-sengerj/models/abstract-model', 'ember-data', 'ember-cp-validations'], function (exports, _juridicoSengerjModelsAbstractModel, _emberData, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    dataAssinatura: (0, _emberCpValidations.validator)('presence', true),
    contratoAssinadoUrl: [(0, _emberCpValidations.validator)('presence', true)]
  });

  exports['default'] = _juridicoSengerjModelsAbstractModel['default'].extend(Validations, {
    dataAssinatura: _emberData['default'].attr('date'),
    reclamante: _emberData['default'].belongsTo('juridico/reclamante'),
    contratoAssinadoUrl: _emberData['default'].attr('string'),
    nomeEscritorio: _emberData['default'].attr('string'),
    codigoReclamanteContrato: _emberData['default'].attr('string'),
    numeroProcesso: _emberData['default'].attr('string'),
    pasta: _emberData['default'].belongsTo('juridico/pasta'),
    condicaoReclamante: _emberData['default'].attr('string')
  });
});