define("juridico-sengerj/templates/components/editable-text", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 2
              },
              "end": {
                "line": 21,
                "column": 2
              }
            },
            "moduleName": "juridico-sengerj/templates/components/editable-text.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "popover top show");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "arrow");
            dom.setAttribute(el2, "style", "left: 50%;");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "popover-content");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "form-inline editableform");
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5, "class", "control-group form-group");
            var el6 = dom.createTextNode("\n              ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("div");
            var el7 = dom.createTextNode("\n                ");
            dom.appendChild(el6, el7);
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n                ");
            dom.appendChild(el6, el7);
            var el7 = dom.createElement("div");
            dom.setAttribute(el7, "class", "editable-buttons pull-right");
            var el8 = dom.createTextNode("\n                  ");
            dom.appendChild(el7, el8);
            var el8 = dom.createElement("button");
            dom.setAttribute(el8, "class", "btn btn-primary");
            var el9 = dom.createElement("i");
            dom.setAttribute(el9, "class", "fa fa-fw fa-check");
            dom.appendChild(el8, el9);
            dom.appendChild(el7, el8);
            var el8 = dom.createTextNode("\n                  ");
            dom.appendChild(el7, el8);
            var el8 = dom.createElement("button");
            dom.setAttribute(el8, "class", "btn btn-default");
            var el9 = dom.createElement("i");
            dom.setAttribute(el9, "class", "fa fa-fw fa-times");
            dom.appendChild(el8, el9);
            dom.appendChild(el7, el8);
            var el8 = dom.createTextNode("\n                ");
            dom.appendChild(el7, el8);
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n              ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n            ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var element1 = dom.childAt(element0, [3, 1, 1, 1, 1]);
            var element2 = dom.childAt(element1, [3]);
            var element3 = dom.childAt(element2, [1]);
            var element4 = dom.childAt(element2, [3]);
            var morphs = new Array(5);
            morphs[0] = dom.createAttrMorph(element0, 'id');
            morphs[1] = dom.createAttrMorph(element0, 'style');
            morphs[2] = dom.createMorphAt(element1, 1, 1);
            morphs[3] = dom.createElementMorph(element3);
            morphs[4] = dom.createElementMorph(element4);
            return morphs;
          },
          statements: [["attribute", "id", ["concat", [["get", "elementId", ["loc", [null, [3, 15], [3, 24]]], 0, 0, 0, 0], "-popover"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "style", ["subexpr", "html-safe", [["get", "position", ["loc", [null, [3, 79], [3, 87]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [3, 89]]], 0, 0], 0, 0, 0, 0], ["content", "yield", ["loc", [null, [10, 16], [10, 25]]], 0, 0, 0, 0], ["element", "action", ["saveChanges"], ["bubbles", false], ["loc", [null, [12, 50], [12, 88]]], 0, 0], ["element", "action", ["closePopOver"], ["bubbles", false], ["loc", [null, [13, 50], [13, 89]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 22,
              "column": 0
            }
          },
          "moduleName": "juridico-sengerj/templates/components/editable-text.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "isVisible", ["loc", [null, [2, 8], [2, 17]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [2, 2], [21, 9]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 23,
            "column": 0
          }
        },
        "moduleName": "juridico-sengerj/templates/components/editable-text.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "ember-wormhole", [], ["to", "ember-top-elements"], 0, null, ["loc", [null, [1, 0], [22, 19]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});