define('juridico-sengerj/components/tables/table-atendimentos-advogado', ['exports', 'ember', 'juridico-sengerj/config/environment', 'ember-cp-validations'], function (exports, _ember, _juridicoSengerjConfigEnvironment, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    numeroProcesso: [(0, _emberCpValidations.validator)('presence', true)],

    peticaoInicialUrl: [(0, _emberCpValidations.validator)('presence', true)]
  });

  exports['default'] = _ember['default'].Component.extend(Validations, {
    session: _ember['default'].inject.service(),
    tagName: '',
    _atendimentoModalVisible: false,
    _atendimentoModal: null,

    columnsTitle: ["Agendamento", "Advogado", "Reclamante", "Status", "Data", "Hora", "Doc. Entregue?"],

    actions: {
      toggleModalPeticaoInicial: function toggleModalPeticaoInicial(atendimento) {
        this.setProperties({
          _atendimentoModalVisible: !this._atendimentoModalVisible,
          _atendimentoModal: atendimento
        });
      },

      saveAtendimento: function saveAtendimento(atendimento) {
        atendimento.save();
      },

      enviarPeticaoInicial: function enviarPeticaoInicial() {
        this._atendimentoModal.save().then(function () {
          _ember['default'].$.niftyNoty({
            type: 'success',
            icon: 'fa fa-exclamation-triangle fa-2x',
            container: "floating",
            title: 'Petição Inicial foi enviada',
            timer: 3000
          });
        });
        this.toggleProperty("_atendimentoModalVisible");
      }
    },

    numeroProcesso: _ember['default'].computed.alias("_atendimentoModal.numeroProcesso"),

    peticaoInicialUrl: _ember['default'].computed.alias("_atendimentoModal.peticaoInicialUrl"),

    atendimentoUrl: _juridicoSengerjConfigEnvironment['default'].ApiHost + '/v1/juridico/advogado/atendimentos/',

    atendimentoCred: _ember['default'].computed("session.data", function () {
      var _get = this.get("session.data.authenticated");

      var usuarioId = _get.usuarioId;
      var token = _get.token;

      return '?token=' + token + '&usuario_id=' + usuarioId;
    })
  });
});