define('juridico-sengerj/controllers/dashboard/tipo-ocorrencias/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: ['porId', 'somenteFinanceiro'],

    columnsTitle: ["Nome", "Pede hora?", "Pede valor?", "Pertence ao financeiro?", "Reclamante visualiza?", "Pode enviar email?"],

    tipoOcorrenciaModal: null,
    tipoOcorrenciaModalVisible: false,

    actions: {
      openModalTipoOcorrencia: function openModalTipoOcorrencia() {
        var tipoOcorrenciaModal = arguments.length <= 0 || arguments[0] === undefined ? this.store.createRecord('juridico/tipo-ocorrencia') : arguments[0];

        this.setProperties({ tipoOcorrenciaModalVisible: true, tipoOcorrenciaModal: tipoOcorrenciaModal });
      },

      toggleNecessidadeCampo: function toggleNecessidadeCampo(tipoOcorrencia, campo) {
        tipoOcorrencia.toggleProperty(campo);
        tipoOcorrencia.save();
      },

      salvarTipoOcorrencia: function salvarTipoOcorrencia(tipoOcorrencia) {
        var _this = this;

        tipoOcorrencia.save().then(function () {
          _this.set("tipoOcorrenciaModalVisible", false);

          _this.container.lookup("route:dashboard.tipo-ocorrencias").refresh();

          _ember['default'].$.niftyNoty({
            type: 'success',
            icon: 'fa fa-exclamation-triangle fa-2x',
            container: "floating",
            title: 'O tipo de ocorrência ' + tipoOcorrencia.get('descricao') + ' foi salvo com sucesso.',
            timer: 3000
          });
        });
      },

      removeTipoOcorrencia: function removeTipoOcorrencia(tipoOcorrencia) {
        tipoOcorrencia.destroyRecord().then(function () {

          _ember['default'].$.niftyNoty({
            type: 'success',
            icon: 'fa fa-exclamation-triangle fa-2x',
            container: "floating",
            title: 'O tipo de ocorrência foi removido com sucesso.',
            timer: 3000
          });
        });
      },

      changeValue: function changeValue(tipoOcorrencia) {
        tipoOcorrencia.save();
      }
    }
  });
});