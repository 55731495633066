define("juridico-sengerj/components/panels/panel-show-addresses", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    panelClasses: "",
    color: "first",
    addresses: [],

    actions: {
      removeAddress: function removeAddress(address) {
        if (!address.get("isSaving")) {
          address.destroyRecord();
        }
      },

      addAddress: function addAddress() {
        var enderecavel = this.get("addresses.content.record").getRecord();
        this.get("addresses").createRecord({ enderecavel: enderecavel });
      }
    },

    bairroTabs: _ember["default"].computed.mapBy("addresses", "bairroNome")
  });
});