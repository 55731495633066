define('juridico-sengerj/components/button-export-atendimentos-advogado', ['exports', 'ember', 'juridico-sengerj/components/button-export-model', 'juridico-sengerj/helpers/format-date', 'juridico-sengerj/helpers/format-time'], function (exports, _ember, _juridicoSengerjComponentsButtonExportModel, _juridicoSengerjHelpersFormatDate, _juridicoSengerjHelpersFormatTime) {
  exports['default'] = _juridicoSengerjComponentsButtonExportModel['default'].extend({
    columns: {
      Agendamento: "agendamento.mostrarCodigo",
      Advogado: "agendamento.nomeAdvogado",
      Status: "status.text",
      Data: function Data(a) {
        return (0, _juridicoSengerjHelpersFormatDate.formatDate)([a.get("createdAt")]);
      },
      "Horário": function HorRio(a) {
        return (0, _juridicoSengerjHelpersFormatTime.formatTime)([a.get("createdAt")]);
      },
      "Doc. Entregue?": function DocEntregue(a) {
        return a.get("isDocumentosEntregues") ? "Sim" : "Não";
      },
      Reclamante: "agendamento.reclamanteIndividual"
    },

    title: "Atendimentos Advogados",

    longTextColumns: ["Advogado", "Reclamante"],

    include: "agendamento.advogado.pessoa, documentos_necessarios"
  });
});