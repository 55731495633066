define('juridico-sengerj/components/route-components/form-pasta/ocorrencias-excluidas', ['exports', 'ember', 'juridico-sengerj/utils/pdf-header-pasta-info'], function (exports, _ember, _juridicoSengerjUtilsPdfHeaderPastaInfo) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),

    isModalVisible: false,
    isExportModalVisible: false,
    pastaId: null,
    numeroProcesso: null,

    _filters: {},
    _ocorrencias: [],

    actions: {
      applyFilters: function applyFilters(filters) {
        this.set("_filters", filters);
        this._loadOcorrencias();
      },

      reloadOcorrencias: function reloadOcorrencias() {
        this._loadOcorrencias();
      }
    },

    pasta: _ember['default'].computed("pastaId", function () {
      return this.get("store").peekRecord('juridico/pasta', this.pastaId);
    }),

    _loadOcorrencias: function _loadOcorrencias() {
      var store = this.get("store");

      this.set("_ocorrencias", store.query("juridico/ocorrencia", Object.assign({ porExclusao: true, pastaId: this.pastaId, include: 'tipo_ocorrencia' }, this.filters)));
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this._loadOcorrencias();
    }
  });
});