define('juridico-sengerj/models/juridico/advogado/agendamento', ['exports', 'juridico-sengerj/models/abstract-model', 'ember-data', 'ember', 'juridico-sengerj/utils/exibir-reclamantes-computed', 'ember-cp-validations'], function (exports, _juridicoSengerjModelsAbstractModel, _emberData, _ember, _juridicoSengerjUtilsExibirReclamantesComputed, _emberCpValidations) {

	var Validations = (0, _emberCpValidations.buildValidations)({
		ramoDireito: [(0, _emberCpValidations.validator)('presence', true)],

		tipo: [(0, _emberCpValidations.validator)('presence', true)],

		escritorio: [(0, _emberCpValidations.validator)('presence', true)],

		canalAtendimento: [(0, _emberCpValidations.validator)('presence', true)],

		reclamantes: [(0, _emberCpValidations.validator)('length', { min: 1 })]
	});

	exports['default'] = _juridicoSengerjModelsAbstractModel['default'].extend(Validations, {
		codigo: _emberData['default'].attr("string"),
		canalAtendimento: _emberData['default'].attr("enum"),
		informacaoExtra: _emberData['default'].attr("string"),
		reclamanteIndividual: _emberData['default'].attr("string", { readOnly: true }),
		tipo: _emberData['default'].attr("enum"),
		createdAt: _emberData['default'].attr("date", { defaultValue: function defaultValue() {
				return new Date();
			} }),
		dataHorario: _emberData['default'].attr("date"),
		reclamantes: _emberData['default'].hasMany('juridico/reclamante'),
		advogado: _emberData['default'].belongsTo("juridico/advogado"),
		ramoDireito: _emberData['default'].belongsTo("juridico/ramo-direito"),
		escritorio: _emberData['default'].belongsTo("juridico/escritorio"),
		atendimento: _emberData['default'].belongsTo("juridico/advogado/atendimento"),
		justificativa: _emberData['default'].belongsTo("juridico/advogado/agendamento/justificativa"),

		numeroProcesso: _ember['default'].computed.alias("atendimento.numeroProcesso"),

		exibirReclamantes: (0, _juridicoSengerjUtilsExibirReclamantesComputed['default'])(),

		status: _ember['default'].computed('atendimentoId', 'justificativa.tipoJustificativa', function () {
			if (this.get("atendimentoId") !== null) {
				return "Atendido";
			} else if (this.belongsTo("justificativa").id() !== null) {
				return this.get("justificativa.tipoJustificativa.text");
			} else {
				return "Em Aberto";
			}
		}),

		isAtendido: _ember['default'].computed('atendimentoId', function () {
			return this.get("atendimentoId") !== null;
		}),

		nomeEscritorio: _ember['default'].computed.alias("escritorio.nomeFantasia"),

		atendimentoId: _ember['default'].computed("atendimento", function () {
			return this.belongsTo("atendimento").id();
		}),

		nomeAdvogado: _ember['default'].computed("advogado.pessoa.nome", function () {
			return this.get("advogado.pessoa.nome") || "Sem Advogado";
		}),

		mostrarCodigo: _ember['default'].computed("codigo", function () {
			return 'AGJ - ' + ("000" + this.get('codigo')).slice(-4) + '/' + this.get('createdAt').getFullYear();
		})
	});
});