define('juridico-sengerj/controllers/dashboard/configuracao-homologacoes/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: ['porId'],

    actions: {

      changeValue: function changeValue(feriado) {
        this.store.find('juridico/homologacao/feriado', feriado.id).then(function (feriado) {
          feriado.set('nome', feriado.get('nome'));
          feriado.save();

          _ember['default'].$.niftyNoty({
            type: 'success',
            icon: 'fa fa-exclamation-triangle fa-2x',
            container: "floating",
            title: 'O feriado foi atualizado com sucesso.',
            timer: 3000
          });
        });
      },

      rollbackValue: function rollbackValue(feriado) {
        this.store.peekRecord('juridico/homologacao/feriado', feriado.id).rollbackAttributes();
      }
    }
  });
});