define("juridico-sengerj/mixins/pages-loader", ["exports", "ember"], function (exports, _ember) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports["default"] = _ember["default"].Mixin.create({
    store: _ember["default"].inject.service(),
    loadedPages: _ember["default"].Object.create(),
    isConcurrency: true,

    loadPages: function loadPages(property, modelName) {
      var _this = this;

      var queryParams = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

      var loadedPages = this._startDefaultProperties(property);

      return this.get("store").query(modelName, queryParams).then(function (records) {
        _this._setTotalPages(loadedPages, records);
        _this._putRecordsToPage(loadedPages, records);

        return _this._loadAllPages(loadedPages, modelName, queryParams);
      }).then(function () {
        return loadedPages.all;
      });
    },

    loadPagesWithFirstPageLoaded: function loadPagesWithFirstPageLoaded(property, records) {
      var queryParams = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

      if (records && records.get("length") > 0) {
        var modelName = records.get("firstObject._internalModel.modelName");

        var _queryParams = Object.assign({}, queryParams, records.query);

        delete _queryParams["page"];

        return this.loadPages(property, modelName, _queryParams);
      } else {
        return _ember["default"].RSVP.resolve([]);
      }
    },

    _loadAllPages: function _loadAllPages(loadedPages, modelName, queryParams) {
      var _this2 = this;

      var store = this.get("store");

      var pages = [].concat(_toConsumableArray(Array(loadedPages.total - 1).keys())).map(function (p) {
        return p + 2;
      });

      if (this.isConcurrency) {
        return _ember["default"].RSVP.all(pages.map(function (page) {
          return _this2._loadNewPage(loadedPages, modelName, page, queryParams);
        }));
      } else {
        return pages.reduce(function (promises, page) {
          return promises.then(function () {
            return _this2._loadNewPage(loadedPages, modelName, page, queryParams);
          });
        }, _ember["default"].RSVP.resolve());
      }
    },

    _putRecordsToPage: function _putRecordsToPage(loadedPages, records) {
      var pages = _ember["default"].A(loadedPages.pages.toArray());

      pages[((records.meta['current-page'] || 1) - 1).toString()] = records.toArray();

      loadedPages.set("pages", pages);

      loadedPages.set("all", this._concatAllPages(loadedPages));

      if (this._isAllPagesLoaded(loadedPages)) {
        loadedPages.set("isLoaded", true);
      }
    },

    _loadNewPage: function _loadNewPage(loadedPages, modelName, page, queryParams) {
      var _this3 = this;

      var store = this.get("store");
      return store.query(modelName, Object.assign({ page: page }, queryParams)).then(function (records) {
        _this3._putRecordsToPage(loadedPages, records);
      });
    },

    _concatAllPages: function _concatAllPages(loadedPages) {
      return loadedPages.pages.reduce(function (records, pages) {
        return records.concat(pages);
      }, []);
    },

    _isAllPagesLoaded: function _isAllPagesLoaded(loadedPages) {
      return loadedPages.pages.length === loadedPages.total;
    },

    _setTotalPages: function _setTotalPages(loadedPages, records) {
      loadedPages.set("total", records.meta['total-pages'] || 1);
    },

    _startDefaultProperties: function _startDefaultProperties(property) {
      var total = arguments.length <= 1 || arguments[1] === undefined ? 0 : arguments[1];

      this.loadedPages.set(property, _ember["default"].Object.create({
        total: total,
        all: [],
        pages: _ember["default"].A(),
        isLoaded: false
      }));

      return this.loadedPages.get(property);
    }
  });
});
// TODO Transferir mixin para uma classe Object na pasta utils