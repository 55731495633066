define("juridico-sengerj/components/panels/panel-with-header-tabs", ["exports", "juridico-sengerj/components/panels/panel-simple"], function (exports, _juridicoSengerjComponentsPanelsPanelSimple) {
  exports["default"] = _juridicoSengerjComponentsPanelsPanelSimple["default"].extend({
    records: [],
    tabs: [],
    currentIndex: 0,
    newTabLabel: "Novo",
    "add-new-tab": function addNewTab() {},
    "remove-tab": function removeTab() {},

    actions: {
      selectTab: function selectTab(tabIndex) {
        this.set("currentIndex", tabIndex);
      },

      removeTab: function removeTab(record) {
        Ember.get(this, "remove-tab")(record);
        this.decrementProperty("currentIndex");
      },

      addNewTab: function addNewTab() {
        Ember.get(this, "add-new-tab")();
        this.set("currentIndex", this.tabs.length);
      }
    },

    isTabsEmpty: Ember.computed.empty("tabs"),

    currentRecord: Ember.computed("currentIndex", "records.[]", function () {
      return this.get("records").toArray()[this.currentIndex];
    })
  });
});