define("juridico-sengerj/locales/pt/br/translations", ["exports"], function (exports) {
  exports["default"] = {
    errors: {
      description: "Este campo",
      inclusion: "{{description}} não está incluido nesta lista",
      exclusion: "{{description}} é reservado",
      invalid: "{{description}} é inválido",
      confirmation: "{{description}} não está igual o campo {{on}}",
      accepted: "{{description}} tem que ser aceito",
      empty: "{{description}} não pode estar vazio",
      blank: "{{description}} precisa ser preenchido",
      present: "{{description}} precisa estar em branco",
      collection: "{{description}} precisa ser uma coleção",
      singular: "{{description}} não pode ser uma coleção",
      tooLong: "{{description}} não pode ter mais que {{max}} caracteres)",
      tooShort: "{{description}} precisa ter mais caracteres (minino é {{min}} caracteres)",
      before: "{{description}} não pode ser anterior à data {{before}}",
      after: "{{description}} precisa ser depois de {{after}}",
      wrongDateFormat: "{{description}} precisa estar no formato {{format}}",
      wrongLength: "{{description}} quantidade de caracteres incorreta (precisa ter {{is}} caracteres)",
      notANumber: "{{description}} precisa ser um número",
      notAnInteger: "{{description}} precisa ser um número inteiro",
      greaterThan: "{{description}} precisa ser maior que {{gt}}",
      greaterThanOrEqualTo: "{{description}} precisa ser maior ou igual que {{gte}}",
      equalTo: "{{description}} precisa ser igual a {{is}}",
      lessThan: "{{description}} precisa ser menor que {{lt}}",
      lessThanOrEqualTo: "{{description}} precisa ser menor ou igual que {{lte}}",
      otherThan: "{{description}} precisa ser diferente de {{value}}",
      odd: "{{description}} precisa ser ímpar",
      even: "{{description}} precisa ser par",
      positive: "{{description}} precisa ser positivo",
      date: "{{description}} precisa ser uma data válida",
      email: "{{description}} precisa ser um e-mail válido",
      phone: "{{description}} precisa ser um telefone válido",
      url: "{{description}} precisa ser um url válido",
      onOrAfter: '{{description}} não pode ser superior ao dia de hoje',
      onOrBefore: '{{description}} deve ser igual ou antes de {{onOrBefore}}'
    }
  };
});