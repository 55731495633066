define('juridico-sengerj/utils/json-to-csv-parser', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Object.extend({
    csvContent: "",

    parse: function parse(_ref) {
      var headers = _ref.headers;
      var rows = _ref.rows;
      var delimiter = _ref.delimiter;
      var _ref$bottomExtraRows = _ref.bottomExtraRows;
      var bottomExtraRows = _ref$bottomExtraRows === undefined ? [] : _ref$bottomExtraRows;

      var csvContent = headers.join(delimiter) + '\r\n';

      var _rows = [].concat(rows, [[]], [bottomExtraRows]);

      var formatedData = _rows.map(function (row) {
        return row.map(function (attr) {
          return typeof attr === "string" ? '"' + attr + '"' : attr;
        }).join(delimiter);
      });

      csvContent += formatedData.join("\r\n");

      this.set("csvContent", csvContent);

      return csvContent;
    },

    saveCSV: function saveCSV(fileName) {
      var uri = 'data:text/csv;charset=utf-8,' + encodeURI(this.get("csvContent"));

      var link = document.createElement("a");
      link.href = uri;

      link.style = "visibility:hidden";
      link.download = fileName + ".csv";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  });
});