define("juridico-sengerj/components/modal-new-ocorrencia", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    store: _ember["default"].inject.service(),
    session: _ember["default"].inject.service(),

    isModalVisible: false,
    isSavingOcorrencia: false,
    textoAlerta: null,
    pasta: null,
    "on-saved": function onSaved() {},
    _tipoOcorrencia: _ember["default"].computed.alias("_newOcorrencia.tipoOcorrencia"),

    _newOcorrencia: _ember["default"].computed("pasta", "isModalVisible", function () {
      var ocorrencia = this.get("store").createRecord("juridico/ocorrencia");

      ocorrencia.get("notas").createRecord();

      return ocorrencia;
    }),

    actions: {

      mouseOverLi: function mouseOverLi(ocorrencia) {

        ocorrencia = this.get("_newOcorrencia");

        var documentos = ocorrencia.get("documentos").map(function (d) {
          d;
        });

        var notas = ocorrencia.get("notas").map(function (d) {
          d.get('descricao');
        });

        if (notas.length === 0 && documentos.length === 0) {

          this.setProperties({
            textoAlerta: 'Não foi inserido nenhum comentário e/ou documento.'
          });
        } else if (notas.length === 0 && documentos.length != 0) {
          this.setProperties({
            textoAlerta: 'Não foi inserido nenhum comentário e/ou documento.'
          });
        } else if (notas.length != 0 && documentos.length === 0) {
          this.setProperties({
            textoAlerta: 'Não foi inserido nenhum comentário e/ou documento.'
          });
        } else {
          this.setProperties({
            textoAlerta: ''
          });
        };
      },

      changeTipoOcorrencia: function changeTipoOcorrencia(tipo) {
        var tipoDescricao = null;

        switch (tipo.get("descricao")) {
          case "Alvará":
            tipoDescricao = "infoFinanceiro";
            break;

          case "Precatório":
            tipoDescricao = "infoFinanceiro";
            break;

          case "RPV":
            tipoDescricao = "infoFinanceiro";
            break;

          case "Mandado de Pagamento":
            tipoDescricao = "infoFinanceiro";
            break;

          case "Substituição de advogado titular":
            tipoDescricao = "substituicaoAdvogado";
            break;

          case "Acordo":
            tipoDescricao = "acordo";
            break;

          case "Prolabore Escritório":
            tipoDescricao = "prolabore";
            break;

          case "Recebimento de Honorário de êxito":
            tipoDescricao = "infoFinanceiro";
            break;
        }

        this.get("_newOcorrencia").set("infoFinanceiro", null);
        this.get("_newOcorrencia").set("substituicaoAdvogado", null);
        this.get("_newOcorrencia").set("acordo", null);
        this.get("_newOcorrencia").set("prolabore", null);

        if (tipoDescricao) {
          this.get("_newOcorrencia").set(tipoDescricao, this.get("store").createRecord("juridico/" + tipoDescricao));
        }
      },

      addDocumento: function addDocumento() {
        this.get("_newOcorrencia.documentos").createRecord();
      },

      showNotification: function showNotification() {
        _ember["default"].$.niftyNoty({
          type: 'success',
          icon: 'fa fa-thumbs-up fa-2x',
          container: 'floating',
          title: "Ocorrência foi registrada para pasta " + this.get("pasta.showCode"),
          timer: 4000
        });
      },

      saveOcorrencia: function saveOcorrencia() {
        var _this = this;

        var ocorrencia = this.get("_newOcorrencia");

        ocorrencia.set("pasta", this.get("pasta"));

        return ocorrencia.save().then(function () {

          var documentos = ocorrencia.get("documentos").map(function (d) {
            d.set("documentavel", ocorrencia);
            return d.save();
          });

          if (ocorrencia.get('tipoOcorrencia').get('descricao') != 'Alvará') {
            ocorrencia.get("notas").map(function (n) {
              n.set("anotavel", ocorrencia);
              return n.save();
            });
          }

          return _ember["default"].RSVP.all(documentos);
        }).then(function () {
          switch (ocorrencia.get("tipoOcorrencia.descricao")) {
            case "Acordo":
              return ocorrencia.get("acordo.content").save().then(function (acordo) {
                return _ember["default"].RSVP.all(acordo.get("dataPagamentos").map(function (p) {
                  return p.save();
                }));
              });

            case "Prolabore Escritório":
              return ocorrencia.get("prolabore.content").save();

            case "Alvará":
              return ocorrencia.get("infoFinanceiro.content").save();

            case "Recebimento de Honorário de êxito":
              return ocorrencia.get("infoFinanceiro.content").save();

            case "Precatório":
              return ocorrencia.get("infoFinanceiro.content").save();

            case "RPV":
              return ocorrencia.get("infoFinanceiro.content").save();

            case "Mandado de Pagamento":
              return ocorrencia.get("infoFinanceiro.content").save();

            case "Substituição de advogado titular":
              return ocorrencia.get("substituicaoAdvogado.content").save();
          }
        }).then(function () {
          return _this.sendEmailNotificandoAdvogadoCriou();
        }).then(function () {
          return _ember["default"].get(_this, "on-saved")();
        });
      }
    },

    sendEmailNotificandoAdvogadoCriou: function sendEmailNotificandoAdvogadoCriou() {
      if (this.get("session.isAdvogado") || this.get("session.isAdvogadoPleno")) {
        var id = this.get("_newOcorrencia.id");

        var adapter = this.get("store").adapterFor();
        return adapter.ajax(adapter.host + "/juridico/ocorrencias/" + id + "/enviar_notificacao_advogado_criou", 'POST');
      }
    }
  });
});