define("juridico-sengerj/components/available-days-week-table", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    toggleDay: function toggleDay() {},
    daysWeekToDisplay: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
    daysWeekAttribute: ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"],
    model: null,

    actions: {
      toggleDay: function toggleDay(dayWeek) {
        _ember["default"].get(this, "toggleDay")(dayWeek);
      }
    }
  });
});