define("juridico-sengerj/components/accordion-collapse", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    visibleDiv: false,
    text: "Busca Avançada",
    _visibleFirstTime: _ember["default"].computed.reads("visibleDiv"),

    actions: {
      showHideDiv: function showHideDiv() {
        this.set("_visibleFirstTime", true);

        this.toggleAccordionCollapse();

        this.toggleProperty("visibleDiv");
      }
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.visibleDiv && this.get("_visibleFirstTime")) {
        this.$("div.accordion-collapse").show();
      }
    },

    toggleAccordionCollapse: function toggleAccordionCollapse() {
      _ember["default"].run.scheduleOnce('afterRender', this, function () {
        this.$("div.accordion-collapse").velocity(this.visibleDiv ? "slideDown" : "slideUp", {
          duration: 150
        });
      });
    }
  });
});