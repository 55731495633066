define("juridico-sengerj/components/route-components/form-pasta/reclamante-terceiros", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    store: _ember["default"].inject.service(),
    pasta: null,
    _newReclamante: null,
    _isModalVisible: false,
    _filters: {},

    columnsPdf: {
      "Nome": "nome",
      "E-mail": "email"
    },

    columns: ['Nome', 'E-mail'],

    actions: {
      applyFilters: function applyFilters(filters) {
        this.set("_filters", filters);
        this._loadReclamantes();
      },

      updatePastaAndReclamantes: function updatePastaAndReclamantes() {
        this.get("pasta").reload();
        this._loadReclamantes();
      },

      removeReclamante: function removeReclamante(reclamante) {
        var _this = this;

        reclamante.destroyRecord().then(function () {
          _this.get("pasta").reload();
        });
      }
    },

    _loadReclamantes: function _loadReclamantes() {
      var _getProperties = this.getProperties("_filtersWithPastaId", "store");

      var _filtersWithPastaId = _getProperties._filtersWithPastaId;
      var store = _getProperties.store;

      this.set("_reclamantes", store.query("juridico/reclamante", Object.assign({ include: 'pessoa.emails', terceiros: true }, _filtersWithPastaId)));
    },

    _filtersWithPastaId: _ember["default"].computed("_filters", function () {
      return Object.assign({}, this._filters, { pastaId: this.get("pasta.id") });
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this._loadReclamantes();
    }
  });
});