define('juridico-sengerj/controllers/dashboard/inicio/advogado/index', ['exports', 'ember', 'juridico-sengerj/utils/format/date/short'], function (exports, _ember, _juridicoSengerjUtilsFormatDateShort) {
  exports['default'] = _ember['default'].Controller.extend({
    agendamentosColumns: ["Código", "Tipo", "Data", "Status"],

    init: function init() {
      this._super.apply(this, arguments);
      var currentDate = new Date();
      var currentDay = currentDate.getDate();
      var currentMonth = currentDate.getMonth();
      var currentYear = currentDate.getFullYear();

      this.setProperties({
        "31DaysAgo": (0, _juridicoSengerjUtilsFormatDateShort['default'])(new Date(currentYear, currentMonth, currentDay - 31))
      });
    }
  });
});