define("juridico-sengerj/components/upload-grid-model", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    urlField: "",
    imageClass: "col-md-4",
    readonly: false,
    addText: "Adicionar Documento",
    "finished-uploading": function finishedUploading() {},

    actions: {
      addUpload: function addUpload() {
        _ember["default"].get(this, "addUpload")();
        _ember["default"].run.scheduleOnce('afterRender', this, function () {
          this.$("div.uploadBox div.bloco-placeholder:visible:last").click();
        });
      },

      finishedUploading: function finishedUploading(record, uploadedUrl) {
        record.set(this.urlField, uploadedUrl);
        _ember["default"].get(this, "finished-uploading")(record);
      }
    }
  });
});