define("juridico-sengerj/templates/components/panels/juridico/panel-show-valores", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 11,
              "column": 0
            }
          },
          "moduleName": "juridico-sengerj/templates/components/panels/juridico/panel-show-valores.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "juridico/form-valor", [], ["valor", ["subexpr", "@mut", [["get", "valor", ["loc", [null, [10, 30], [10, 35]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [10, 2], [10, 37]]], 0, 0]],
        locals: ["valor"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 12,
            "column": 0
          }
        },
        "moduleName": "juridico-sengerj/templates/components/panels/juridico/panel-show-valores.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "panels/panel-with-header-tabs", [], ["classNames", ["subexpr", "concat", ["col-md-6 ", ["get", "panelClasses", ["loc", [null, [2, 33], [2, 45]]], 0, 0, 0, 0]], [], ["loc", [null, [2, 13], [2, 46]]], 0, 0], "color", ["subexpr", "@mut", [["get", "color", ["loc", [null, [3, 8], [3, 13]]], 0, 0, 0, 0]], [], [], 0, 0], "records", ["subexpr", "@mut", [["get", "valores", ["loc", [null, [4, 10], [4, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "title", "Valor por Demanda", "add-new-tab", ["subexpr", "action", ["addValor"], [], ["loc", [null, [6, 14], [6, 33]]], 0, 0], "remove-tab", ["subexpr", "action", ["removeValor"], [], ["loc", [null, [7, 13], [7, 35]]], 0, 0], "tabs", ["subexpr", "@mut", [["get", "valorTabs", ["loc", [null, [8, 7], [8, 16]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [1, 0], [11, 34]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});