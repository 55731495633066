define('juridico-sengerj/models/profissional-unidade', ['exports', 'juridico-sengerj/models/abstract-model', 'ember-data', 'ember-cp-validations'], function (exports, _juridicoSengerjModelsAbstractModel, _emberData, _emberCpValidations) {

	var Validations = (0, _emberCpValidations.buildValidations)({
		dataAdmissao: [(0, _emberCpValidations.validator)('presence', true)],
		dataDesligamento: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('compare', {
			greaterThan: 'dataAdmissao',
			message: 'é necessário ter a maior que a data de admissão'
		})],
		unidade: [(0, _emberCpValidations.validator)('presence', true)]
	});

	exports['default'] = _juridicoSengerjModelsAbstractModel['default'].extend(Validations, {
		motivo: _emberData['default'].attr('string'),
		matriculaEmpresa: _emberData['default'].attr('string'),
		dataAdmissao: _emberData['default'].attr('date'),
		dataDesligamento: _emberData['default'].attr('date'),
		unidade: _emberData['default'].belongsTo(),
		profissional: _emberData['default'].belongsTo(),

		nomeFantasia: Ember.computed.alias("unidade.empresa.nomeFantasia")
	});
});