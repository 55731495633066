define("juridico-sengerj/transforms/pis", ["exports", "ember-data/transform"], function (exports, _emberDataTransform) {
  exports["default"] = _emberDataTransform["default"].extend({
    deserialize: function deserialize(pis) {
      if (pis != null) {
        return pis.slice(0, 3) + "." + pis.slice(3, 8) + "." + pis.slice(8, 10) + "-" + pis.slice(10, 11);
      }
    },

    serialize: function serialize(deserialized) {
      if (deserialized != null) {
        return deserialized.replace(/\D/g, "");
      }
    }
  });
});