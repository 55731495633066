define('juridico-sengerj/models/juridico/valor-por-demanda', ['exports', 'juridico-sengerj/models/abstract-model', 'ember-data', 'ember-cp-validations'], function (exports, _juridicoSengerjModelsAbstractModel, _emberData, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    valor: (0, _emberCpValidations.validator)('presence', true),
    parcela: (0, _emberCpValidations.validator)('presence', true)
  });

  exports['default'] = _juridicoSengerjModelsAbstractModel['default'].extend(Validations, {
    valor: _emberData['default'].attr('number'),
    parcela: _emberData['default'].attr("number"),
    percentualRepasseSenge: _emberData['default'].attr("number"),

    contratoEscritorio: _emberData['default'].belongsTo('juridico/contrato-escritorio')
  });
});