define('juridico-sengerj/models/usuario', ['exports', 'juridico-sengerj/models/abstract-model', 'ember-data', 'ember', 'juridico-sengerj/utils/has-permissao', 'ember-cp-validations'], function (exports, _juridicoSengerjModelsAbstractModel, _emberData, _ember, _juridicoSengerjUtilsHasPermissao, _emberCpValidations) {
  var isPresent = _ember['default'].isPresent;

  var Validations = (0, _emberCpValidations.buildValidations)({
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    })],

    escritorioResponsavel: [(0, _emberCpValidations.validator)(function () {
      return !this.get("model.isEscritorio") || isPresent(this.get('model.escritorioResponsavel.id'));
    }, {
      dependentKeys: ['isEscritorio']
    })]
  });

  exports['default'] = _juridicoSengerjModelsAbstractModel['default'].extend(Validations, {
    email: _emberData['default'].attr('string'),
    nome: _emberData['default'].attr('string'),
    ativo: _emberData['default'].attr('boolean', { defaultValue: true }),
    novoSistema: _emberData['default'].attr('boolean', { defaultValue: true }),
    permissoes: _emberData['default'].attr('enum-array', { defaultValue: _ember['default'].A() }),
    ultimaVezLogado: _emberData['default'].attr('date'),
    updatedAt: _emberData['default'].attr('date'),

    pessoa: _emberData['default'].belongsTo(),
    advogado: _emberData['default'].belongsTo('juridico/advogado'),
    escritorioResponsavel: _emberData['default'].belongsTo('juridico/escritorio'),
    usuarioEmpresas: _emberData['default'].hasMany(),

    isAdvogado: (0, _juridicoSengerjUtilsHasPermissao['default'])("advogado"),

    isAdmin: (0, _juridicoSengerjUtilsHasPermissao['default'])("admin"),

    isEscritorio: (0, _juridicoSengerjUtilsHasPermissao['default'])("escritorio"),

    isFuncionarioEmpresa: (0, _juridicoSengerjUtilsHasPermissao['default'])("funcionario_empresa"),

    isFuncionarioJuridico: (0, _juridicoSengerjUtilsHasPermissao['default'])("funcionario_juridico"),

    isPessoa: (0, _juridicoSengerjUtilsHasPermissao['default'])("pessoa"),

    isAdvogadoPleno: (0, _juridicoSengerjUtilsHasPermissao['default'])("advogado_pleno"),

    isDiretor: (0, _juridicoSengerjUtilsHasPermissao['default'])("diretor"),

    isAdminJuridico: (0, _juridicoSengerjUtilsHasPermissao['default'])("admin_juridico"),

    isGerenteJuridico: (0, _juridicoSengerjUtilsHasPermissao['default'])("gerente_juridico"),

    isAdmins: _ember['default'].computed.or("isAdminJuridico", "isAdmin"),

    isSocio: _ember['default'].computed.and("socio", "isPessoa"),

    isInternoSenge: _ember['default'].computed.or("isFuncionarioJuridico", "isAdmins", "isGerenteJuridico", "isDiretor"),

    escritorio: _ember['default'].computed.alias("advogado.escritorio"),

    empresas: _ember['default'].computed.or("usuarioEmpresas.empresas"),

    socio: _ember['default'].computed.alias("pessoa.profissional.socio")
  });
});