define('juridico-sengerj/models/financeiro/beneficiario-pessoa-juridica', ['exports', 'juridico-sengerj/models/abstract-model', 'ember-data', 'ember-cp-validations'], function (exports, _juridicoSengerjModelsAbstractModel, _emberData, _emberCpValidations) {

	var Validations = (0, _emberCpValidations.buildValidations)({});

	exports['default'] = _juridicoSengerjModelsAbstractModel['default'].extend(Validations, {
		razaoSocial: _emberData['default'].attr('string'),
		nomeFantasia: _emberData['default'].attr('string'),
		cnpj: _emberData['default'].attr('cnpj'),

		emails: _emberData['default'].hasMany(),
		telefones: _emberData['default'].hasMany()
	});
});