define('juridico-sengerj/components/route-components/form-pasta/reclamantes', ['exports', 'ember', 'juridico-sengerj/utils/pdf-header-pasta-info'], function (exports, _ember, _juridicoSengerjUtilsPdfHeaderPastaInfo) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),
    reclamantes: [],
    pasta: null,
    _filterReclamantes: {},
    _isModalVisible: false,
    _isModalAdicionarReclamantesFiltroVisible: false,
    _isModalRemoverReclamantesFiltroVisible: false,

    actions: {
      onReclamanteCreate: function onReclamanteCreate(reclamante) {
        if (this.get("pasta.isNew")) {
          this.get("pasta.reclamantes").pushObject(reclamante);
        } else {
          this.get("pasta").reload();
          this.notifyPropertyChange("_filterReclamantes");
        }
      },

      updatePasta: function updatePasta() {
        if (!this.get("pasta.isNew")) {
          this.get("pasta").reload();
        }
      },

      applyFilters: function applyFilters(filters) {
        this.set("_filterReclamantes", filters);
      },

      pastaInfoHeader: function pastaInfoHeader(doc, totalReclamantes) {
        (0, _juridicoSengerjUtilsPdfHeaderPastaInfo['default'])(doc, this.get("pasta"), totalReclamantes);
      }
    },

    _filterReclamantesWithPastaId: _ember['default'].computed("_filterReclamantes", "pasta.id", function () {
      return Object.assign({ include: 'pessoa.profissional', naoSecundarios: true }, this._filterReclamantes, { pastaId: this.get("pasta.id") });
    })
  });
});