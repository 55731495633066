define('juridico-sengerj/initializers/ember-i18n-cp-validations', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize() {
    // intentionally left blank to not break upgrade path
  }

  exports['default'] = {
    name: 'ember-i18n-cp-validations',
    initialize: initialize
  };
});