define('juridico-sengerj/components/editable-tags', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var isPresent = _ember['default'].isPresent;
  exports['default'] = _ember['default'].Component.extend({
    newTagName: '',
    tags: [],
    onTagAdd: function onTagAdd() {},
    onFocusOut: function onFocusOut() {},
    onTagRemove: function onTagRemove() {},

    click: function click() {
      this.$("input").focus();
    },

    actions: {
      addTag: function addTag() {
        get(this, "onFocusOut")();
        this._addTag();
      },

      removeTag: function removeTag(tag) {
        get(this, "onTagRemove")(tag);
      }
    },

    _addTag: function _addTag() {
      if (isPresent(this.newTagName)) {
        get(this, "onTagAdd")(this.newTagName);
        this.set("newTagName", "");
      }
    }
  });
});