define('juridico-sengerj/models/juridico/planilha-pagamento', ['exports', 'juridico-sengerj/models/abstract-model', 'ember-data', 'ember-cp-validations'], function (exports, _juridicoSengerjModelsAbstractModel, _emberData, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    arquivoUrl: (0, _emberCpValidations.validator)('presence', true),
    infoFinanceiro: (0, _emberCpValidations.validator)('presence', true)
  });

  exports['default'] = _juridicoSengerjModelsAbstractModel['default'].extend(Validations, {
    alertas: _emberData['default'].attr("string"),
    impedimentos: _emberData['default'].attr("string"),
    status: _emberData['default'].attr('enum'),
    arquivoUrl: _emberData['default'].attr("string"),

    infoFinanceiro: _emberData['default'].belongsTo('juridico/info-financeiro')
  });
});