define("juridico-sengerj/components/masked-input", ["exports", "ember-text-mask/components/masked-input"], function (exports, _emberTextMaskComponentsMaskedInput) {
  exports["default"] = _emberTextMaskComponentsMaskedInput["default"].extend({
    classNames: ["masked-input"],
    guide: false,
    "input-mask": null,

    init: function init() {
      if (this.get("input-mask")) {
        this.set("mask", this.get("input-mask").split("").map(function (char) {
          switch (char) {
            case "9":
              return (/\d/
              );
            default:
              return char;
          }
        }));
      }

      this._super.apply(this, arguments);
    }
  });
});