define('juridico-sengerj/models/juridico/motivo-afastamento', ['exports', 'juridico-sengerj/models/abstract-model', 'ember-data', 'ember', 'ember-cp-validations'], function (exports, _juridicoSengerjModelsAbstractModel, _emberData, _ember, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    motivo: (0, _emberCpValidations.validator)('presence', true),
    dataAfastamento: (0, _emberCpValidations.validator)('presence', true),
    arquivoUrl: (0, _emberCpValidations.validator)('presence', true)
  });

  exports['default'] = _juridicoSengerjModelsAbstractModel['default'].extend(Validations, {
    motivo: _emberData['default'].attr('string'),
    dataAfastamento: _emberData['default'].attr('date'),
    arquivoUrl: _emberData['default'].attr('string'),
    reclamante: _emberData['default'].belongsTo('juridico/reclamante')
  });
});