define('juridico-sengerj/components/input-percentage', ['exports', 'ember', 'npm:text-mask-addons', 'juridico-sengerj/components/masked-input'], function (exports, _ember, _npmTextMaskAddons, _juridicoSengerjComponentsMaskedInput) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports['default'] = _juridicoSengerjComponentsMaskedInput['default'].extend({
    number: 0,
    align: "left",
    classNames: ["form-control"],
    mask: _npmTextMaskAddons['default'].createNumberMask({
      prefix: '',
      suffix: ' %',
      decimalSymbol: ',',
      thousandsSeparatorSymbol: true,
      allowDecimal: true,
      integerLimit: 3
    }),

    value: _ember['default'].computed.oneWay("_number"),

    _number: _ember['default'].computed("number", function () {
      if (this.number) {
        return this.number.toString().replace(".", ",");
      }
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.$().css({ "text-align": this.align });
    },

    keyUp: function keyUp() {
      var _$$val$split = this.$().val().split(",");

      var _$$val$split2 = _slicedToArray(_$$val$split, 2);

      var round = _$$val$split2[0];
      var _$$val$split2$1 = _$$val$split2[1];
      var decimal = _$$val$split2$1 === undefined ? "0" : _$$val$split2$1;

      var number = parseFloat(round.replace(/\D/g, "")) + parseFloat(decimal) / 100;

      this.set("number", number);
    }
  });
});