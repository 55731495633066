define('juridico-sengerj/app', ['exports', 'ember', 'sweetalert2', 'juridico-sengerj/resolver', 'ember-load-initializers', 'juridico-sengerj/config/environment', 'juridico-sengerj/models/custom-inflector-rules'], function (exports, _ember, _sweetalert2, _juridicoSengerjResolver, _emberLoadInitializers, _juridicoSengerjConfigEnvironment, _juridicoSengerjModelsCustomInflectorRules) {

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _juridicoSengerjConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _juridicoSengerjConfigEnvironment['default'].podModulePrefix,
    Resolver: _juridicoSengerjResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _juridicoSengerjConfigEnvironment['default'].modulePrefix);

  _sweetalert2['default'].setDefaults({
    target: 'body:last-child',
    cancelButtonText: 'Cancelar',
    confirmButtonText: 'Confirmar',
    showCancelButton: true
  });

  Promise = _ember['default'].RSVP.Promise;

  exports['default'] = App;
});