define('juridico-sengerj/models/juridico/restaurar-pasta', ['exports', 'juridico-sengerj/models/abstract-model', 'ember-data', 'ember-cp-validations'], function (exports, _juridicoSengerjModelsAbstractModel, _emberData, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    motivo: (0, _emberCpValidations.validator)('presence', true)
  });

  exports['default'] = _juridicoSengerjModelsAbstractModel['default'].extend(Validations, {
    motivo: _emberData['default'].attr('string'),
    pasta: _emberData['default'].belongsTo('juridico/pasta')
  });
});