define("juridico-sengerj/templates/dashboard/contrato-escritorios/index", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 6,
                "column": 8
              },
              "end": {
                "line": 8,
                "column": 8
              }
            },
            "moduleName": "juridico-sengerj/templates/dashboard/contrato-escritorios/index.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          Adicionar novo Contrato\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 16,
                      "column": 14
                    },
                    "end": {
                      "line": 18,
                      "column": 14
                    }
                  },
                  "moduleName": "juridico-sengerj/templates/dashboard/contrato-escritorios/index.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("td");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                  return morphs;
                },
                statements: [["content", "contrato.escritorio.beneficiarioPessoaJuridica.razaoSocial", ["loc", [null, [17, 20], [17, 82]]], 0, 0, 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 18,
                      "column": 14
                    },
                    "end": {
                      "line": 20,
                      "column": 14
                    }
                  },
                  "moduleName": "juridico-sengerj/templates/dashboard/contrato-escritorios/index.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("td");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                  return morphs;
                },
                statements: [["content", "contrato.escritorioContabil.beneficiarioPessoaJuridica.razaoSocial", ["loc", [null, [19, 20], [19, 90]]], 0, 0, 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child2 = (function () {
              var child0 = (function () {
                var child0 = (function () {
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 27,
                          "column": 18
                        },
                        "end": {
                          "line": 29,
                          "column": 18
                        }
                      },
                      "moduleName": "juridico-sengerj/templates/dashboard/contrato-escritorios/index.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                    ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      var el2 = dom.createTextNode(" ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("i");
                      dom.setAttribute(el2, "class", "fa fa-lg fa-eye");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode(" ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode(" Editar\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() {
                      return [];
                    },
                    statements: [],
                    locals: [],
                    templates: []
                  };
                })();
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 26,
                        "column": 16
                      },
                      "end": {
                        "line": 30,
                        "column": 16
                      }
                    },
                    "moduleName": "juridico-sengerj/templates/dashboard/contrato-escritorios/index.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["block", "link-to", ["dashboard.contrato-escritorios.contrato-escritorio", ["get", "contrato", ["loc", [null, [27, 82], [27, 90]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [27, 18], [29, 30]]]]],
                  locals: [],
                  templates: [child0]
                };
              })();
              var child1 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 32,
                        "column": 16
                      },
                      "end": {
                        "line": 36,
                        "column": 16
                      }
                    },
                    "moduleName": "juridico-sengerj/templates/dashboard/contrato-escritorios/index.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1, "class", "bg-danger");
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    var el3 = dom.createTextNode(" ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("i");
                    dom.setAttribute(el3, "class", "fa fa-lg fa-times-circle");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode(" ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode(" Remover\n                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element1 = dom.childAt(fragment, [1]);
                    var morphs = new Array(1);
                    morphs[0] = dom.createElementMorph(element1);
                    return morphs;
                  },
                  statements: [["element", "action", [["subexpr", "invoke", ["destroyRecord", ["get", "contrato", ["loc", [null, [33, 72], [33, 80]]], 0, 0, 0, 0]], [], ["loc", [null, [33, 48], [33, 81]]], 0, 0]], [], ["loc", [null, [33, 39], [33, 84]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 25,
                      "column": 14
                    },
                    "end": {
                      "line": 37,
                      "column": 14
                    }
                  },
                  "moduleName": "juridico-sengerj/templates/dashboard/contrato-escritorios/index.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "context-menu-item", [], [], 0, null, ["loc", [null, [26, 16], [30, 38]]]], ["block", "context-menu-item", [], [], 1, null, ["loc", [null, [32, 16], [36, 38]]]]],
                locals: [],
                templates: [child0, child1]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 14,
                    "column": 12
                  },
                  "end": {
                    "line": 38,
                    "column": 12
                  }
                },
                "moduleName": "juridico-sengerj/templates/dashboard/contrato-escritorios/index.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("td");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("td");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("td");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" - ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("td");
                var el2 = dom.createElement("a");
                dom.setAttribute(el2, "target", "_blank");
                var el3 = dom.createTextNode("Acessar contrato");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element2 = dom.childAt(fragment, [7]);
                var element3 = dom.childAt(fragment, [9, 0]);
                var morphs = new Array(7);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5]), 0, 0);
                morphs[3] = dom.createMorphAt(element2, 0, 0);
                morphs[4] = dom.createMorphAt(element2, 2, 2);
                morphs[5] = dom.createAttrMorph(element3, 'href');
                morphs[6] = dom.createMorphAt(fragment, 11, 11, contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["content", "contrato.id", ["loc", [null, [15, 18], [15, 33]]], 0, 0, 0, 0], ["block", "if", [["subexpr", "eq", [["get", "contrato.tipo.value", ["loc", [null, [16, 24], [16, 43]]], 0, 0, 0, 0], "advocacia"], [], ["loc", [null, [16, 20], [16, 56]]], 0, 0]], [], 0, 1, ["loc", [null, [16, 14], [20, 21]]]], ["content", "contrato.tipo.text", ["loc", [null, [21, 18], [21, 42]]], 0, 0, 0, 0], ["inline", "format-date", [["get", "contrato.dataInicioVigencia", ["loc", [null, [22, 32], [22, 59]]], 0, 0, 0, 0]], [], ["loc", [null, [22, 18], [22, 61]]], 0, 0], ["inline", "format-date", [["get", "contrato.dataFimVigencia", ["loc", [null, [22, 78], [22, 102]]], 0, 0, 0, 0]], [], ["loc", [null, [22, 64], [22, 104]]], 0, 0], ["attribute", "href", ["get", "contrato.copiaContratoAssinadoUrl", ["loc", [null, [23, 30], [23, 63]]], 0, 0, 0, 0], 0, 0, 0, 0], ["block", "context-menu", [], ["isInsideTable", true], 2, null, ["loc", [null, [25, 14], [37, 31]]]]],
              locals: [],
              templates: [child0, child1, child2]
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 44,
                      "column": 14
                    },
                    "end": {
                      "line": 51,
                      "column": 14
                    }
                  },
                  "moduleName": "juridico-sengerj/templates/dashboard/contrato-escritorios/index.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("tbody");
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("tr");
                  var el3 = dom.createTextNode("\n                    ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("td");
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                    ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("td");
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("x");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                  ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element0 = dom.childAt(fragment, [1, 1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
                  morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
                  return morphs;
                },
                statements: [["inline", "format-currency", [["get", "valor.valor", ["loc", [null, [47, 42], [47, 53]]], 0, 0, 0, 0]], [], ["loc", [null, [47, 24], [47, 55]]], 0, 0], ["content", "valor.parcela", ["loc", [null, [48, 24], [48, 41]]], 0, 0, 0, 0]],
                locals: ["valor"],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 40,
                    "column": 12
                  },
                  "end": {
                    "line": 52,
                    "column": 12
                  }
                },
                "moduleName": "juridico-sengerj/templates/dashboard/contrato-escritorios/index.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                var el2 = dom.createTextNode("Percentual Honorário Sucumbência: ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("b");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                var el2 = dom.createTextNode("Valor Fixo Mensal:                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("b");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("hr");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 0, 0);
                morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3, 1]), 0, 0);
                morphs[2] = dom.createMorphAt(fragment, 7, 7, contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["inline", "format-percentage", [["get", "contrato.percentualHonorarioSucumbencia", ["loc", [null, [41, 75], [41, 114]]], 0, 0, 0, 0]], [], ["loc", [null, [41, 54], [41, 117]]], 0, 0], ["inline", "format-currency", [["get", "contrato.valorFixoMensal", ["loc", [null, [42, 73], [42, 97]]], 0, 0, 0, 0]], [], ["loc", [null, [42, 54], [42, 100]]], 0, 0], ["block", "elements-table", [], ["hasActions", false, "columnsTitle", ["subexpr", "array", ["Valor por Demanda", "Parcela(s)"], [], ["loc", [null, [44, 62], [44, 102]]], 0, 0], "records", ["subexpr", "@mut", [["get", "contrato.valorPorDemandas", ["loc", [null, [44, 111], [44, 136]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [44, 14], [51, 33]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 13,
                  "column": 10
                },
                "end": {
                  "line": 53,
                  "column": 10
                }
              },
              "moduleName": "juridico-sengerj/templates/dashboard/contrato-escritorios/index.hbs"
            },
            isEmpty: false,
            arity: 2,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "component", [["get", "row", ["loc", [null, [14, 25], [14, 28]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [14, 12], [38, 26]]]], ["block", "component", [["get", "hideableRow", ["loc", [null, [40, 25], [40, 36]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [40, 12], [52, 26]]]]],
            locals: ["row", "hideableRow"],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 8
              },
              "end": {
                "line": 54,
                "column": 8
              }
            },
            "moduleName": "juridico-sengerj/templates/dashboard/contrato-escritorios/index.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "toggleable-row-table", [], ["columns", ["subexpr", "@mut", [["get", "columnsTitle", ["loc", [null, [13, 42], [13, 54]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [13, 10], [53, 35]]]]],
          locals: ["contrato"],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 59,
              "column": 0
            }
          },
          "moduleName": "juridico-sengerj/templates/dashboard/contrato-escritorios/index.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "col-xs-12");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "panel");
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "panel-body");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("hr");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1, 1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element4, 1, 1);
          morphs[1] = dom.createMorphAt(element4, 5, 5);
          return morphs;
        },
        statements: [["block", "link-to", ["dashboard.contrato-escritorios.novo"], ["classNames", "btn btn-purple btn-labeled fa fa-plus"], 0, null, ["loc", [null, [6, 8], [8, 20]]]], ["block", "elements-table", [], ["hasActions", true, "columnsTitle", ["subexpr", "array", ["ID", "Escritório", "Tipo", "Data de Vigência", "Cópia do Contrato"], [], ["loc", [null, [12, 55], [12, 126]]], 0, 0], "records", ["subexpr", "@mut", [["get", "model", ["loc", [null, [12, 135], [12, 140]]], 0, 0, 0, 0]], [], [], 0, 0]], 1, null, ["loc", [null, [12, 8], [54, 27]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 60,
            "column": 0
          }
        },
        "moduleName": "juridico-sengerj/templates/dashboard/contrato-escritorios/index.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "dashboard-container", [], ["page-header", "Contratos"], 0, null, ["loc", [null, [1, 0], [59, 24]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});