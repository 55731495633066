define('juridico-sengerj/components/filters/filter-select', ['exports', 'ember', 'juridico-sengerj/mixins/components/filter', 'juridico-sengerj/components/select-model'], function (exports, _ember, _juridicoSengerjMixinsComponentsFilter, _juridicoSengerjComponentsSelectModel) {
  var isPresent = _ember['default'].isPresent;
  var computed = _ember['default'].computed;
  exports['default'] = _juridicoSengerjComponentsSelectModel['default'].extend(_juridicoSengerjMixinsComponentsFilter['default'], {
    valueField: 'id',

    setSelected: function setSelected(selectedRecord) {
      this._super(selectedRecord);
      this.saveFilterValue(selectedRecord.get(this.valueField));
    },

    clearSelect: function clearSelect() {
      this._super();
      this.clearFilterValue();
    },

    valueToShow: computed("value", function () {
      var value = this.get("value");

      if (isPresent(value)) {
        return value.get(this.label);
      }
    }),

    value: computed("records.content", "filterValue", function () {
      var filterValue = this.get("filterValue");

      if (isPresent(this.get("records.content")) && isPresent(filterValue)) {
        return this.get("records").findBy(this.valueField, filterValue);
      }
    })
  });
});