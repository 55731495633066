define('juridico-sengerj/models/juridico/escritorio', ['exports', 'juridico-sengerj/models/abstract-model', 'ember-data', 'ember', 'ember-cp-validations'], function (exports, _juridicoSengerjModelsAbstractModel, _emberData, _ember, _emberCpValidations) {

	var Validations = (0, _emberCpValidations.buildValidations)({
		cnpj: [(0, _emberCpValidations.validator)('presence', true)],
		tipo: [(0, _emberCpValidations.validator)('presence', true)]
	});

	exports['default'] = _juridicoSengerjModelsAbstractModel['default'].extend(Validations, {
		cnpj: _emberData['default'].attr("number"),
		createdAt: _emberData['default'].attr(),
		tipo: _emberData['default'].attr("enum"),
		empresa: _emberData['default'].belongsTo(),
		beneficiarioPessoaJuridica: _emberData['default'].belongsTo('financeiro/beneficiario-pessoa-juridica'),

		emails: _emberData['default'].hasMany(),
		telefones: _emberData['default'].hasMany(),
		enderecos: _emberData['default'].hasMany(),

		advogados: _emberData['default'].hasMany("juridico/advogados"),
		responsaveis: _emberData['default'].hasMany("usuarios"),

		nomeFantasia: _ember['default'].computed.alias("beneficiarioPessoaJuridica.nomeFantasia")
	});
});