define('juridico-sengerj/components/tables/table-ocorrencias', ['exports', 'ember', 'juridico-sengerj/config/environment', 'juridico-sengerj/utils/format/date/full', 'juridico-sengerj/helpers/format-currency', 'juridico-sengerj/helpers/format-date'], function (exports, _ember, _juridicoSengerjConfigEnvironment, _juridicoSengerjUtilsFormatDateFull, _juridicoSengerjHelpersFormatCurrency, _juridicoSengerjHelpersFormatDate) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var $ = _ember['default'].$;
  var computed = _ember['default'].computed;
  var htmlSafe = _ember['default'].String.htmlSafe;
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),

    columns: ["Tipo de Ocorrência", "Data"],

    possibleGroupsToSend: {
      "Advogado": "advogado",
      "Funcionário Jurídico": "funcionario_juridico",
      "Escritório": "escritorio",
      "Reclamantes": "reclamantes",
      "Reclamantes Secundários": "reclamantes_secundarios",
      "Terceiros Interessados": "reclamantes_terceiros",
      "Reclamantes Afastados": "reclamantes_afastados"
    },

    remoteSave: true,

    pasta: null,

    "on-remove": function onRemove() {},
    "on-restore": function onRestore() {},

    _isSendingEmail: false,

    _messageAdvogado: null,

    _choosenOcorrencia: null,

    _isOpenModalMessageAdvogado: false,

    _isOpenModalGroupsToSend: false,

    _groupsToSend: [],

    _filterReclamantes: {},

    _onlyReclamantesIdToSend: [],

    _exceptReclamantesIdToSen: [],

    _choosenAdvogado: computed.alias("_choosenOcorrencia.pasta.advogado"),

    possibleGroupsToSendValue: computed("possibleGroupsToSend", function () {
      var _this = this;

      return Object.keys(this.possibleGroupsToSend).map(function (key) {
        return _this.possibleGroupsToSend[key];
      });
    }),

    possibleGroupsToSendText: computed("possibleGroupsToSend", "_choosenOcorrencia.gruposNotificados.[]", "_choosenOcorrencia.gruposNotificados.isFulfilled", function () {
      var _this2 = this;

      var gruposNotificados = this.get("_choosenOcorrencia.gruposNotificados");

      return Object.keys(this.get("possibleGroupsToSend")).map(function (group) {
        var grupoNotificado = gruposNotificados.findBy("tipo.value", _this2.possibleGroupsToSend[group]);

        if (grupoNotificado) {
          return htmlSafe(group + ' <br> <b>Notificado em ' + (0, _juridicoSengerjHelpersFormatDate.formatDate)([grupoNotificado.get("createdAt")]) + '</b>');
        } else {
          return group;
        }
      });
    }),

    actions: {
      reloadOcorrencia: function reloadOcorrencia(ocorrencia) {
        ocorrencia.reload();
      },

      saveDocumento: function saveDocumento(documento) {
        documento.save();
      },

      addDocumento: function addDocumento(ocorrencia) {
        ocorrencia.get("documentos").createRecord({ documentavel: ocorrencia });
      },

      saveOcorrencia: function saveOcorrencia(ocorrencia) {
        this._saveOcorrencia(ocorrencia);
      },

      restoreOcorrencia: function restoreOcorrencia(ocorrencia) {
        var pasta;
        return regeneratorRuntime.async(function restoreOcorrencia$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              ocorrencia.set("excluido", false);

              context$1$0.next = 3;
              return regeneratorRuntime.awrap(ocorrencia.save());

            case 3:

              _ember['default'].$.niftyNoty({
                type: 'success',
                icon: 'fa fa-thumbs-up fa-2x',
                container: 'floating',
                title: 'Ocorrência foi restaurada',
                timer: 4000
              });

              context$1$0.next = 6;
              return regeneratorRuntime.awrap(ocorrencia.get("pasta"));

            case 6:
              pasta = context$1$0.sent;
              context$1$0.next = 9;
              return regeneratorRuntime.awrap(pasta.reload());

            case 9:
              context$1$0.next = 11;
              return regeneratorRuntime.awrap(_ember['default'].get(this, "on-restore")(ocorrencia));

            case 11:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      },

      removeOcorrencia: function removeOcorrencia(ocorrencia) {
        var pasta;
        return regeneratorRuntime.async(function removeOcorrencia$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              ocorrencia.set("excluido", true);

              context$1$0.next = 3;
              return regeneratorRuntime.awrap(ocorrencia.save());

            case 3:

              _ember['default'].$.niftyNoty({
                type: 'success',
                icon: 'fa fa-thumbs-up fa-2x',
                container: 'floating',
                title: 'Ocorrência foi excluída',
                timer: 4000
              });

              context$1$0.next = 6;
              return regeneratorRuntime.awrap(ocorrencia.get("pasta"));

            case 6:
              pasta = context$1$0.sent;
              context$1$0.next = 9;
              return regeneratorRuntime.awrap(pasta.reload());

            case 9:
              context$1$0.next = 11;
              return regeneratorRuntime.awrap(_ember['default'].get(this, "on-remove")(ocorrencia));

            case 11:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      },

      changeGroupToSend: function changeGroupToSend(groups) {
        this.set("_groupsToSend", groups);
      },

      applyFilters: function applyFilters(filters) {
        this.set("_filterReclamantes", Object.assign({}, filters, { pastaId: this.get("_choosenOcorrencia.pasta.id") }));
      },

      changeReclamantesToSend: function changeReclamantesToSend(selectedRecords, unSelectedRecords) {
        this.setProperties({
          _onlyReclamantesIdToSend: selectedRecords.mapBy("id"),
          _exceptReclamantesIdToSend: unSelectedRecords.mapBy("id")
        });
      },

      sendOcorrencia: function sendOcorrencia(ocorrencia, nota) {
        var grupos, data, adapter;
        return regeneratorRuntime.async(function sendOcorrencia$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              this.toggleProperty("_isSendingEmail");

              grupos = this.get("_groupsToSend");
              data = {
                grupos: grupos,
                only_reclamantes_id: this._onlyReclamantesIdToSend,
                except_reclamantes_id: this._exceptReclamantesIdToSend
              };
              adapter = ocorrencia.store.adapterFor();
              context$1$0.next = 6;
              return regeneratorRuntime.awrap(adapter.ajax(adapter.host + '/juridico/ocorrencias/' + ocorrencia.id + '/enviar_notificacao_nota/' + nota.id, 'POST', { data: data }));

            case 6:
              context$1$0.next = 8;
              return regeneratorRuntime.awrap(ocorrencia.reload());

            case 8:

              this.setProperties({
                _groupsToSend: [],
                _isSendingEmail: false,
                _isOpenModalGroupsToSend: false,
                _onlyReclamantesIdToSend: [],
                _exceptReclamantesIdToSend: []
              });

            case 9:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      },

      chooseOcorrenciaAndNotaOpenModal: function chooseOcorrenciaAndNotaOpenModal(_choosenOcorrencia, _choosenNota, modalName) {
        var _setProperties;

        this.setProperties((_setProperties = {}, _defineProperty(_setProperties, modalName, true), _defineProperty(_setProperties, '_filterReclamantes', { pastaId: _choosenOcorrencia.get("pasta.id") }), _defineProperty(_setProperties, '_choosenOcorrencia', _choosenOcorrencia), _defineProperty(_setProperties, '_choosenNota', _choosenNota), _setProperties));
      },

      sendEmailAdvogadoToEdit: function sendEmailAdvogadoToEdit() {
        var usuario, nota;
        return regeneratorRuntime.async(function sendEmailAdvogadoToEdit$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              this.toggleProperty("_isSendingEmail");

              context$1$0.next = 3;
              return regeneratorRuntime.awrap(this.get("_choosenAdvogado.usuario"));

            case 3:
              usuario = context$1$0.sent;
              nota = this.get("_choosenNota");

              nota.set("usuarioPermitidoEdicao", usuario);

              context$1$0.next = 8;
              return regeneratorRuntime.awrap(nota.save({ force: true }));

            case 8:
              context$1$0.next = 10;
              return regeneratorRuntime.awrap(this._sendEmailToAdvogado("notificacao_edicao"));

            case 10:

              this.setProperties({
                _messageAdvogado: null,
                _isSendingEmail: false,
                _isOpenModalMessageAdvogado: false
              });

            case 11:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      }
    },

    _sendEmailToAdvogado: function _sendEmailToAdvogado(action) {
      var id = this.get("_choosenOcorrencia.id");
      var mensagem = this.get("_messageAdvogado");

      var adapter = this.get("_choosenOcorrencia.store").adapterFor();
      return adapter.ajax(adapter.host + '/juridico/ocorrencias/' + id + '/enviar_' + action + '_advogado', 'POST', { data: { mensagem: mensagem } });
    },

    _saveOcorrencia: function _saveOcorrencia(ocorrencia) {
      if (this.remoteSave) {
        ocorrencia.save();
      }
    }
  });
});