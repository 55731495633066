define("juridico-sengerj/components/super-simple-calendar", ["exports", "ember"], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; })();

  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var get = _ember["default"].get;
  var computed = _ember["default"].computed;
  var isPresent = _ember["default"].isPresent;
  var isBlank = _ember["default"].isBlank;
  var w = _ember["default"].String.w;

  var _timeToMinutes = function _timeToMinutes(time) {
    if (isPresent(time)) {
      var _time$replace$split = time.replace(/[a-z]/g, "").split(":");

      var _time$replace$split2 = _slicedToArray(_time$replace$split, 2);

      var timeHours = _time$replace$split2[0];
      var timeMinutes = _time$replace$split2[1];

      return parseInt(timeHours) * 60 + parseInt(timeMinutes);
    } else {
      return 0;
    }
  };

  exports["default"] = _ember["default"].Component.extend({
    monthNames: w("Janeiro Fevereiro Março Abril Maio Junho Julho Agosto Setembro Outubro Novembro Dezembro"),
    weekNames: w("Dom Seg Ter Qua Qui Sex Sáb"),
    startTime: "9:00",
    endTime: "20:30",
    intervalMinutes: "30",
    breakTime: null,
    schedulesMade: [],
    blockedDays: [],
    chosenDate: null,
    isLoadingDates: false,
    "on-date-choosen": function onDateChoosen() {},
    "on-change-month": function onChangeMonth() {},
    _showDaysMonth: true,
    _currentDate: new Date(),
    _chosenDay: null,

    actions: {
      nextMonth: function nextMonth() {
        this._changeCalendarMonth(1);
      },
      previusMonth: function previusMonth() {
        this._changeCalendarMonth(-1);
      },
      backToMonths: function backToMonths() {
        this.set("_showDaysMonth", true);
      },
      showChooseOptions: function showChooseOptions(day) {
        this.setProperties({
          _chosenDay: day,
          _showDaysMonth: false
        });
      },
      chooseDate: function chooseDate(_ref) {
        var date = _ref.date;
        var isBlocked = _ref.isBlocked;

        if (!isBlocked) {
          date.setSeconds(0);
          this.set("chosenDate", date);
          get(this, "on-date-choosen")(date);
        }
      }
    },

    _daysOptionsOnMonth: computed("_allSchedulesOnMonthByDay", function () {
      var _this = this;

      return this.get("_allSchedulesOnMonthByDay").map(function (_ref2) {
        var day = _ref2.day;
        var schedules = _ref2.schedules;

        if (day) {
          var reason = _this._getReasonDayNotAvailable(day, schedules);
          return { day: day, reason: reason, isAvailable: isBlank(reason) };
        }
      });
    }),

    _daysInMonth: computed("_currentDate", function () {
      var days = [].concat(_toConsumableArray(Array(this.get("_lastDayMonth") + 1).keys()));
      var offsetDays = [];
      days.shift();

      for (var i = 0; i < this.get("_firstDayWeekMonth"); i++) {
        offsetDays.push(null);
      }

      return offsetDays.concat(days);
    }),

    _lastDayMonth: computed("_currentDate", function () {
      var date = this._currentDate;
      return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    }),

    _firstDayWeekMonth: computed("_currentDate", function () {
      var date = this._currentDate;
      return new Date(date.getFullYear(), date.getMonth(), 1).getDay();
    }),

    _formatedTitle: computed("_currentDate", function () {
      return this.get("_monthName") + " " + this._currentDate.getFullYear();
    }),

    _monthName: computed("_currentDate", function () {
      return this.monthNames[this._currentDate.getMonth()];
    }),

    _startBreakTimeMinutes: computed("breakTime.startTime", function () {
      return _timeToMinutes(this.breakTime.startTime);
    }),

    _endBreakTimeMinutes: computed("breakTime.endTime", function () {
      return _timeToMinutes(this.breakTime.endTime);
    }),

    _schedulesOnChosenDay: computed("_chosenDay", "_allSchedulesOnMonthByDay", function () {
      return this._schedulesByDay(this.get("_chosenDay"));
    }),

    _allSchedulesOnMonthByDay: computed("blockedDays", "intervalMinutes", "_daysInMonth", "schedulesMade", function () {
      var _this2 = this;

      return this.get("_daysInMonth").map(function (day) {
        if (day && _this2.intervalMinutes) {
          var date = new Date(_this2.get("_currentDate"));
          date.setDate(day);
          date.setHours(1);
          var schedules = _this2._posibleSchedulesOnDay(date).map(function (schedule) {
            return _extends({
              day: schedule.getDate(),
              date: schedule,
              time: schedule.getTime()
            }, _this2._getMessageAndAvailabilityAboutDate(schedule));
          });

          return { day: day, schedules: schedules };
        } else {
          return { day: day, schedules: [{}] };
        }
      });
    }),

    _chosenDateTime: computed("chosenDate", function () {
      if (this.chosenDate) {
        return this.chosenDate.getTime();
      }
    }),

    _schedulesByDay: function _schedulesByDay(day) {
      return this.get("_allSchedulesOnMonthByDay").findBy('day', day).schedules;
    },

    _posibleSchedulesOnDay: function _posibleSchedulesOnDay() {
      var date = arguments.length <= 0 || arguments[0] === undefined ? new Date() : arguments[0];

      var startTimeMinutes = _timeToMinutes(this.startTime);
      var endTimeMinutes = _timeToMinutes(this.endTime);
      var interval = parseInt(this.intervalMinutes);
      var dates = [];

      for (var minutes = startTimeMinutes; minutes <= endTimeMinutes; minutes += interval) {
        dates.push(this._minutesToDate(date, minutes));
      }
      return dates;
    },

    _changeCalendarMonth: function _changeCalendarMonth(increment) {
      var currentCalendarDate = new Date(this.get("_currentDate"));
      var currentDate = new Date();

      currentCalendarDate.setDate(1);
      currentCalendarDate.setMonth(currentCalendarDate.getMonth() + increment);

      var newDate = this._isSameMonthYear(currentDate, currentCalendarDate) ? currentDate : currentCalendarDate;

      this.set("_currentDate", newDate);
      get(this, "on-change-month")(newDate);
    },

    _minutesToDate: function _minutesToDate(date, minutes) {
      var _date = new Date(date);
      _date.setHours(0, minutes, 0);
      return _date;
    },

    _dateToMinutes: function _dateToMinutes(date) {
      return date.getHours() * 60 + date.getMinutes();
    },

    _getReasonDayNotAvailable: function _getReasonDayNotAvailable(day, schedules) {
      var currentDate = new Date();
      var schedulesDate = new Date(this.get("_currentDate"));
      var blockedDay = this.blockedDays.find(function (blockedDay) {
        return blockedDay.day === day;
      });

      schedulesDate.setDate(day);
      schedulesDate.setHours(23, 59);

      if (currentDate >= schedulesDate) {
        return "Não é possível agendar antes da data atual";
      } else if (blockedDay) {
        return blockedDay.reason;
      } else if (schedules.every(function (schedule) {
        return schedule.isBlocked;
      })) {
        return "Todos os horários desse dia estão ocupados";
      }
    },

    _getMessageAndAvailabilityAboutDate: function _getMessageAndAvailabilityAboutDate(date) {
      var _this3 = this;

      var minutes = this._dateToMinutes(date);
      var currentDate = new Date();
      var scheduleMade = this.get("schedulesMade").find(function (schedule) {
        return _this3._isSameMonthYearDay(date, schedule.date) && _this3._dateToMinutes(schedule.date) === minutes;
      });

      if (currentDate > date) {
        return { isBlocked: true, message: 'Não é possível agendar um horário antes do horário atual' };
      } else if (this.breakTime && minutes <= this.get("_endBreakTimeMinutes") && minutes >= this.get("_startBreakTimeMinutes")) {
        return this.breakTime;
      } else if (scheduleMade) {
        return scheduleMade;
      } else {
        return { isBlocked: false, message: null };
      }
    },

    _isSameMonthYear: function _isSameMonthYear(date, otherDate) {
      return date.getMonth() === otherDate.getMonth() && date.getFullYear() === otherDate.getFullYear();
    },

    _isSameMonthYearDay: function _isSameMonthYearDay(date, otherDate) {
      return this._isSameMonthYear(date, otherDate) && date.getDate() === otherDate.getDate();
    },

    init: function init() {
      this._super.apply(this, arguments);
      var chosenDate = this.chosenDate;
      if (isPresent(chosenDate)) {
        this.setProperties({
          _currentDate: chosenDate,
          _chosenDay: chosenDate.getDate(),
          _showDaysMonth: false
        });
      }
    }
  });
});