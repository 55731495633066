define('juridico-sengerj/models/pessoa', ['exports', 'juridico-sengerj/models/abstract-model', 'ember-data', 'ember', 'ember-cp-validations'], function (exports, _juridicoSengerjModelsAbstractModel, _emberData, _ember, _emberCpValidations) {

	var Validations = (0, _emberCpValidations.buildValidations)({
		nome: (0, _emberCpValidations.validator)('presence', true),

		sexo: (0, _emberCpValidations.validator)('presence', {
			presence: true,
			disabled: _ember['default'].computed.and('model.notHasProfissional', 'model.notHasUsuario')
		}),

		cpf: (0, _emberCpValidations.validator)('presence', {
			presence: true,
			disabled: _ember['default'].computed.and('model.notHasProfissional', 'model.notHasUsuario')
		})
	});

	exports['default'] = _juridicoSengerjModelsAbstractModel['default'].extend(Validations, {
		nome: _emberData['default'].attr(),
		sexo: _emberData['default'].attr('enum'),
		dataNascimento: _emberData['default'].attr('date'),
		cpf: _emberData['default'].attr('cpf'),
		fotoUrl: _emberData['default'].attr('photo', { defaultValue: "/assets/images/av1.png" }),
		usuario: _emberData['default'].belongsTo(),
		profissional: _emberData['default'].belongsTo(),
		enderecos: _emberData['default'].hasMany(),
		telefones: _emberData['default'].hasMany(),
		emails: _emberData['default'].hasMany(),

		notHasProfissional: _ember['default'].computed('profissional', function () {
			return this.get('profissional.content') === null;
		}),

		notHasUsuario: _ember['default'].computed('usuario', function () {
			return this.get('usuario.content') === null;
		}),

		nomeComCPF: _ember['default'].computed('nome', 'cpf', function () {
			return this.get("nome") + ' (CPF: ' + this.get("cpf") + ')';
		}),

		saveAll: function saveAll() {
			var promises, _ref, emails, telefones, enderecos, profissional;

			return regeneratorRuntime.async(function saveAll$(context$1$0) {
				var _this = this;

				while (1) switch (context$1$0.prev = context$1$0.next) {
					case 0:
						promises = this.getProperties('emails', 'telefones', 'enderecos', 'profissional');
						context$1$0.next = 3;
						return regeneratorRuntime.awrap(_ember['default'].RSVP.hash(promises));

					case 3:
						_ref = context$1$0.sent;
						emails = _ref.emails;
						telefones = _ref.telefones;
						enderecos = _ref.enderecos;
						profissional = _ref.profissional;
						context$1$0.next = 10;
						return regeneratorRuntime.awrap(this.save());

					case 10:
						context$1$0.next = 12;
						return regeneratorRuntime.awrap(_ember['default'].RSVP.all([].concat(emails.map(function (e) {
							e.set("emailable", _this);
							return e.save();
						}), telefones.map(function (t) {
							t.set("telefonavel", _this);
							return t.save();
						}), enderecos.map(function (e) {
							e.set("enderecavel", _this);
							return e.save();
						}))));

					case 12:
						if (!profissional) {
							context$1$0.next = 15;
							break;
						}

						context$1$0.next = 15;
						return regeneratorRuntime.awrap(profissional.save());

					case 15:
					case 'end':
						return context$1$0.stop();
				}
			}, null, this);
		}
	});
});