define('juridico-sengerj/components/button-export-model', ['exports', 'ember', 'juridico-sengerj/templates/components/button-export-model'], function (exports, _ember, _juridicoSengerjTemplatesComponentsButtonExportModel) {
  exports['default'] = _ember['default'].Component.extend({
    layout: _juridicoSengerjTemplatesComponentsButtonExportModel['default'],
    pdfOverflow: "ellipsize",
    tagName: "span",
    open: false,
    filters: {},
    include: "",
    title: "",
    emptyValue: "-",
    records: [],
    columns: {},
    filtersPdf: null,
    columnStyles: {},
    theme: 'striped',
    longTextColumns: [],
    isConcurrency: false,
    headerStyle: { fillColor: [122, 160, 193] },
    marginPdf: { top: 55, bottom: 30, left: 20, right: 20 },
    showHeader: 'everyPage',

    recordsLength: _ember['default'].computed("records.[]", function () {
      return this.get("records.length");
    })
  });
});