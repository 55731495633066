define('juridico-sengerj/helpers/or', ['exports', 'ember-truth-helpers/helpers/or'], function (exports, _emberTruthHelpersHelpersOr) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberTruthHelpersHelpersOr['default'];
    }
  });
  Object.defineProperty(exports, 'or', {
    enumerable: true,
    get: function get() {
      return _emberTruthHelpersHelpersOr.or;
    }
  });
});