define("juridico-sengerj/routes/dashboard/contrato-escritorios/novo", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Route.extend({
    model: function model() {
      var contrato = this.store.createRecord("juridico/contrato-escritorio");

      return this.store.createRecord("juridico/contrato-escritorio", { contrato: contrato });
    },

    actions: {
      newContratoCreated: function newContratoCreated() {
        _ember["default"].$.niftyNoty({
          type: 'success',
          icon: 'fa fa-exclamation-triangle fa-2x',
          container: "floating",
          title: 'O Contrato foi adicionado.',
          timer: 3000
        });

        this.get('router').transitionTo('dashboard.contrato-escritorios.index');
      }
    }
  });
});