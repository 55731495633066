define("juridico-sengerj/mixins/components/load-holidays-calendar", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Mixin.create({
    loadHolidays: function loadHolidays() {
      var _this = this;

      var date = arguments.length <= 0 || arguments[0] === undefined ? new Date() : arguments[0];

      return this.get("store").findAll("juridico/homologacao/feriado").then(function (holidays) {
        return holidays.filter(function (h) {
          return _this._onMonth(h, date);
        }).map(function (holiday) {
          return { day: holiday.get("dataFeriado").getUTCDate(), reason: holiday.get("nome") };
        });
      });
    },

    _onMonth: function _onMonth(holiday, date) {
      return holiday.get("dataFeriado").getMonth() === date.getMonth();
    }
  });
});