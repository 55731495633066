define('juridico-sengerj/validators/unique', ['exports', 'ember', 'ember-cp-validations/validators/base'], function (exports, _ember, _emberCpValidationsValidatorsBase) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Unique = _emberCpValidationsValidatorsBase['default'].extend({
    store: _ember['default'].inject.service(),

    validate: function validate(value, _ref, model) {
      var scope = _ref.scope;
      var message = _ref.message;

      var modelName = model._internalModel.modelName;
      return this.get("store").query(modelName, _defineProperty({}, scope, value)).then(function (records) {
        return _ember['default'].isEmpty(records.rejectBy('id', model.id)) ? true : message;
      });
    }
  });

  exports['default'] = Unique;
});