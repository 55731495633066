define('juridico-sengerj/models/juridico/pasta-encerrada', ['exports', 'juridico-sengerj/models/abstract-model', 'ember-data', 'ember-cp-validations'], function (exports, _juridicoSengerjModelsAbstractModel, _emberData, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    observacao: (0, _emberCpValidations.validator)('presence', true),
    dataEncerramento: (0, _emberCpValidations.validator)('presence', true),
    tipoEncerramento: (0, _emberCpValidations.validator)('presence', true)
  });

  exports['default'] = _juridicoSengerjModelsAbstractModel['default'].extend(Validations, {
    observacao: _emberData['default'].attr('string'),
    dataEncerramento: _emberData['default'].attr('date'),
    pasta: _emberData['default'].belongsTo('juridico/pasta'),
    tipoEncerramento: _emberData['default'].belongsTo('juridico/tipo-encerramento')
  });
});