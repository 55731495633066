define('juridico-sengerj/routes/dashboard/atendimentos/homologacoes/atendimento', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    actions: {
      atendimentoSaved: function atendimentoSaved(atendimento, wasHomologado) {
        _ember['default'].$.niftyNoty({
          type: 'success',
          icon: 'fa fa-exclamation-triangle fa-2x',
          container: "floating",
          title: this._foiHomologado(atendimento, wasHomologado) ? 'O Atendimento foi alterado' : 'Homologação foi realizada',
          timer: 3000
        });

        this.get('router').transitionTo('dashboard.atendimentos.homologacoes');
      }
    },

    model: function model(_ref) {
      var atendimento_id = _ref.atendimento_id;

      return this.store.findRecord("juridico/homologacao/atendimento", atendimento_id);
    },

    _foiHomologado: function _foiHomologado(atendimento, wasHomologado) {
      return atendimento.get("dataHomologacao") && wasHomologado;
    }
  });
});