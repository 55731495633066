define("juridico-sengerj/templates/dashboard/menu/-escritorio", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 9,
            "column": 88
          }
        },
        "moduleName": "juridico-sengerj/templates/dashboard/menu/-escritorio.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        morphs[4] = dom.createMorphAt(fragment, 8, 8, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "dashboard-menu", ["dashboard.inicio.escritorio.index"], ["icon", "fa-home", "title", "Página Inicial"], ["loc", [null, [1, 0], [1, 92]]], 0, 0], ["inline", "dashboard-menu", ["dashboard.advogados.index"], ["icon", "fa-group", "title", "Advogados"], ["loc", [null, [3, 0], [3, 80]]], 0, 0], ["inline", "dashboard-menu", ["dashboard.agendamentos.advogados.index"], ["icon", "fa-calendar", "title", "Agendamentos"], ["loc", [null, [5, 0], [5, 99]]], 0, 0], ["inline", "dashboard-menu", ["dashboard.inicio.advogado.atendimentos"], ["icon", "fa-pencil-square-o", "title", "Atendimentos"], ["loc", [null, [7, 0], [7, 106]]], 0, 0], ["inline", "dashboard-menu", ["dashboard.inicio.advogado.pastas"], ["icon", "fa-folder", "title", "Processos"], ["loc", [null, [9, 0], [9, 88]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});