define("juridico-sengerj/components/route-components/form-homologacao", ["exports", "ember"], function (exports, _ember) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var isPresent = _ember["default"].isPresent;
  var isBlank = _ember["default"].isBlank;
  var computed = _ember["default"].computed;
  var isEmpty = _ember["default"].isEmpty;
  var RSVP = _ember["default"].RSVP;
  var $ = _ember["default"].$;
  var get = _ember["default"].get;
  exports["default"] = _ember["default"].Component.extend({
    homologar: false,
    mostrarCalendario: true,
    currentCalendarDate: new Date(),
    agendamento: null,
    atendimento: null,
    "on-saved": function onSaved() {},

    _agendamentoOrAtendimento: null,

    _isAgendamentoOrAtendimentoNew: false,

    _profissionalHasAgendamento: false,

    profissional: computed.alias("_agendamentoOrAtendimento.profissional"),
    profissionalUnidade: computed.alias("_agendamentoOrAtendimento.profissionalUnidade"),
    unidade: computed.alias("profissionalUnidade.unidade"),

    pessoa: computed.alias("profissional.pessoa"),

    store: _ember["default"].inject.service(),

    actions: {
      toggleHomologacaoPanel: function toggleHomologacaoPanel() {
        this.setProperties({
          homologacaoToggled: true,
          profissionalToggled: false
        });

        this._saveProfissional();
      },

      searchProfissional: function searchProfissional(filterName, filterValue) {
        var _get$query;

        return this.get("store").query("profissional", (_get$query = {}, _defineProperty(_get$query, filterName, filterValue), _defineProperty(_get$query, "include", 'pessoa.telefones, pessoa.emails, pessoa.enderecos'), _get$query));
      },

      selectedProfissional: function selectedProfissional(profissional) {
        this._agendamentoOrAtendimento.set("profissional", profissional);
      },

      setCalendarChoosenDate: function setCalendarChoosenDate(dataHorario) {
        this.get("agendamento").set("dataHorario", dataHorario);

        this.agendamento.save();

        this.setProperties({
          calendarioToggled: false,
          profissionalToggled: true
        });
      },

      finishAgendamento: function finishAgendamento() {
        var enderecos, promises, _ref, unidade, profissionalUnidade, atendimento, _agendamentoOrAtendimento;

        return regeneratorRuntime.async(function finishAgendamento$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.next = 2;
              return regeneratorRuntime.awrap(this.get("pessoa.enderecos"));

            case 2:
              enderecos = context$1$0.sent;

              if (!enderecos.isAny("tipoEndereco.value", "residencial")) {
                context$1$0.next = 27;
                break;
              }

              context$1$0.next = 6;
              return regeneratorRuntime.awrap(this.getProperties('profissionalUnidade', 'unidade', '_agendamentoOrAtendimento', 'atendimento'));

            case 6:
              promises = context$1$0.sent;
              context$1$0.next = 9;
              return regeneratorRuntime.awrap(_ember["default"].RSVP.hash(promises));

            case 9:
              _ref = context$1$0.sent;
              unidade = _ref.unidade;
              profissionalUnidade = _ref.profissionalUnidade;
              atendimento = _ref.atendimento;
              _agendamentoOrAtendimento = _ref._agendamentoOrAtendimento;
              context$1$0.next = 16;
              return regeneratorRuntime.awrap(this._saveProfissional());

            case 16:
              context$1$0.next = 18;
              return regeneratorRuntime.awrap(unidade.save());

            case 18:
              context$1$0.next = 20;
              return regeneratorRuntime.awrap(profissionalUnidade.save());

            case 20:
              context$1$0.next = 22;
              return regeneratorRuntime.awrap(_agendamentoOrAtendimento.save({ force: true }));

            case 22:
              context$1$0.next = 24;
              return regeneratorRuntime.awrap(atendimento.save());

            case 24:

              get(this, 'on-saved')(atendimento, this._homologado);
              context$1$0.next = 28;
              break;

            case 27:
              _ember["default"].$.niftyNoty({
                type: 'danger',
                icon: 'fa fa-thumbs-o-down fa-2x',
                container: "floating",
                title: 'É necessário adicionar pelo menos um endereço residencial',
                timer: 6000
              });

            case 28:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      checkAgendamentos: function checkAgendamentos(profissional) {
        var _this = this;

        var porProfissionalId = profissional.get("id");

        this.get("store").query('juridico/homologacao/agendamento-ocupado', { porProfissionalId: porProfissionalId }).then(function (agendamentos) {
          if (agendamentos.get("length") === 0) {
            _this.set("_profissionalHasAgendamento", false);
          } else {
            _this.set("_profissionalHasAgendamento", true);

            _ember["default"].$.niftyNoty({
              type: 'danger',
              icon: 'fa fa-thumbs-o-down fa-2x',
              container: "floating",
              title: 'Profissional já possui um agendamento em aberto',
              timer: 6000
            });
          }
        });
      }
    },

    _saveProfissional: function _saveProfissional() {
      var pessoa;
      return regeneratorRuntime.async(function _saveProfissional$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this.get("pessoa"));

          case 2:
            pessoa = context$1$0.sent;
            context$1$0.next = 5;
            return regeneratorRuntime.awrap(pessoa.saveAll());

          case 5:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    _profissionalHasNotAgendamento: computed.not("_profissionalHasAgendamento"),

    validationsAttrs: computed.alias("agendamento.validations.attrs"),
    contatosIsValid: computed.and("validationsAttrs.telefones.isValid", "validationsAttrs.emails.isValid"),

    enderecosValidations: computed.mapBy("pessoa.enderecos", "validations"),

    enderecosAllValid: computed("enderecosValidations.@each.isValid", function () {
      return this.get("enderecosValidations.length") === 0 || this.get("enderecosValidations").isAny("isValid", true);
    }),

    pessoaIsValid: computed.and("pessoa.validations.isValid", "enderecosAllValid"),

    dadosProfissionalIsValid: computed.and("_profissionalHasNotAgendamento", "profissional.validations.isValid", "pessoaIsValid", "contatosIsValid"),

    atendimentoIsValid: computed.alias("atendimento.validations.isValid"),

    profissionalUnidadeIsValid: computed.alias("profissionalUnidade.validations.isValid"),

    unidadeIsValid: computed.alias("unidade.validations.isValid"),

    dadosHomologacaoIsValid: computed.and("unidadeIsValid", "atendimentoIsValid", "profissionalUnidadeIsValid", "dadosProfissionalIsValid"),

    init: function init() {
      var _setProperties;

      this._super.apply(this, arguments);

      var modelName = this.homologar ? "atendimento" : "agendamento";

      var relationName = this.homologar ? "agendamento" : "atendimento";

      var _agendamentoOrAtendimento = this.get(modelName);

      var _isAgendamentoOrAtendimentoNew = _agendamentoOrAtendimento.get("isNew");

      this.setProperties((_setProperties = {
        calendarioToggled: _isAgendamentoOrAtendimentoNew,
        homologacaoToggled: !_isAgendamentoOrAtendimentoNew,
        profissionalToggled: this.homologar

      }, _defineProperty(_setProperties, relationName, this.get(modelName + "." + relationName)), _defineProperty(_setProperties, "_agendamentoOrAtendimento", _agendamentoOrAtendimento), _defineProperty(_setProperties, "_isAgendamentoOrAtendimentoNew", _isAgendamentoOrAtendimentoNew), _setProperties));

      this.set("_homologado", isPresent(this.get("atendimento.dataHomologacao")));
      this.set("_naoHomologado", !this._homologado);
    }
  });
});