define('juridico-sengerj/validators/compare', ['exports', 'ember-cp-validations/validators/base'], function (exports, _emberCpValidationsValidatorsBase) {

  var compareTypes = ['greaterThan'];

  function compareTo(options) {
    return options[choosenType(options)];
  };

  function choosenType(options) {
    return compareTypes.find(function (type) {
      return options[type];
    });
  };

  var Compare = _emberCpValidationsValidatorsBase['default'].extend({
    validate: function validate(value, options, model) {
      var compareToAttribute = compareTo(options);

      return this.isValid(value, choosenType(options), model.get(compareToAttribute)) || options.message;
    },

    isValid: function isValid(value, choosenType, compareValue) {
      switch (choosenType) {
        case 'greaterThan':
          return value > compareValue;
        default:
          return false;
      }
    }
  });

  Compare.reopenClass({
    /**
     * Define attribute specific dependent keys for your validator
     *
     * [
     * 	`model.array.@each.${attribute}` --> Dependent is created on the model's context
     * 	`${attribute}.isValid` --> Dependent is created on the `model.validations.attrs` context
     * ]
     *
     * @param {String}  attribute   The attribute being evaluated
     * @param {Unknown} options     Options passed into your validator
     * @return {Array}
     */
    getDependentsFor: function getDependentsFor(attribute, options) {
      return [attribute, compareTo(options)];
    }
  });

  exports['default'] = Compare;
});