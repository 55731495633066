define('juridico-sengerj/controllers/dashboard/tipo-reclamacoes/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: ['porId'],

    columnsTitle: ["Código", "Nome"],

    tipoReclamacoes: null,
    tipoReclamacoesModalVisible: false,

    actions: {

      openModalTipoReclamacoes: function openModalTipoReclamacoes() {

        var tipoReclamacoes = this.store.createRecord('juridico/tipo-reclamacao');

        this.setProperties({ tipoReclamacoesModalVisible: true, tipoReclamacoes: tipoReclamacoes });
      },

      criarTipoReclamacoes: function criarTipoReclamacoes() {
        var _this = this;

        this.tipoReclamacoes.save().then(function () {
          _this.set("tipoReclamacoesModalVisible", false);

          _this.container.lookup("route:dashboard.tipo-reclamacoes").refresh();

          _ember['default'].$.niftyNoty({
            type: 'success',
            icon: 'fa fa-exclamation-triangle fa-2x',
            container: "floating",
            title: 'O tipo de ação foi criado com sucesso.',
            timer: 3000
          });
        });
      },

      removerTipoReclamacoes: function removerTipoReclamacoes(tipoReclamacoes) {
        this.store.find('juridico/tipo-reclamacao', tipoReclamacoes.id).then(function (tipoReclamacoes) {
          tipoReclamacoes.destroyRecord();

          _ember['default'].$.niftyNoty({
            type: 'success',
            icon: 'fa fa-exclamation-triangle fa-2x',
            container: "floating",
            title: 'O tipo de ação foi removido com sucesso.',
            timer: 3000
          });
        });
      },

      changeValue: function changeValue(tipoReclamacoes) {
        this.store.find('juridico/tipo-reclamacao', tipoReclamacoes.id).then(function (tipoReclamacoes) {
          tipoReclamacoes.set('nome', tipoReclamacoes.get('nome'));
          tipoReclamacoes.save();

          _ember['default'].$.niftyNoty({
            type: 'success',
            icon: 'fa fa-exclamation-triangle fa-2x',
            container: "floating",
            title: 'O tipo de ação foi atualizado com sucesso.',
            timer: 3000
          });
        });
      },

      rollbackValue: function rollbackValue(tipoReclamacoes) {
        this.store.peekRecord('juridico/tipo-reclamacao', tipoReclamacoes.id).rollbackAttributes();
      }
    }
  });
});