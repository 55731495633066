define('juridico-sengerj/components/modal-export-ocorrencias', ['exports', 'ember', 'juridico-sengerj/components/modal-export-data', 'juridico-sengerj/helpers/format-date', 'juridico-sengerj/helpers/format-currency', 'juridico-sengerj/helpers/format-time'], function (exports, _ember, _juridicoSengerjComponentsModalExportData, _juridicoSengerjHelpersFormatDate, _juridicoSengerjHelpersFormatCurrency, _juridicoSengerjHelpersFormatTime) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var isBlank = _ember['default'].isBlank;
  var computed = _ember['default'].computed;
  exports['default'] = _juridicoSengerjComponentsModalExportData['default'].extend({
    store: _ember['default'].inject.service(),

    pastaId: null,
    emptyValue: "",
    include: "tipo_ocorrencia, notas, documentos",

    columns: {
      "Ocorrência": "tipoOcorrencia.descricao",
      Data: function Data(o) {
        return (0, _juridicoSengerjHelpersFormatDate.formatDate)([o.get("dataOcorrencia")]);
      },
      Hora: function Hora(o) {
        return (0, _juridicoSengerjHelpersFormatTime.formatTime)([o.get("hora")]);
      },
      "Documentos": "documentosUrl",
      "Comentário": "comentario"
    },

    createPdfTable: function createPdfTable(doc, rows, headers) {
      var _this = this;

      rows.forEach(function (row) {
        var comentario = row[row.length - 1];
        var documentos = row[row.length - 2] || [];

        _this._createPdfOcorrencia(doc, row.slice(0, 3), comentario, documentos);

        if (comentario != "") {
          _this._createTableComentario(doc, comentario);
        }

        if (_ember['default'].isPresent(documentos)) {
          _this._createTableDocumentos(doc, documentos);
        }

        doc.setDrawColor(169, 169, 169);
        doc.line(20, doc.autoTable.previous.finalY + 6, 190, doc.autoTable.previous.finalY + 6);
      });
    },

    loadAllRecords: function loadAllRecords() {
      var _getProperties = this.getProperties("store", "pastaId");

      var store = _getProperties.store;
      var pastaId = _getProperties.pastaId;

      var include = "pasta_avulsa.advogado.pessoa, atendimento.agendamento.advogado.pessoa, objetos_acao, orgao_justica, reus.reuzavel";
      var allRecords = this._super.apply(this, arguments);

      return store.findRecord("juridico/pasta", pastaId, { include: include, reload: true }).then(function (pasta) {
        return pasta.get("reclamantes.firstObject.pessoa");
      }).then(function () {
        return allRecords;
      });
    },

    _createPdfOcorrencia: function _createPdfOcorrencia(doc, row, comentario, documentosUrl) {
      if (comentario === undefined) comentario = "";

      var _row = [].concat(row, ['']).filter(_ember['default'].isPresent);
      var _headers = [].concat(_toConsumableArray(Array(_row.length).keys()));

      doc.autoTable(_headers, [_row], {
        addPageContent: this._pdfPageContent.bind(doc, this),
        startY: doc.autoTable.previous.finalY + 12 || 80,
        theme: 'striped',
        margin: { top: 80, bottom: 30, left: 20, right: 20 },
        styles: { columnWidth: 'wrap' },
        headerStyles: { fillColor: [91, 91, 91] },
        showHeader: { showHeader: 'never' },
        columnStyles: _defineProperty({
          0: { fontStyle: "bold", columnWidth: 'wrap' }
        }, _row.length - 1, { columnWidth: "auto", overflow: "linebreak" }),

        drawRow: function drawRow(row, data) {
          var k = doc.internal.scaleFactor;
          var lineCount = doc.splitTextToSize(comentario, data.table.width).length;
          var fontHeight = 12 / k * 1.15;
          var comentarioHeight = lineCount * fontHeight;
          var documentosHeight = documentosUrl.map(function (url) {
            return doc.splitTextToSize(url, data.table.width, { fontSize: 12 }).length;
          }).reduce(function (a, b) {
            return a + b;
          }, 0) * row.height;
          var headers = 3;

          var posY = row.y + row.height * headers + documentosHeight + comentarioHeight + data.settings.margin.bottom;
          if (posY > doc.internal.pageSize.height) {
            data.addPage();
          }

          return true;
        }
      });
    },

    _createTableComentario: function _createTableComentario(doc, comentario) {
      doc.autoTable(['Comentário'], [[comentario]], {
        startY: doc.autoTable.previous.finalY,
        theme: 'striped',
        pageBreak: 'avoid',
        margin: { top: 80, bottom: 30, left: 25, right: 20 },
        headerStyles: { fillColor: [91, 91, 91], halign: 'center' },
        showHeader: { showHeader: 'never' },
        styles: { overflow: 'linebreak' }
      });
    },

    _createTableDocumentos: function _createTableDocumentos(doc, documentosUrl) {
      doc.autoTable(['Anexos'], documentosUrl.map(function (url) {
        return [url];
      }), {
        startY: doc.autoTable.previous.finalY,
        theme: 'striped',
        pageBreak: 'avoid',
        margin: { top: 80, bottom: 30, left: 25, right: 20 },
        headerStyles: { fillColor: [122, 160, 193], halign: 'center' },
        showHeader: { showHeader: 'never' },
        styles: { overflow: 'linebreak' },
        bodyStyles: { textColor: [6, 69, 173] }
      });
    }
  });
});