define('juridico-sengerj/components/route-components/atendimento-advogado', ['exports', 'ember', 'sweetalert2'], function (exports, _ember, _sweetalert2) {
  exports['default'] = _ember['default'].Component.extend({
    atendimento: null,
    store: _ember['default'].inject.service(),

    agendamento: _ember['default'].computed.alias("atendimento.agendamento"),
    reclamantes: _ember['default'].computed.alias("agendamento.reclamantes"),

    actions: {
      changeNecessidadeDocumento: function changeNecessidadeDocumento(documentos) {
        this.atendimento.set("documentosNecessarios", documentos);
      },

      saveAtendimento: function saveAtendimento() {
        var _this = this;

        this._saveAtendimento().then(function () {
          _this._atendimentoChanged();
          _this._transitionToIndex();
        });
      },

      rollback: function rollback() {
        this.atendimento.rollbackAttributes();
        this._transitionToIndex();
      },

      saveNewAtendimento: function saveNewAtendimento(isOriginaProcesso) {
        var _this2 = this;

        (0, _sweetalert2['default'])({
          title: 'Tem certeza?',
          text: "Você tem certeza que deseja salvar o atendimento e " + (isOriginaProcesso ? "originar um processo" : "não originar um processo"),
          type: "warning"
        }).then(function () {
          _this2.atendimento.set("status", { value: isOriginaProcesso ? 'possivel_processo' : 'encerrado' });

          return _this2._saveAtendimento().then(function () {
            _this2._atendimentoCreated();
            return _this2._transitionToIndex();
          });
        })['catch'](_sweetalert2['default'].noop);
      },

      addDocumentoNecessario: function addDocumentoNecessario() {
        this.get("atendimento.documentosNecessarios").createRecord();
      },

      addContrato: function addContrato() {
        this.get("atendimento.contratosAssinados").createRecord();
      }
    },

    atendimentoIsValid: _ember['default'].computed.alias("atendimento.validations.isValid"),

    agendamentoIsValid: _ember['default'].computed.alias("agendamento.validations.isValid"),

    newAtendimentoIsValid: _ember['default'].computed.and("atendimentoIsValid", "agendamentoIsValid"),

    _saveAtendimento: function _saveAtendimento() {
      var _this3 = this;

      return this.atendimento.save({ force: true }).then(function () {
        _this3.atendimento.get("documentosNecessarios").forEach(function (documento) {
          return documento.save();
        });
        _this3.atendimento.get("contratosAssinados").forEach(function (contrato) {
          return contrato.save();
        });
      });
    },

    _atendimentoCreated: function _atendimentoCreated() {
      _ember['default'].$.niftyNoty({
        type: 'success',
        icon: 'fa fa-exclamation-triangle fa-2x',
        container: "floating",
        title: 'Atendimento foi registrado com sucesso',
        timer: 3000
      });
    },

    _atendimentoChanged: function _atendimentoChanged() {
      _ember['default'].$.niftyNoty({
        type: 'success',
        icon: 'fa fa-exclamation-triangle fa-2x',
        container: "floating",
        title: 'Atendimento foi alterado com sucesso',
        timer: 3000
      });
    },

    _transitionToIndex: function _transitionToIndex() {
      this.get('router').transitionTo('dashboard.atendimentos.advogados');
    },

    init: function init() {
      this._super.apply(this, arguments);
      var store = this.get("store");

      var documentos = store.findAll("juridico/tipo-documento").then(function (tipoDocumentos) {
        return tipoDocumentos.map(function (tipoDocumento) {
          return store.createRecord('juridico/advogado/atendimento/documento-necessario', { tipoDocumento: tipoDocumento });
        });
      });

      this.set("documentosNecessarios", documentos);
    }
  });
});