define('juridico-sengerj/components/elements-selection-table', ['exports', 'ember', 'juridico-sengerj/components/elements-table'], function (exports, _ember, _juridicoSengerjComponentsElementsTable) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports['default'] = _juridicoSengerjComponentsElementsTable['default'].extend({
    defaultSelection: false,

    "on-selection-change": function onSelectionChange() {},

    _allSelected: _ember['default'].computed.oneWay("defaultSelection"),

    _toggledRecords: [],

    actions: {
      toggleAllSelection: function toggleAllSelection() {
        this.setProperties({
          _allSelected: !this._allSelected,
          _toggledRecords: []
        });
        this.onSelectionChange();
      },

      toggleSelection: function toggleSelection(record) {
        this.set("_toggledRecords", [record].concat(_toConsumableArray(this._toggledRecords)));
        this.onSelectionChange();
      },

      isRecordChecked: function isRecordChecked(record, selectedRecords, unSelectedRecords) {
        var _allSelected = this.get("_allSelected");

        return _allSelected && !unSelectedRecords.includes(record) || !_allSelected && selectedRecords.includes(record);
      }
    },

    selectedRecords: _ember['default'].computed("_toggledRecords", function () {
      return _ember['default'].A(this.get("_allSelected") ? [] : this._toggledRecords);
    }),

    unSelectedRecords: _ember['default'].computed("_toggledRecords", function () {
      return _ember['default'].A(this.get("_allSelected") ? this._toggledRecords : []);
    }),

    onSelectionChange: function onSelectionChange() {
      var _getProperties = this.getProperties("_allSelected", "selectedRecords", "unSelectedRecords");

      var _allSelected = _getProperties._allSelected;
      var selectedRecords = _getProperties.selectedRecords;
      var unSelectedRecords = _getProperties.unSelectedRecords;

      _ember['default'].get(this, "on-selection-change")(selectedRecords, unSelectedRecords, _allSelected);
    }
  });
});