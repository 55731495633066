define('juridico-sengerj/validators/presence-array', ['exports', 'ember', 'ember-cp-validations/validators/base'], function (exports, _ember, _emberCpValidationsValidatorsBase) {

  var PresenceArray = _emberCpValidationsValidatorsBase['default'].extend({
    validate: function validate(value, _ref) {
      var message = _ref.message;

      if (_ember['default'].isBlank(value)) {
        return message;
      } else {
        return _ember['default'].isEmpty(value) ? message : true;
      }
    }
  });

  PresenceArray.reopenClass({
    getDependentsFor: function getDependentsFor(attribute) {
      return ['model.' + attribute + '.[]'];
    }
  });

  exports['default'] = PresenceArray;
});