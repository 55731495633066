define('juridico-sengerj/controllers/dashboard/ramo-direitos/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: ['porId'],

    columnsTitle: ["Nome"],

    ramoDireito: null,
    ramoDireitoModalVisible: false,

    actions: {

      openModalRamoDireito: function openModalRamoDireito() {

        var ramoDireito = this.store.createRecord('juridico/ramo-direito');

        this.setProperties({ ramoDireitoModalVisible: true, ramoDireito: ramoDireito });
      },

      criarRamoDireito: function criarRamoDireito() {
        var _this = this;

        this.ramoDireito.save().then(function () {
          _this.set("ramoDireitoModalVisible", false);

          _this.container.lookup("route:dashboard.ramo-direitos").refresh();

          _ember['default'].$.niftyNoty({
            type: 'success',
            icon: 'fa fa-exclamation-triangle fa-2x',
            container: "floating",
            title: 'O ramo do direito ' + _this.tipoDocumento.get('nome') + ' foi criado com sucesso.',
            timer: 3000
          });
        });
      },

      removerRamoDireito: function removerRamoDireito(ramoDireito) {
        ramoDireito.destroyRecord().then(function () {

          _ember['default'].$.niftyNoty({
            type: 'success',
            icon: 'fa fa-exclamation-triangle fa-2x',
            container: "floating",
            title: 'O ramo do direito foi removido com sucesso.',
            timer: 3000
          });
        });
      },

      changeValue: function changeValue(ramoDireito) {
        this.store.find('juridico/ramo-direito', ramoDireito.id).then(function (ramoDireito) {
          ramoDireito.set('nome', ramoDireito.get('nome'));
          ramoDireito.save();

          _ember['default'].$.niftyNoty({
            type: 'success',
            icon: 'fa fa-exclamation-triangle fa-2x',
            container: "floating",
            title: 'O ramo do direito foi atualizado com sucesso.',
            timer: 3000
          });
        });
      },

      rollbackValue: function rollbackValue(ramoDireito) {
        this.store.peekRecord('juridico/ramo-direito', ramoDireito.id).rollbackAttributes();
      }
    }
  });
});