define("juridico-sengerj/templates/components/toggleable-row-table", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 5,
            "column": 0
          }
        },
        "moduleName": "juridico-sengerj/templates/components/toggleable-row-table.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "yield", [["subexpr", "component", ["toggleable-row-table/row"], ["on-click", ["subexpr", "action", ["onRowClick"], [], ["loc", [null, [2, 49], [2, 70]]], 0, 0]], ["loc", [null, [2, 2], [2, 71]]], 0, 0], ["subexpr", "component", ["toggleable-row-table/hideable-row"], ["isHidden", ["subexpr", "@mut", [["get", "isHidden", ["loc", [null, [3, 58], [3, 66]]], 0, 0, 0, 0]], [], [], 0, 0], "columnsLength", ["subexpr", "@mut", [["get", "columnsLength", ["loc", [null, [3, 81], [3, 94]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [3, 2], [3, 95]]], 0, 0]], [], ["loc", [null, [1, 0], [4, 2]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});