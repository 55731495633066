define('juridico-sengerj/components/autocomplete-model', ['exports', 'ember', 'juridico-sengerj/components/autocomplete-field'], function (exports, _ember, _juridicoSengerjComponentsAutocompleteField) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  exports['default'] = _juridicoSengerjComponentsAutocompleteField['default'].extend({
    valueField: 'id',
    modelName: '',
    value: null,
    queryParam: null,
    store: _ember['default'].inject.service(),
    "on-selected": function onSelected() {},

    _selectModelResult: function _selectModelResult(select) {
      _ember['default'].get(this, "on-selected")(select);
      this.set("value", select);
    },

    init: function init() {
      var _this = this;

      this.setProperties({
        search: function search(query) {
          var include = _this.include;
          return _this.get("store").query(_this.modelName, _defineProperty({ include: include }, _this.queryParam, query));
        },

        selectedResult: function selectedResult(select) {
          _this._selectModelResult(select);
        }
      });

      if (_ember['default'].isBlank(this.searchValue)) {
        this.set("searchValue", _ember['default'].computed('value.' + this.label, function () {
          return this.value && this.value.get(this.label);
        }));
      }

      this._super.apply(this, arguments);
    }
  });
});