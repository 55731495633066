define("juridico-sengerj/components/editable-text", ["exports", "ember"], function (exports, _ember) {
  var $ = _ember["default"].$;
  var run = _ember["default"].run;
  var computed = _ember["default"].computed;
  exports["default"] = _ember["default"].Component.extend({
    parent: "*:first",
    child: null,
    isVisible: false,
    top: 0,
    left: 0,
    "on-cancel": function onCancel() {},
    "on-save": function onSave() {},
    "on-open": function onOpen() {},

    actions: {
      closePopOver: function closePopOver() {
        this.set("isVisible", false);
        _ember["default"].get(this, "on-cancel")();
      },

      saveChanges: function saveChanges() {
        this.set("isVisible", false);
        _ember["default"].get(this, "on-save")();
      }
    },

    position: computed("top", "left", function () {
      return "top:" + this.top + "px;left:" + this.left + "px";
    }),

    _showPopOver: function _showPopOver(event) {
      _ember["default"].get(this, "on-open")();
      event.stopPropagation();
      this.set("isVisible", true);
      this._calculateTopLeftPos();

      run.schedule("afterRender", this, function () {
        var _this = this;

        this._getPopOverInputElement().keydown(function () {
          _this._calculateTopLeftPos();
        });
      });
    },

    _getElementToCalculate: function _getElementToCalculate() {
      if (this.child) {
        return this.$().parents(this.parent).find(this.child);
      } else {
        return this.$().parents(this.parent);
      }
    },

    _getPopOverElement: function _getPopOverElement() {
      return $("#" + this.elementId + "-popover");
    },

    _getPopOverInputElement: function _getPopOverInputElement() {
      return this._getPopOverElement().find("input");
    },

    _calculateTopLeftPos: function _calculateTopLeftPos() {
      run.schedule("afterRender", this, function () {
        var $element = this._getElementToCalculate();
        var $popOver = this._getPopOverElement();
        var parentLeftPos = $element.offset().left;
        var parentTopPos = $element.offset().top;

        this.setProperties({
          left: parentLeftPos - $popOver.width() / 2 + $element.width() / 2,
          top: parentTopPos - $popOver.height()
        });
      });
    },

    click: function click(event) {
      event.stopPropagation();
    },

    destroyClickEvent: (function () {
      this._getElementToCalculate().off("click");
      this._getPopOverInputElement().off("keydown");
    }).on("willDestroyElement"),

    didInsertElement: function didInsertElement() {
      this._getElementToCalculate().click(run.bind(this, this._showPopOver));

      this._super.apply(this, arguments);
    },

    init: function init() {
      this._super.apply(this, arguments);
      this.set("isVisible", false);
    }
  });
});