define('juridico-sengerj/components/summernote-text-area', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    height: 50,
    minHeight: null,
    maxHeight: null,
    focus: false,
    dialogsInBody: true,
    value: '',
    lang: 'pt-BR',

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      this.$().summernote(this.getProperties('height', 'minHeight', 'maxHeight', 'focus', 'lang', 'dialogsInBody'));

      this.$().summernote('code', this.value);

      this.$().on('summernote.change', function (_, value) {
        _this.set("value", value);
      });
    },

    didUpdateAttrs: function didUpdateAttrs(_ref) {
      var newValue = _ref.newAttrs.value.value;

      this._super.apply(this, arguments);
      if (_ember['default'].isBlank(this.value)) {
        this.$().summernote('code', '');
      }
    },

    willDestroyElementpublic: function willDestroyElementpublic() {
      this._super.apply(this, arguments);
      this.$().summernote("destroy");
    }
  });
});