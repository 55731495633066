define('juridico-sengerj/components/tables/table-agendamentos-advogado', ['exports', 'ember', 'sweetalert2'], function (exports, _ember, _sweetalert2) {
  exports['default'] = _ember['default'].Component.extend({
    columnsTitle: ["Código", "Advogado", "Tipo", "Reclamante", "Data", "Horário", "Canal", "Status"],

    agendamentos: [],
    isModalJustificativaVisible: false,
    popOverAdvogado: null,
    justificativaModal: null,
    store: _ember['default'].inject.service(),

    actions: {
      showModalJustificativa: function showModalJustificativa(agendamento) {
        var store = this.get("store");

        agendamento.get("justificativa").then(function (justificativa) {
          if (justificativa === null) {
            agendamento.set("justificativa", store.createRecord("juridico/advogado/agendamento/justificativa"));
          }
        });

        this.setProperties({
          isModalJustificativaVisible: true,
          agendamentoModal: agendamento
        });
      },

      showAdvogadoDialog: function showAdvogadoDialog(agendamentoId) {
        this.$('a#editable-text-advogado-' + agendamentoId).click();
      },

      createJustificativa: function createJustificativa(agendamento) {
        var _this = this;

        agendamento.get("justificativa").then(function (justificativa) {
          return justificativa.save();
        }).then(function () {
          _this.setProperties({
            isModalJustificativaVisible: false,
            agendamentoModal: null
          });

          _ember['default'].$.niftyNoty({
            type: 'success',
            icon: 'fa fa-thumbs-up fa-2x',
            container: "floating",
            title: 'Foi adicionado uma Justificativa para o agendamento ' + agendamento.get("mostrarCodigo"),
            timer: 4000
          });
        });
      },

      deleteAgendamento: function deleteAgendamento(agendamento) {
        (0, _sweetalert2['default'])({
          title: 'Tem certeza?',
          text: 'Não terá meios para recuperar o agendamento ' + agendamento.get("mostrarCodigo") + ' após sua remoção',
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Sim, remova"
        }).then(function () {
          return agendamento.destroyRecord();
        }).then(function () {
          _ember['default'].$.niftyNoty({
            type: 'success',
            icon: 'fa fa-thumbs-up fa-2x',
            container: "floating",
            title: 'Agendamento ' + agendamento.get("mostrarCodigo") + ' foi removido',
            timer: 4000
          });
        })['catch'](_sweetalert2['default'].noop);
      },

      selectedAdvogado: function selectedAdvogado(advogado) {
        this.set("popOverAdvogado", advogado);
      },

      findAdvogado: function findAdvogado(porNome) {
        return this.get("store").query("juridico/advogado", { porNome: porNome, include: 'pessoa ' });
      },

      changeAdvogado: function changeAdvogado(agendamento) {
        var agendamentoCodigo = agendamento.get("mostrarCodigo");
        var advogado = this.popOverAdvogado;
        var nomeAdvogado = advogado.get("nome");
        var oldAdvogado = this.get("store").peekRecord("juridico/advogado", agendamento.get("advogado.id"));

        var onError = function onError(_ref) {
          var errors = _ref.errors;

          agendamento.set("advogado", oldAdvogado);

          _ember['default'].$.niftyNoty({
            type: 'danger',
            icon: 'fa fa-thumbs-o-down fa-2x',
            container: 'floating',
            title: 'Erro ao alterar o Advogado',
            message: errors.mapBy("detail").join(', '),
            timer: 6000
          });
        };

        var onSucess = function onSucess() {
          _ember['default'].$.niftyNoty({
            type: 'success',
            icon: 'fa fa-thumbs-up fa-2x',
            container: "floating",
            title: 'O Advogado do agendamento ' + agendamentoCodigo + ' agora é ' + nomeAdvogado,
            timer: 5000
          });
        };
        if (this.popOverAdvogado) {

          agendamento.set("advogado", advogado);
          agendamento.save({ force: true }).then(onSucess, onError);
        }
      },

      cleanPopOverAdvogado: function cleanPopOverAdvogado() {
        this.set("popOverAdvogado", null);
      }
    }
  });
});