define('juridico-sengerj/models/juridico/reclamante', ['exports', 'juridico-sengerj/models/abstract-model', 'juridico-sengerj/utils/has-one-polymorphic', 'ember-data', 'ember', 'ember-cp-validations', 'juridico-sengerj/utils/format/date/short'], function (exports, _juridicoSengerjModelsAbstractModel, _juridicoSengerjUtilsHasOnePolymorphic, _emberData, _ember, _emberCpValidations, _juridicoSengerjUtilsFormatDateShort) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    pessoa: [(0, _emberCpValidations.validator)('belongs-to', true)]
  });

  exports['default'] = _juridicoSengerjModelsAbstractModel['default'].extend(Validations, {
    reclamavelId: _emberData['default'].attr('number'),
    reclamavelType: _emberData['default'].attr('string'),
    pis: _emberData['default'].attr('pis'),
    carteiraTrabalho: _emberData['default'].attr('string'),
    nomeMae: _emberData['default'].attr('string'),
    nomePai: _emberData['default'].attr('string'),
    verificaPedidoFiliacao: _emberData['default'].attr('string'),
    verificaSocioEmDia: _emberData['default'].attr('string'),
    isSecundario: _emberData['default'].attr('boolean'),
    isTerceiro: _emberData['default'].attr('boolean'),
    percentualCobrado: _emberData['default'].attr('number'),
    motivoAfastamento: _emberData['default'].belongsTo('juridico/motivo-afastamento'),
    pessoa: _emberData['default'].belongsTo('pessoa'),
    profissional: _emberData['default'].belongsTo('profissional'),
    autorizacao: _emberData['default'].belongsTo('juridico/reclamante-autorizacao'),
    cpf: _ember['default'].computed.alias("pessoa.cpf"),
    nome: _ember['default'].computed.alias("pessoa.nome"),
    nomeComCPF: _ember['default'].computed.alias("pessoa.nomeComCPF"),
    isAfastado: _ember['default'].computed.alias("motivoAfastamento.motivo"),
    email: _ember['default'].computed.alias('pessoa.emails.firstObject.email'),
    linkReclamanteContrato: _emberData['default'].attr('string'),
    percentualCobradoTramitacaoTsb: _emberData['default'].attr('number'),

    isAutorizado: _ember['default'].computed("autorizacao", function () {
      return _ember['default'].isPresent(this.belongsTo("autorizacao").id()) || _ember['default'].isPresent(this.get("autorizacao.content"));
    }),

    matricula: _ember['default'].computed(function () {
      return this.get("profissional.socio.matricula") || "Não é Sócio";
    }),

    statusSocioProfissional: _ember['default'].computed("profissional", "socio", function () {
      return this.get("profissional.socio.statusSocio.text") || this.get("profissional.statusCrea");
    }),

    afastamentoMotivoData: _ember['default'].computed("motivoAfastamento.{isLoaded,dataAfastamento,motivo}", function () {
      var afastamento = this.get("motivoAfastamento");

      if (afastamento.get("isLoaded")) {
        return afastamento.get("motivo") + '\n(' + (0, _juridicoSengerjUtilsFormatDateShort['default'])(afastamento.get("dataAfastamento")) + ')';
      }
    }),

    reclamavel: (0, _juridicoSengerjUtilsHasOnePolymorphic['default'])("reclamavel", { "juridico/advogado/agendamento": "Juridico::Agendamento" })
  });
});