define('juridico-sengerj/routes/dashboard/banco-pagadores/banco-pagador', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(_ref) {
      var banco_pagador_id = _ref.banco_pagador_id;

      return this.store.findRecord("juridico/banco-pagador", banco_pagador_id, {});
    },

    actions: {
      bancoPagadorSaved: function bancoPagadorSaved() {
        _ember['default'].$.niftyNoty({
          type: 'success',
          icon: 'fa fa-exclamation-triangle fa-2x',
          container: "floating",
          title: 'O Banco foi alterado.',
          timer: 3000
        });

        this.get('router').transitionTo('dashboard.banco-pagadores.index');
      }
    }
  });
});