define('juridico-sengerj/services/raven', ['exports', 'ember-cli-sentry/services/raven'], function (exports, _emberCliSentryServicesRaven) {
  exports['default'] = _emberCliSentryServicesRaven['default'].extend({

    captureException: function captureException() /* error */{
      this._super.apply(this, arguments);
    },

    captureMessage: function captureMessage() /* message */{
      return this._super.apply(this, arguments);
    },

    enableGlobalErrorCatching: function enableGlobalErrorCatching() {
      return this._super.apply(this, arguments);
    },

    ignoreError: function ignoreError() {
      var error = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      // Ember 2.X seems to not catch `TransitionAborted` errors caused by
      // regular redirects. We don't want these errors to show up in Sentry
      // so we have to filter them ourselfs.
      //
      // Once this issue https://github.com/emberjs/ember.js/issues/12505 is
      // resolved we can remove this code.
      return error.name === 'TransitionAborted' && error.message === 'The adapter rejected the commit because it was invalid';
    },

    callRaven: function callRaven() /* methodName, ...optional */{
      return this._super.apply(this, arguments);
    }
  });
});