define('juridico-sengerj/models/endereco', ['exports', 'juridico-sengerj/models/abstract-model', 'juridico-sengerj/utils/has-one-polymorphic', 'ember-data', 'ember-cp-validations'], function (exports, _juridicoSengerjModelsAbstractModel, _juridicoSengerjUtilsHasOnePolymorphic, _emberData, _emberCpValidations) {

	var Validations = (0, _emberCpValidations.buildValidations)({
		cep: [(0, _emberCpValidations.validator)('presence', true)],
		ufNome: [(0, _emberCpValidations.validator)('presence', true)],
		bairroNome: [(0, _emberCpValidations.validator)('presence', true)],
		cidadeNome: [(0, _emberCpValidations.validator)('presence', true)],
		logradouro: [(0, _emberCpValidations.validator)('presence', true)],
		tipoEndereco: [(0, _emberCpValidations.validator)('presence', true)],
		numero: [(0, _emberCpValidations.validator)('presence', true)]
	});

	exports['default'] = _juridicoSengerjModelsAbstractModel['default'].extend(Validations, {
		ufNome: _emberData['default'].attr('string'),
		bairroNome: _emberData['default'].attr('string'),
		cidadeNome: _emberData['default'].attr('string'),
		logradouro: _emberData['default'].attr('string'),
		numero: _emberData['default'].attr('string'),
		complemento: _emberData['default'].attr('string'),
		cep: _emberData['default'].attr('string'),
		tipoEndereco: _emberData['default'].attr('enum'),
		enderecavelType: _emberData['default'].attr('string'),
		enderecavelId: _emberData['default'].attr('number'),
		enderecavel: (0, _juridicoSengerjUtilsHasOnePolymorphic['default'])('enderecavel')
	});
});