define("juridico-sengerj/components/phones-tags", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    phones: [],
    attrValidations: _ember["default"].Object.create(),

    actions: {
      addPhone: function addPhone(telefone) {
        var telefonavel = this.get("phones.content.record").getRecord();

        var phoneRecord = this.get("phones").createRecord({
          telefone: telefone,
          telefonavel: telefonavel
        });

        if (!telefonavel.get("isNew")) {
          phoneRecord.save();
        }
      },

      removePhone: function removePhone(phone) {
        return this.get("phones").findBy('telefone', phone).destroyRecord();
      },

      onFocusOut: function onFocusOut() {
        this.set("showValidation", true);
      }
    }
  });
});