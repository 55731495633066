define("juridico-sengerj/components/panels/panel-simple", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    color: null,
    classNames: ["col-xs-12"],

    panelColor: _ember["default"].computed("color", function () {
      var color = this.get("color");
      if (color) {
        return "panel-" + color;
      }
    })
  });
});