define('juridico-sengerj/controllers/dashboard/tipo-encerramentos/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: ['porId'],

    columnsTitle: ["Nome"],

    tipoEncerramentos: null,
    tipoEncerramentosModalVisible: false,

    actions: {

      openModalTipoEncerramentos: function openModalTipoEncerramentos() {

        var tipoEncerramentos = this.store.createRecord('juridico/tipo-encerramento');

        this.setProperties({ tipoEncerramentosModalVisible: true, tipoEncerramentos: tipoEncerramentos });
      },

      criarTipoEncerramentos: function criarTipoEncerramentos() {
        var _this = this;

        this.tipoEncerramentos.save().then(function () {
          _this.set("tipoEncerramentosModalVisible", false);

          _this.container.lookup("route:dashboard.tipo-encerramentos").refresh();

          _ember['default'].$.niftyNoty({
            type: 'success',
            icon: 'fa fa-exclamation-triangle fa-2x',
            container: "floating",
            title: 'O tipo de arquivamento de processo foi criado com sucesso.',
            timer: 3000
          });
        });
      },

      removerTipoEncerramentos: function removerTipoEncerramentos(tipoEncerramentos) {
        this.store.find('juridico/tipo-encerramento', tipoEncerramentos.id).then(function (tipoEncerramentos) {
          tipoEncerramentos.destroyRecord();

          _ember['default'].$.niftyNoty({
            type: 'success',
            icon: 'fa fa-exclamation-triangle fa-2x',
            container: "floating",
            title: 'O tipo de arquivamento de processo foi removido com sucesso.',
            timer: 3000
          });
        });
      },

      changeValue: function changeValue(tipoEncerramentos) {
        this.store.find('juridico/tipo-encerramento', tipoEncerramentos.id).then(function (tipoEncerramentos) {
          tipoEncerramentos.set('nome', tipoEncerramentos.get('nome'));
          tipoEncerramentos.save();

          _ember['default'].$.niftyNoty({
            type: 'success',
            icon: 'fa fa-exclamation-triangle fa-2x',
            container: "floating",
            title: 'O tipo de arquivamento de processo foi atualizado com sucesso.',
            timer: 3000
          });
        });
      },

      rollbackValue: function rollbackValue(tipoEncerramentos) {
        this.store.peekRecord('juridico/tipo-encerramento', tipoEncerramentos.id).rollbackAttributes();
      }
    }
  });
});