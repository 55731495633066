define('juridico-sengerj/transforms/photo', ['exports', 'ember-data/transform'], function (exports, _emberDataTransform) {
  exports['default'] = _emberDataTransform['default'].extend({
    deserialize: function deserialize(serialized, options) {
      if (serialized === null) {
        return options.defaultValue;
      }

      return serialized;
    },

    serialize: function serialize(deserialized, options) {
      if (options.defaultValue === deserialized) {
        return null;
      }

      return deserialized;
    }
  });
});