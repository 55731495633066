define('juridico-sengerj/components/modal-export-reclamantes', ['exports', 'ember', 'juridico-sengerj/components/modal-export-data'], function (exports, _ember, _juridicoSengerjComponentsModalExportData) {
  var isBlank = _ember['default'].isBlank;
  var computed = _ember['default'].computed;
  exports['default'] = _juridicoSengerjComponentsModalExportData['default'].extend({
    store: _ember['default'].inject.service(),

    pastaId: null,
    emptyValue: "",
    include: "pessoa.profissional.socio, motivo_afastamento",

    columns: {
      "Matrícula": "profissional.socio.matricula",
      CPF: "cpf",
      Nome: "nome",
      "Afastamento": "afastamentoMotivoData",
      Status: "statusSocioProfissional"
    },

    longTextColumns: ["Nome", "Afastamento(motivo/data)"],

    marginPdf: { top: 85, bottom: 30, left: 20, right: 20 },

    loadAllRecords: function loadAllRecords() {
      var _getProperties = this.getProperties("store", "pastaId");

      var store = _getProperties.store;
      var pastaId = _getProperties.pastaId;

      var include = "pasta_avulsa.advogado.pessoa, atendimento.agendamento.advogado.pessoa, objetos_acao, orgao_justica, reus.reuzavel";
      var allRecords = this._super.apply(this, arguments);

      return store.findRecord("juridico/pasta", pastaId, { include: include, reload: true }).then(function (pasta) {
        return pasta.get("reclamantes.firstObject.pessoa");
      }).then(function () {
        return allRecords;
      });
    }
  });
});