define('juridico-sengerj/serializers/application', ['exports', 'ember-data/serializers/json-api'], function (exports, _emberDataSerializersJsonApi) {
  exports['default'] = DS.JSONAPISerializer.extend({
    serializeAttribute: function serializeAttribute(snapshot, json, key, attribute) {
      if (attribute.options && attribute.options.readOnly) {
        return;
      }

      if (snapshot.record.get('isNew') || snapshot.changedAttributes()[key]) {
        this._super(snapshot, json, key, attribute);
      }
    }
  });
});