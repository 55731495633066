define('juridico-sengerj/transforms/enum', ['exports', 'ember-data/transform', 'ember'], function (exports, _emberDataTransform, _ember) {
  exports['default'] = _emberDataTransform['default'].extend({
    enumGeneric: _ember['default'].Object.extend(),

    deserialize: function deserialize() {
      var serialized = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      return this.enumGeneric.create(serialized);
    },

    serialize: function serialize() {
      var deserialized = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      return deserialized.value;
    }
  });
});