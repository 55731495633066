define('juridico-sengerj/routes/dashboard/pastas/index', ['exports', 'ember', 'juridico-sengerj/mixins/routes/table-listing-with-filters'], function (exports, _ember, _juridicoSengerjMixinsRoutesTableListingWithFilters) {
  var isPresent = _ember['default'].isPresent;
  exports['default'] = _ember['default'].Route.extend(_juridicoSengerjMixinsRoutesTableListingWithFilters['default'], {
    model: function model(params) {
      var _this = this;

      return this.get("session.currentUser").then(function (usuario) {
        var isParamsPresent = isPresent(Object.keys(params).filter(function (param) {
          return isPresent(params[param]);
        }));
        var include = 'objetos_acao,reus.reuzavel,pasta_avulsa.advogado.escritorio.empresa, pasta_avulsa.advogado.pessoa, atendimento.agendamento.advogado.pessoa, atendimento.agendamento.escritorio.empresa';

        if (isParamsPresent || usuario.get("isPessoa")) {
          return _this.get("store").query("juridico/pasta", _this.filtersParams({ params: params, include: include }));
        } else {
          return [];
        }
      });
    },

    actions: {
      applyFilters: function applyFilters(filters) {
        this._super.apply(this, arguments).then(function () {
          // if (this.currentModel.get("length") === 1) {
          //   this.transitionTo('dashboard.pastas.detalhes', this.currentModel.get('firstObject.id'));
          // }
        });
      }
    }
  });
});