define("juridico-sengerj/routes/dashboard/usuarios/novo", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Route.extend({
    model: function model(_ref) {
      var permissao = _ref.permissao;

      var store = this.store;
      var usuario = store.createRecord("usuario");
      var pessoa = store.createRecord("pessoa");

      usuario.setProperties({
        pessoa: pessoa,
        permissoes: []
      });

      if (_ember["default"].isPresent(permissao)) {
        usuario.set("permissoes", [{ value: permissao }]);
      }

      return usuario;
    }
  });
});