define('juridico-sengerj/models/nota', ['exports', 'juridico-sengerj/utils/has-one-polymorphic', 'juridico-sengerj/models/abstract-model', 'ember-data'], function (exports, _juridicoSengerjUtilsHasOnePolymorphic, _juridicoSengerjModelsAbstractModel, _emberData) {
	exports['default'] = _juridicoSengerjModelsAbstractModel['default'].extend({
		descricao: _emberData['default'].attr('string'),
		anotavelType: _emberData['default'].attr('string'),
		anotavelId: _emberData['default'].attr('number'),
		nomeQuemCriou: _emberData['default'].attr('string', { readOnly: true }),
		fotoPerfil: _emberData['default'].attr('photo', { readOnly: true, defaultValue: "/assets/images/av1.png" }),
		isEditing: _emberData['default'].attr('boolean', { readOnly: true, defaultValue: function defaultValue() {
				return false;
			} }),
		createdAt: _emberData['default'].attr('date'),

		anotavel: (0, _juridicoSengerjUtilsHasOnePolymorphic['default'])("anotavel"),

		usuarioPermitidoEdicao: _emberData['default'].belongsTo('usuario'),
		usuario: _emberData['default'].belongsTo(),
		anexos: _emberData['default'].hasMany()
	});
});