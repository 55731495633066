define("juridico-sengerj/components/route-components/form-homologacao/homologacao", ["exports", "ember"], function (exports, _ember) {
  var computed = _ember["default"].computed;
  var $ = _ember["default"].$;
  exports["default"] = _ember["default"].Component.extend({

    isValid: false,

    store: _ember["default"].inject.service(),

    actions: {
      selectedUnidade: function selectedUnidade(unidade) {
        var _this = this;

        var profissional = this.get("profissional");

        this.get("store").query("profissional-unidade", {
          porUnidadeId: unidade.id,
          porProfissionalId: profissional.get('id')
        }).then(function (profissionalUnidade) {

          var first = profissionalUnidade.get("firstObject");

          if (first) {
            _this.set("profissionalUnidade", first);
          } else {
            var newProfissionalUnidade = _this.get("store").createRecord('profissional-unidade', {
              unidade: unidade,
              profissional: profissional
            });
            _this.set("profissionalUnidade", newProfissionalUnidade);
          }
        });
      }
    }
  });
});