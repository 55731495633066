define('juridico-sengerj/components/panels/panel-dados-profissional', ['exports', 'ember', 'ember-cp-validations'], function (exports, _ember, _emberCpValidations) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var computed = _ember['default'].computed;
  var isBlank = _ember['default'].isBlank;

  var Validations = (0, _emberCpValidations.buildValidations)({
    emails: [(0, _emberCpValidations.validator)('presence-array', { message: "É necessário informar pelo menos um E-mail" })],
    phones: [(0, _emberCpValidations.validator)('presence-array', { message: "É necessário informar pelo menos um Telefone" })]
  });

  exports['default'] = _ember['default'].Component.extend(Validations, {
    profissional: null,
    store: _ember['default'].inject.service(),
    showValidation: false,
    requiredContact: false,
    "profissional-selected": function profissionalSelected() {},

    actions: {
      setProfissionalNome: function setProfissionalNome(nome) {
        this.get("pessoa").set("nome", nome);
      },

      setProfissionalCPF: function setProfissionalCPF(cpf) {
        this.get("pessoa").set("cpf", cpf);
      },

      searchProfissional: function searchProfissional(filterName, filterValue) {
        var _get$query,
            _this = this;

        var promise = this.get("store").query("profissional", (_get$query = {}, _defineProperty(_get$query, filterName, filterValue), _defineProperty(_get$query, 'include', 'pessoa.telefones, pessoa.emails, pessoa.enderecos'), _get$query));

        promise.then(function (profissionais) {
          if (profissionais.get("length") === 0 && !_this.get("profissional.isNew")) {
            _this.set("profissional", _this._createNewProfissional());
          }
        });

        return promise;
      },

      selectedProfissional: function selectedProfissional(profissional) {
        _ember['default'].get(this, "profissional-selected")(profissional);
        this.set("profissional", profissional);
        this.set("showValidation", true);
      }
    },

    pessoa: computed.alias("profissional.pessoa"),
    emails: computed.alias("pessoa.emails"),
    phones: computed.alias("pessoa.telefones"),

    _createNewProfissional: function _createNewProfissional() {
      var _get$getProperties = this.get("pessoa").getProperties("nome", "cpf");

      var nome = _get$getProperties.nome;
      var cpf = _get$getProperties.cpf;

      var pessoa = this.get("store").createRecord("pessoa", { nome: nome, cpf: cpf });
      var profissional = this.get("store").createRecord("profissional", { pessoa: pessoa });

      return profissional;
    },

    init: function init() {
      this._super.apply(this, arguments);
      if (isBlank(this.get("profissional"))) {
        var store = this.get("store");
        var pessoa = store.createRecord("pessoa");

        this.set("profissional", store.createRecord("profissional", { pessoa: pessoa }));
      }
    }
  });
});