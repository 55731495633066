define('juridico-sengerj/routes/dashboard/agendamentos/advogados/novo', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    actions: {
      newAgendamentoSaved: function newAgendamentoSaved() {
        _ember['default'].$.niftyNoty({
          type: 'success',
          icon: 'fa fa-exclamation-triangle fa-2x',
          container: "floating",
          title: 'O agendamento foi criado.',
          timer: 3000
        });
      }
    },

    model: function model() {
      var agendamento = this.store.createRecord("juridico/advogado/agendamento");

      return agendamento;
    }
  });
});