define('juridico-sengerj/helpers/colorful-label', ['exports', 'ember'], function (exports, _ember) {
  exports.colorfulLabel = colorfulLabel;

  function colorfulLabel(params) {

    var value = params[0];

    if (_ember['default'].isPresent(value)) {
      if (value === false) {
        return _ember['default'].String.htmlSafe('<div class="label label-table label-danger"> Não </div>');
      } else {
        return _ember['default'].String.htmlSafe('<div class="label label-table label-success"> Sim </div>');
      }
    }
  }

  exports['default'] = _ember['default'].Helper.helper(colorfulLabel);
});