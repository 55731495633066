define('juridico-sengerj/routes/dashboard/atendimentos/advogados/atendimento', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(_ref) {
      var atendimento_id = _ref.atendimento_id;

      var atendimento = this.store.findRecord('juridico/advogado/atendimento', atendimento_id, { include: "agendamento" });

      return atendimento;
    }
  });
});