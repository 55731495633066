define('juridico-sengerj/models/juridico/substituicao-advogado', ['exports', 'juridico-sengerj/models/abstract-model', 'ember-data', 'ember-cp-validations'], function (exports, _juridicoSengerjModelsAbstractModel, _emberData, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    ocorrencia: (0, _emberCpValidations.validator)('presence', true),
    novoAdvogado: (0, _emberCpValidations.validator)('presence', true)
  });

  exports['default'] = _juridicoSengerjModelsAbstractModel['default'].extend(Validations, {
    ocorrencia: _emberData['default'].belongsTo('juridico/ocorrencia'),
    novoAdvogado: _emberData['default'].belongsTo('juridico/advogado'),
    advogadoAntigo: _emberData['default'].belongsTo('juridico/advogado')
  });
});