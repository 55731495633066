define('juridico-sengerj/components/modals/modal-new-reclamante-secundario', ['exports', 'ember', 'ember-cp-validations', 'juridico-sengerj/utils/format/date/short'], function (exports, _ember, _emberCpValidations, _juridicoSengerjUtilsFormatDateShort) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    nome: (0, _emberCpValidations.validator)('presence', true),
    emails: (0, _emberCpValidations.validator)('presence-array', { message: "É necessário informar pelo menos um E-mail" })
  });

  exports['default'] = _ember['default'].Component.extend(Validations, {
    store: _ember['default'].inject.service(),
    isVisible: false,
    reclamavel: null,
    _created: false,
    "on-create": function onCreate() {},

    actions: {
      saveReclamante: function saveReclamante() {
        var reclamante, pessoa;
        return regeneratorRuntime.async(function saveReclamante$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              reclamante = this.get("_newReclamante");
              context$1$0.next = 3;
              return regeneratorRuntime.awrap(reclamante.get("pessoa"));

            case 3:
              pessoa = context$1$0.sent;

              if (!pessoa.get("isNew")) {
                context$1$0.next = 7;
                break;
              }

              context$1$0.next = 7;
              return regeneratorRuntime.awrap(pessoa.saveAll());

            case 7:
              context$1$0.next = 9;
              return regeneratorRuntime.awrap(reclamante.save());

            case 9:

              _ember['default'].get(this, "on-create")(reclamante);

            case 10:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      },

      showNotification: function showNotification() {
        _ember['default'].$.niftyNoty({
          type: 'success',
          icon: 'fa fa-thumbs-up fa-2x',
          container: 'floating',
          title: 'Reclamante foi registrado',
          timer: 4000
        });
      }
    },

    _newReclamante: _ember['default'].computed("reclamavel", "isVisible", function () {
      var store = this.get("store");
      var reclamavel = this.get("reclamavel");
      var pessoa = store.createRecord("pessoa");
      var newReclamante = store.createRecord("juridico/reclamante", { reclamavel: reclamavel, pessoa: pessoa, isSecundario: true });

      return newReclamante;
    }),

    nome: _ember['default'].computed.alias("_newReclamante.pessoa.nome"),
    emails: _ember['default'].computed.alias("_newReclamante.pessoa.emails"),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.isVisible) {
        this.set("_created", true);
      }
    }
  });
});