define('juridico-sengerj/transforms/cnpj', ['exports', 'ember-data/transform', 'ember'], function (exports, _emberDataTransform, _ember) {
  exports['default'] = _emberDataTransform['default'].extend({
    deserialize: function deserialize(cnpj) {
      if (_ember['default'].isPresent(cnpj)) {
        return cnpj.slice(0, 2) + '.' + cnpj.slice(2, 5) + '.' + cnpj.slice(5, 8) + '/' + cnpj.slice(8, 12) + '-' + cnpj.slice(12, 14);
      }
    },

    serialize: function serialize() {
      var deserialized = arguments.length <= 0 || arguments[0] === undefined ? '' : arguments[0];

      return deserialized.replace(/\D/g, "");
    }
  });
});