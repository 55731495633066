define("juridico-sengerj/templates/components/route-components/agendamento-advogado/-calendario", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 9,
            "column": 0
          }
        },
        "moduleName": "juridico-sengerj/templates/components/route-components/agendamento-advogado/-calendario.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "super-simple-calendar", [], ["on-date-choosen", ["subexpr", "action", ["setCalendarChoosenDate"], [], ["loc", [null, [2, 18], [2, 51]]], 0, 0], "on-change-month", ["subexpr", "action", ["calendarMonthChanged"], [], ["loc", [null, [3, 18], [3, 49]]], 0, 0], "blockedDays", ["subexpr", "@mut", [["get", "holidays", ["loc", [null, [4, 14], [4, 22]]], 0, 0, 0, 0]], [], [], 0, 0], "schedulesMade", ["subexpr", "@mut", [["get", "profissionalsSchedules", ["loc", [null, [5, 16], [5, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "isLoadingDates", ["subexpr", "@mut", [["get", "isHolidaysLoading", ["loc", [null, [6, 17], [6, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "chosenDate", ["subexpr", "@mut", [["get", "agendamento.dataHorario", ["loc", [null, [7, 13], [7, 36]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [1, 0], [8, 2]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});