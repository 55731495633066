define('juridico-sengerj/routes/dashboard/pastas/detalhes', ['exports', 'ember', 'juridico-sengerj/mixins/routes/table-listing-with-filters'], function (exports, _ember, _juridicoSengerjMixinsRoutesTableListingWithFilters) {
  exports['default'] = _ember['default'].Route.extend(_juridicoSengerjMixinsRoutesTableListingWithFilters['default'], {
    model: function model(_ref) {
      var pasta_id = _ref.pasta_id;

      var pasta = this.store.findRecord('juridico/pasta', pasta_id);

      return pasta;
    }
  });
});