define("juridico-sengerj/components/juridico/form-valor", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    model: function model() {
      var valor = this.store.createRecord("juridico/valor-por-demanda");

      return this.store.createRecord("juridico/valor-por-demanda", { valor: valor });
    },

    "on-saved": function onSaved() {},

    valorValid: _ember["default"].computed.and("valor.validations.isValid"),

    actions: {
      saveValor: function saveValor() {
        var _this = this;

        this.get("valor").save().then(function (valor) {
          _ember["default"].get(_this, "on-saved")(valor);
        });
      }
    }
  });
});