define('juridico-sengerj/components/button-export-atendimentos-homologacao', ['exports', 'ember', 'juridico-sengerj/components/button-export-model', 'juridico-sengerj/helpers/format-date'], function (exports, _ember, _juridicoSengerjComponentsButtonExportModel, _juridicoSengerjHelpersFormatDate) {
  exports['default'] = _juridicoSengerjComponentsButtonExportModel['default'].extend({
    columns: {
      "Código": "codigoHomologacao",
      "Data Homologação": function DataHomologaO(a) {
        return (0, _juridicoSengerjHelpersFormatDate.formatDate)([a.get("dataHomologacao")]);
      },
      Profissional: "profissional.nome",
      CPF: "profissional.cpf",
      Empresa: "profissionalUnidade.unidade.nomeFantasia",
      "Data Admissão": function DataAdmissO(a) {
        return (0, _juridicoSengerjHelpersFormatDate.formatDate)([a.get("profissionalUnidade.dataAdmissao")]);
      },
      "Data Demissão": function DataDemissO(a) {
        return (0, _juridicoSengerjHelpersFormatDate.formatDate)([a.get("profissionalUnidade.dataDesligamento")]);
      }
    },

    longTextColumns: ["Empresa", "Profissional"],

    title: "Atendimentos Homologação",

    include: "profissional.pessoa, profissional_unidade.unidade.empresa"
  });
});