define('juridico-sengerj/controllers/login/reset-senha', ['exports', 'ember', 'juridico-sengerj/mixins/change-password'], function (exports, _ember, _juridicoSengerjMixinsChangePassword) {
	exports['default'] = _ember['default'].Controller.extend(_juridicoSengerjMixinsChangePassword['default'], {
		queryParams: ['token', 'userId'],
		isSending: false,

		actions: {
			resetSenha: function resetSenha() {
				var _this = this;

				var _getProperties = this.getProperties("userId", "token");

				var userId = _getProperties.userId;
				var token = _getProperties.token;

				this.set("isSending", true);

				this.changePassword(userId, token).then(function () {
					_this.get("router").transitionTo("login");
				})['finally'](function () {
					_this.set("isSending", false);
				});
			}
		}
	});
});