define('juridico-sengerj/transforms/cpf', ['exports', 'ember-data/transform', 'ember'], function (exports, _emberDataTransform, _ember) {
  exports['default'] = _emberDataTransform['default'].extend({
    deserialize: function deserialize(cpf) {
      if (_ember['default'].isPresent(cpf)) {
        return cpf.slice(0, 3) + '.' + cpf.slice(3, 6) + '.' + cpf.slice(6, 9) + '-' + cpf.slice(9, 11);
      }
    },

    serialize: function serialize() {
      var deserialized = arguments.length <= 0 || arguments[0] === undefined ? '' : arguments[0];

      return deserialized.replace(/\D/g, "");
    }
  });
});