define('juridico-sengerj/models/juridico/contrato-escritorio', ['exports', 'juridico-sengerj/models/abstract-model', 'ember-data', 'ember', 'ember-cp-validations'], function (exports, _juridicoSengerjModelsAbstractModel, _emberData, _ember, _emberCpValidations) {

	var Validations = (0, _emberCpValidations.buildValidations)({
		dataInicioVigencia: [(0, _emberCpValidations.validator)('presence', true)],
		copiaContratoAssinadoUrl: [(0, _emberCpValidations.validator)('presence', true)],
		escritorioContabil: [(0, _emberCpValidations.validator)('presence', {
			presence: true,
			disabled: _ember['default'].computed('model.tipo', function () {
				return this.get("model.tipo.value") !== "calculo";
			})
		})],
		escritorio: [(0, _emberCpValidations.validator)('presence', {
			presence: true,
			disabled: _ember['default'].computed('model.tipo', function () {
				return this.get("model.tipo.value") !== "advocacia";
			})
		})],
		tipo: [(0, _emberCpValidations.validator)('presence', true)]
	});

	exports['default'] = _juridicoSengerjModelsAbstractModel['default'].extend(Validations, {
		dataInicioVigencia: _emberData['default'].attr("date"),
		dataFimVigencia: _emberData['default'].attr("date"),
		percentualHonorarioSucumbencia: _emberData['default'].attr("number"),
		valorFixoMensal: _emberData['default'].attr("number"),
		copiaContratoAssinadoUrl: _emberData['default'].attr("string"),
		nomeContratoVigencia: _emberData['default'].attr("string"),
		tipo: _emberData['default'].attr("enum"),
		escritorio: _emberData['default'].belongsTo('juridico/escritorio'),
		escritorioContabil: _emberData['default'].belongsTo('juridico/escritorio'),
		valorPorDemandas: _emberData['default'].hasMany('juridico/valor-por-demanda')
	});
});