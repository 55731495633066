define('juridico-sengerj/models/profissional', ['exports', 'juridico-sengerj/models/abstract-model', 'ember-data', 'ember', 'ember-cp-validations'], function (exports, _juridicoSengerjModelsAbstractModel, _emberData, _ember, _emberCpValidations) {

	var Validations = (0, _emberCpValidations.buildValidations)({
		pessoa: [(0, _emberCpValidations.validator)('belongs-to')],
		naturalidade: [(0, _emberCpValidations.validator)('presence', true)],
		identidade: [(0, _emberCpValidations.validator)('presence', true)],
		orgaoEmissor: [(0, _emberCpValidations.validator)('presence', true)],
		especialidades: [(0, _emberCpValidations.validator)('presence-array', { message: "É necessário informar pelo uma especialidade" })]
	});

	exports['default'] = _juridicoSengerjModelsAbstractModel['default'].extend(Validations, {
		cpf: _emberData['default'].attr('cpf', { readOnly: true }),
		nome: _emberData['default'].attr('string', { readOnly: true }),
		nacionalidade: _emberData['default'].attr('string'),
		ufIdentidade: _emberData['default'].attr('string'),
		naturalidade: _emberData['default'].attr('string'),
		rnp: _emberData['default'].attr('string'),
		identidade: _emberData['default'].attr('string'),
		orgaoEmissor: _emberData['default'].attr('string'),
		crea: _emberData['default'].attr('string'),
		statusCrea: _emberData['default'].attr('string'),
		pessoa: _emberData['default'].belongsTo(),
		socio: _emberData['default'].belongsTo(),
		especialidades: _emberData['default'].hasMany(),

		nomeComCPF: Ember.computed('nome', 'cpf', function () {
			return this.get("nome") + ' (CPF: ' + this.get("cpf") + ')';
		})
	});
});