define('juridico-sengerj/adapters/application', ['exports', 'ember-data', 'juridico-sengerj/config/environment', 'ember-simple-auth/mixins/data-adapter-mixin', 'ember'], function (exports, _emberData, _juridicoSengerjConfigEnvironment, _emberSimpleAuthMixinsDataAdapterMixin, _ember) {
	exports['default'] = _emberData['default'].JSONAPIAdapter.extend(_emberSimpleAuthMixinsDataAdapterMixin['default'], {
		host: _juridicoSengerjConfigEnvironment['default'].ApiHost + '/' + _juridicoSengerjConfigEnvironment['default'].ApiVersion,

		session: _ember['default'].inject.service(),

		raven: _ember['default'].inject.service(),

		authorizer: 'authorizer:token',

		_paramsSentToServer: {},

		pathForType: function pathForType(type) {
			return type.pluralize().underscore();
		},

		query: function query(store, type, _query) {
			var underscoreQuery = {};

			for (var key in _query) {
				underscoreQuery[key.underscore()] = _query[key];
			}

			return this._super(store, type, underscoreQuery);
		},

		ajaxOptions: function ajaxOptions(url, type) {
			var options = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

			this._paramsSentToServer[url] = options.data;
			return this._super.apply(this, arguments);
		},

		handleResponse: function handleResponse(status, headers, payload, requestData) {
			var result = this._super.apply(this, arguments);

			if (this.isInvalid(status)) {
				this.get("raven").captureException(result, {
					extra: {
						errors: payload.errors.map(function (e) {
							return JSON.stringify(e);
						}),
						sentToAPI: this._paramsSentToServer[requestData.url]
					}
				});
			}

			return result;
		},

		isInvalid: function isInvalid(status) {
			return status === 422;
		},

		headers: _ember['default'].computed('session.data.authenticated', function () {
			if (this.get('session.isAuthenticated')) {
				return { "USUARIO-ID": this.get('session.data').authenticated.usuarioId };
			}
		})
	});
});