define('juridico-sengerj/models/juridico/ocorrencia', ['exports', 'juridico-sengerj/models/abstract-model', 'ember-data', 'ember', 'ember-cp-validations'], function (exports, _juridicoSengerjModelsAbstractModel, _emberData, _ember, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    tipoOcorrencia: (0, _emberCpValidations.validator)('presence', true),

    dataOcorrencia: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],

    acordo: (0, _emberCpValidations.validator)('belongs-to', {
      disabled: _ember['default'].computed('model.tipoOcorrencia.descricao', function () {
        return this.get("model.tipoOcorrencia.descricao") !== "Acordo";
      }),

      dependentKeys: ['model.tipoOcorrencia.descricao']
    }),

    prolabore: (0, _emberCpValidations.validator)('belongs-to', {
      disabled: _ember['default'].computed('model.tipoOcorrencia.descricao', function () {
        return this.get("model.tipoOcorrencia.descricao") !== "Prolabore Escrtório";
      }),

      dependentKeys: ['model.tipoOcorrencia.descricao']
    }),

    infoFinanceiro: (0, _emberCpValidations.validator)('belongs-to', {
      disabled: _ember['default'].computed('model.tipoOcorrencia.descricao', function () {
        return !this.get("model.tipoOcorrencia.pertenceFinanceiro");
      }),

      dependentKeys: ['model.infoFinanceiro']
    }),

    substituicaoAdvogado: (0, _emberCpValidations.validator)('belongs-to', {
      disabled: _ember['default'].computed('model.tipoOcorrencia.descricao', function () {
        return this.get("model.tipoOcorrencia.descricao") !== "Substituição de advogado titular";
      }),

      dependentKeys: ['model.tipoOcorrencia.descricao']
    }),

    hora: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: _ember['default'].computed('model.tipoOcorrencia.descricao', function () {
        return !this.get("model.tipoOcorrencia.pedeHora");
      }),
      dependentKeys: ['model.tipoOcorrencia.pedeHora']
    }),

    valor: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: _ember['default'].computed('model.tipoOcorrencia.descricao', function () {
        return !this.get("model.tipoOcorrencia.pedeValor");
      }),
      dependentKeys: ['model.tipoOcorrencia.pedeValor']
    })
  });

  exports['default'] = _juridicoSengerjModelsAbstractModel['default'].extend(Validations, {
    createdAt: _emberData['default'].attr('date'),
    dataOcorrencia: _emberData['default'].attr('date'),
    hora: _emberData['default'].attr("date"),
    valor: _emberData['default'].attr("number"),
    tipoSentenca: _emberData['default'].attr('enum'),
    excluido: _emberData['default'].attr('boolean'),
    nomeAdvogado: _emberData['default'].attr('string'),
    codigoPasta: _emberData['default'].attr('string'),
    gruposEnviadosSentence: _emberData['default'].attr('string'),
    dataGruposEnviadosSentence: _emberData['default'].attr('string'),
    createdAtFormatado: _emberData['default'].attr('string'),
    dataOcorrenciaFormatado: _emberData['default'].attr('string'),

    tipoOcorrencia: _emberData['default'].belongsTo('juridico/tipo-ocorrencia'),
    substituicaoAdvogado: _emberData['default'].belongsTo('juridico/substituicao-advogado'),
    acordo: _emberData['default'].belongsTo('juridico/acordo'),
    prolabore: _emberData['default'].belongsTo('juridico/prolabore'),
    infoFinanceiro: _emberData['default'].belongsTo('juridico/info-financeiro'),
    pasta: _emberData['default'].belongsTo('juridico/pasta'),
    gruposNotificados: _emberData['default'].hasMany('juridico/ocorrencia-grupo-notificado'),

    documentos: _emberData['default'].hasMany('documento'),
    notas: _emberData['default'].hasMany('nota'),

    notNotificadoGrupos: _ember['default'].computed.equal("gruposNotificados.length", 0),

    comentario: _ember['default'].computed.alias("notas.firstObject.descricao"),

    documentosUrl: _ember['default'].computed.mapBy("documentos", "arquivoUrl")

  });
});