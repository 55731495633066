define("juridico-sengerj/components/group-filters", ["exports", "ember"], function (exports, _ember) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var isPresent = _ember["default"].isPresent;
  exports["default"] = _ember["default"].Component.extend({
    filtersValues: {},
    filtersValuesToShow: {},
    applyFilters: function applyFilters() {},

    actions: {
      saveFilter: function saveFilter(name, filterValue) {
        var newFilters = Object.assign({}, this.filtersValues, _defineProperty({}, name, filterValue));
        this.set("filtersValues", newFilters);

        this._sendFilter();
      },

      saveFilterDataToShow: function saveFilterDataToShow(name, filterValue) {
        var newFilters = Object.assign({}, this.filtersValuesToShow, _defineProperty({}, name, filterValue));
        this.set("filtersValuesToShow", newFilters);
      }
    },

    _sendFilter: function _sendFilter() {
      _ember["default"].get(this, "applyFilters")(this.filtersValues);
    },

    init: function init() {
      this._super.apply(this, arguments);
      var queryParams = this.get('router').router.state.fullQueryParams;
      this.set("filtersValues", queryParams);
    }
  });
});