define('juridico-sengerj/models/juridico/advogado/atendimento', ['exports', 'juridico-sengerj/models/abstract-model', 'ember-data', 'ember', 'ember-cp-validations'], function (exports, _juridicoSengerjModelsAbstractModel, _emberData, _ember, _emberCpValidations) {
	var isPresent = _ember['default'].isPresent;
	var isBlank = _ember['default'].isBlank;

	var Validations = (0, _emberCpValidations.buildValidations)({
		historico: [(0, _emberCpValidations.validator)('presence', true)],

		medidas: [(0, _emberCpValidations.validator)('presence', true)]
	});

	exports['default'] = _juridicoSengerjModelsAbstractModel['default'].extend(Validations, {
		historico: _emberData['default'].attr('string'),
		medidas: _emberData['default'].attr('string'),
		documentos: _emberData['default'].attr('string'),
		fichaAtendimentoAssinadaUrl: _emberData['default'].attr('string'),
		peticaoInicialUrl: _emberData['default'].attr('string'),
		fichaAtendimentoAssinadaIdentifier: _emberData['default'].attr('string'),
		numeroProcesso: _emberData['default'].attr('numero-processo'),
		createdAt: _emberData['default'].attr('date'),
		status: _emberData['default'].attr('enum'),
		empresasTemporarias: _emberData['default'].attr('string'),
		agendamento: _emberData['default'].belongsTo('juridico/advogado/agendamento'),
		pasta: _emberData['default'].belongsTo('juridico/pasta'),
		contratosAssinados: _emberData['default'].hasMany('juridico/advogado/atendimento/contrato-assinado'),
		documentosNecessarios: _emberData['default'].hasMany('juridico/advogado/atendimento/documento-necessario'),

		exibirReclamantes: _ember['default'].computed.alias("agendamento.exibirReclamantes"),

		isDocumentosEntregues: _ember['default'].computed('documentosNecessarios.@each.isLoaded', 'documentosNecessarios.@each.enviado', "peticaoInicialUrl", "fichaAtendimentoAssinadaUrl", function () {
			var documentos = this.get("documentosNecessarios");

			var _getProperties = this.getProperties("fichaAtendimentoAssinadaUrl");

			var fichaAtendimentoAssinadaUrl = _getProperties.fichaAtendimentoAssinadaUrl;

			var fichaAndPeticaoEntregue = isPresent(fichaAtendimentoAssinadaUrl);

			return documentos.isEvery("isLoaded", true) && documentos.isEvery('enviado', true) && fichaAndPeticaoEntregue;
		}),

		pastaId: _ember['default'].computed("pasta", function () {
			return this.belongsTo("pasta").id();
		}),

		prontoVirarPasta: _ember['default'].computed("pastaId", "status", "isDocumentosEntregues", function () {
			var _getProperties2 = this.getProperties("pastaId", "status", "isDocumentosEntregues");

			var pastaId = _getProperties2.pastaId;
			var status = _getProperties2.status;
			var isDocumentosEntregues = _getProperties2.isDocumentosEntregues;

			return isBlank(pastaId) && status.get("value") === "possivel_processo" && isDocumentosEntregues;
		})
	});
});