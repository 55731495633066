define('juridico-sengerj/components/context-menu-item', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'li',
    divider: false,
    disabled: false,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.divider) {
        this.$().addClass("divider");
      }
      if (this.disabled) {
        this.$().addClass("disabled");
      }
    }
  });
});