define('juridico-sengerj/mixins/components/dynamic-field', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    inputDelay: 600,
    fieldUpdatedOnTime: function fieldUpdatedOnTime() {},
    fieldKeyUpdatedOnTime: 'value',
    _fieldValue: null,
    _inputTimer: null,

    _turnOnTimerSendAction: function _turnOnTimerSendAction() {
      var _this = this;

      this.stopInputTimer();
      this.set('_inputTimer', setTimeout(function () {
        _this.fieldUpdatedOnTime();
        _this.stopInputTimer();
      }, this.inputDelay));
    },

    isTimerRunning: _ember['default'].computed.notEmpty('_inputTimer'),

    isTimerNotRunning: _ember['default'].computed.not('isTimerRunning'),

    _stopInputTimerOnDestroy: (function () {
      this.stopInputTimer();
    }).on('willDestroyElement'),

    stopInputTimer: function stopInputTimer() {
      clearTimeout(this._inputTimer);
      clearInterval(this._inputTimer);
      this.set("_inputTimer", null);
    },

    init: function init() {
      this._super.apply(this, arguments);

      this.addObserver(this.fieldKeyUpdatedOnTime, this._turnOnTimerSendAction);
    }
  });
});