define('juridico-sengerj/components/form-address', ['exports', 'ember'], function (exports, _ember) {

  var SEARCHING_ADDRESS = 'Buscando...';

  exports['default'] = _ember['default'].Component.extend({
    ajax: _ember['default'].inject.service(),

    address: null,
    isLoadingCep: false,
    showValidation: false,

    actions: {
      fillAddressInfoViaCep: function fillAddressInfoViaCep(cep) {
        var _this = this;

        cep = cep.replace(/\D/g, "");

        if (cep.length === 8) {
          this._setAddressInfo(cep);
          this.toggleProperty("isLoadingCep");
          this.get('ajax').request('https://viacep.com.br/ws/' + cep + '/json/?callback=?').then(function (addressViaCep) {
            _this.set("showValidation", true);
            _this._setAddressInfo(cep, addressViaCep);
            _this.toggleProperty("isLoadingCep");
          });
        }
      }
    },

    cep: _ember['default'].computed.oneWay("address.cep"),

    _setAddressInfo: function _setAddressInfo(cep) {
      var _ref = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      var logradouro = _ref.logradouro;
      var bairro = _ref.bairro;
      var localidade = _ref.localidade;
      var uf = _ref.uf;

      this.address.setProperties({
        cep: cep,
        tipoEndereco: { value: "residencial", text: "Residencial" },
        logradouro: logradouro || SEARCHING_ADDRESS,
        bairroNome: bairro || SEARCHING_ADDRESS,
        cidadeNome: localidade || SEARCHING_ADDRESS,
        ufNome: uf || SEARCHING_ADDRESS
      });
    }
  });
});