define("juridico-sengerj/helpers/gender-condicional", ["exports", "ember"], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; })();

  exports.genderCondicional = genderCondicional;

  function genderCondicional(_ref, _ref3) {
    var _ref2 = _slicedToArray(_ref, 2);

    var _ref2$0 = _ref2[0];
    var opecaoMasculino = _ref2$0 === undefined ? "o" : _ref2$0;
    var _ref2$1 = _ref2[1];
    var opecaoFeminino = _ref2$1 === undefined ? "a" : _ref2$1;
    var person = _ref3.person;

    return person.get("sexo.value") === "masculino" ? opecaoMasculino : opecaoFeminino;
  }

  exports["default"] = _ember["default"].Helper.helper(genderCondicional);
});