define('juridico-sengerj/models/abstract-model', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Model.extend({
    save: function save() {
      var _this = this;

      var _ref = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      var force = _ref.force;

      if (force || this.get("hasDirtyAttributes")) {
        return this._super();
      } else {
        return new _ember['default'].RSVP.Promise(function (resolve) {
          return resolve(_this);
        });
      }
    }
  });
});