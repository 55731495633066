define('juridico-sengerj/controllers/dashboard/objeto-acoes/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: ['porId'],

    columnsTitle: ["Nome"],

    objetoAcoes: null,
    objetoAcoesModalVisible: false,

    actions: {

      openModalObjetoAcoes: function openModalObjetoAcoes() {

        var objetoAcoes = this.store.createRecord('juridico/objeto-acao');

        this.setProperties({ objetoAcoesModalVisible: true, objetoAcoes: objetoAcoes });
      },

      criarObjetoAcoes: function criarObjetoAcoes() {
        var _this = this;

        this.objetoAcoes.save().then(function () {
          _this.set("objetoAcoesModalVisible", false);

          _this.container.lookup("route:dashboard.objeto-acoes").refresh();

          _ember['default'].$.niftyNoty({
            type: 'success',
            icon: 'fa fa-exclamation-triangle fa-2x',
            container: "floating",
            title: 'O tipo de documento foi criado com sucesso.',
            timer: 3000
          });
        });
      },

      removerObjetoAcoes: function removerObjetoAcoes(objetoAcoes) {
        this.store.find('juridico/objeto-acao', objetoAcoes.id).then(function (objetoAcoes) {
          objetoAcoes.destroyRecord();

          _ember['default'].$.niftyNoty({
            type: 'success',
            icon: 'fa fa-exclamation-triangle fa-2x',
            container: "floating",
            title: 'O tipo de documento foi removido com sucesso.',
            timer: 3000
          });
        });
      },

      changeValue: function changeValue(objetoAcoes) {
        this.store.find('juridico/objeto-acao', objetoAcoes.id).then(function (objetoAcoes) {
          objetoAcoes.set('nome', objetoAcoes.get('nome'));
          objetoAcoes.save();

          _ember['default'].$.niftyNoty({
            type: 'success',
            icon: 'fa fa-exclamation-triangle fa-2x',
            container: "floating",
            title: 'O tipo de documento foi atualizado com sucesso.',
            timer: 3000
          });
        });
      },

      rollbackValue: function rollbackValue(objetoAcoes) {
        this.store.peekRecord('juridico/objeto-acao', objetoAcoes.id).rollbackAttributes();
      }
    }
  });
});