define('juridico-sengerj/components/bs-modal-footer', ['exports', 'ember', 'ember-bootstrap/components/bs-modal-footer'], function (exports, _ember, _emberBootstrapComponentsBsModalFooter) {
  exports['default'] = _emberBootstrapComponentsBsModalFooter['default'].extend({
    submit: function submit(e) {
      if (typeof this.submitAction === 'string') {
        this._super.apply(this, arguments);
      } else {
        e.preventDefault();
        _ember['default'].get(this, "submitAction")();
      }
    }
  });
});