define('juridico-sengerj/models/empresa', ['exports', 'juridico-sengerj/models/abstract-model', 'ember-data', 'ember-cp-validations'], function (exports, _juridicoSengerjModelsAbstractModel, _emberData, _emberCpValidations) {

	var Validations = (0, _emberCpValidations.buildValidations)({
		razaoSocial: [(0, _emberCpValidations.validator)('presence', true)],

		nomeFantasia: [(0, _emberCpValidations.validator)('presence', true)]
	});

	exports['default'] = _juridicoSengerjModelsAbstractModel['default'].extend(Validations, {
		codigo: _emberData['default'].attr('string'),
		razaoSocial: _emberData['default'].attr('string'),
		nomeFantasia: _emberData['default'].attr('string'),
		matricula: _emberData['default'].attr('string'),
		cnpj: _emberData['default'].attr('cnpj'),
		createdAt: _emberData['default'].attr(),
		setorEmpresa: _emberData['default'].belongsTo(),
		atuacaoEmpresa: _emberData['default'].belongsTo(),
		entidade: _emberData['default'].belongsTo()
	});
});