define('juridico-sengerj/models/juridico/banco-pagador', ['exports', 'juridico-sengerj/models/abstract-model', 'ember-data', 'ember', 'ember-cp-validations'], function (exports, _juridicoSengerjModelsAbstractModel, _emberData, _ember, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    nomeBanco: (0, _emberCpValidations.validator)('presence', true),
    endereco: (0, _emberCpValidations.validator)('presence', true)
  });

  exports['default'] = _juridicoSengerjModelsAbstractModel['default'].extend(Validations, {
    nomeBanco: _emberData['default'].attr('string'),
    endereco: _emberData['default'].attr("string"),
    nomeComEndereco: _emberData['default'].attr("string")
  });
});