define('juridico-sengerj/components/progress-bar', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var htmlSafe = _ember['default'].String.htmlSafe;
  exports['default'] = _ember['default'].Component.extend({
    color: "info",
    progress: 0,
    current: 0,
    total: 1,

    classNames: ['progress'],

    progressPercentage: computed('progress', 'current', 'total', function () {
      var progress = this.progress ? this.progress : this.current / this.total * 100;

      return Math.round(progress) + '%';
    }),

    progressPercentageStyle: computed('progressPercentage', function () {
      return htmlSafe('width:' + this.get("progressPercentage"));
    })
  });
});