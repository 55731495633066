define('juridico-sengerj/models/juridico/pasta-avulsa', ['exports', 'juridico-sengerj/models/abstract-model', 'juridico-sengerj/utils/exibir-reclamantes-computed', 'ember-data'], function (exports, _juridicoSengerjModelsAbstractModel, _juridicoSengerjUtilsExibirReclamantesComputed, _emberData) {
	exports['default'] = _juridicoSengerjModelsAbstractModel['default'].extend({
		ataAssembleia: _emberData['default'].attr('string'),
		numeroProcesso: _emberData['default'].attr('numero-processo'),
		createdAt: _emberData['default'].attr(),
		tipo: _emberData['default'].attr('enum'),
		pasta: _emberData['default'].belongsTo('juridico/pasta'),
		advogado: _emberData['default'].belongsTo('juridico/advogado'),
		ramoDireito: _emberData['default'].belongsTo('juridico/ramo-direito'),
		reclamantes: _emberData['default'].hasMany('juridico/reclamante'),
		reclamantesEmMassa: _emberData['default'].hasMany('juridico/reclamante-em-massa'),

		escritorio: Ember.computed.alias("advogado.escritorio"),

		exibirReclamantes: (0, _juridicoSengerjUtilsExibirReclamantesComputed['default'])()
	});
});