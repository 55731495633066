define('juridico-sengerj/models/juridico/homologacao/configuracao-homologacao', ['exports', 'juridico-sengerj/models/abstract-model', 'ember-data', 'ember'], function (exports, _juridicoSengerjModelsAbstractModel, _emberData, _ember) {

  var WEEK_DAYS = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

  exports['default'] = _juridicoSengerjModelsAbstractModel['default'].extend({
    intervalo: _emberData['default'].attr(),
    horarioInicio: _emberData['default'].attr('string'),
    horarioFim: _emberData['default'].attr('string'),
    horarioInicioAlmoco: _emberData['default'].attr('string'),
    horarioFimAlmoco: _emberData['default'].attr('string'),
    dataLimite: _emberData['default'].attr('date'),
    monday: _emberData['default'].attr('boolean'),
    tuesday: _emberData['default'].attr('boolean'),
    wednesday: _emberData['default'].attr('boolean'),
    thursday: _emberData['default'].attr('boolean'),
    friday: _emberData['default'].attr('boolean'),
    saturday: _emberData['default'].attr('boolean'),
    sunday: _emberData['default'].attr('boolean'),
    duracaoPreenchimento: _emberData['default'].attr(),

    avaibleDaysWeek: _ember['default'].computed.apply(_ember['default'], WEEK_DAYS.concat([function () {
      var _this = this;

      return WEEK_DAYS.map(function (dayWeek) {
        return _this.get(dayWeek);
      });
    }]))
  });
});