define("juridico-sengerj/utils/pages-loader", ["exports", "ember"], function (exports, _ember) {
  exports.loadPagesInParallel = loadPagesInParallel;
  exports.loadPages = loadPages;

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var RSVP = _ember["default"].RSVP;

  function loadPagesInParallel(recordsPromise) {
    return recordsPromise.then(getPagesPromisesInParallel).then(waitForAllPromises);
  }

  function loadPages(recordsPromise) {
    return recordsPromise.then(getPagesPromisesInChain).then(waitForAllPromises);
  }

  function waitForAllPromises(promises) {
    return {
      promises: promises,
      records: RSVP.all(promises).then(function (allRecords) {
        return allRecords.reduce(function (allRecords, records) {
          return allRecords.concat(records.toArray());
        }, []);
      })
    };
  }

  function getPagesPromisesInParallel(records) {
    return [RSVP.resolve(records)].concat(_toConsumableArray(getPagesToLoad(records).map(function (page) {
      return loadPage(records, page);
    })));
  }

  function getPagesPromisesInChain(records) {
    return getPagesToLoad(records).reduce(function (promises, _, i) {
      return promises.concat([promises[i].then(loadNextPage)]);
    }, [RSVP.resolve(records)]);
  }

  function getPagesToLoad(_ref) {
    var totalPages = _ref.meta["total-pages"];

    return [].concat(_toConsumableArray(Array(totalPages).keys())).map(function (p) {
      return p + 1;
    }).slice(1);
  }

  function loadNextPage(records) {
    return loadPage(records, records.meta['current-page'] + 1);
  }

  function loadPage(records, page) {
    return records.store.query(records.get("firstObject._internalModel.modelName"), Object.assign({}, records.query, { page: page }));
  }
});