define('juridico-sengerj/router', ['exports', 'ember', 'juridico-sengerj/config/environment'], function (exports, _ember, _juridicoSengerjConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _juridicoSengerjConfigEnvironment['default'].locationType,
    rootURL: _juridicoSengerjConfigEnvironment['default'].rootURL,
    deployTarget: _juridicoSengerjConfigEnvironment['default'].deployTarget
  });

  Router.map(function () {
    this.route('login', { path: '/' });
    this.route('login', { path: 'login' }, function () {
      this.route('novo-usuario');
      this.route('esqueceu-senha');
      this.route('reset-senha');
    });
    this.route('dashboard', function () {
      this.route('pastas', function () {
        this.route('detalhes', { path: "detalhes/:pasta_id" });
        this.route('novo', { path: "novo/:atendimento_id" });
        this.route('nova-avulsa');
      });
      this.route('usuarios', function () {
        this.route('usuario', { path: ":usuario_id" });
        this.route('novo', { path: "novo/:permissao" });
      });

      this.route('usuario', function () {});

      this.route('ramo-direitos', function () {});

      this.route('objeto-acoes', function () {});

      this.route('tipo-reclamacoes', function () {});

      this.route('tipo-documentos', function () {});

      this.route('tipo-encerramentos', function () {});

      this.route('tipo-ocorrencias', function () {});

      this.route('contrato-escritorios', function () {
        this.route('novo');
        this.route('contrato-escritorio', { path: 'contrato/:contrato_escritorio_id' });
      });

      this.route('banco-pagadores', function () {
        this.route('novo');
      });

      this.route('relatorio-envio-ocorrencias', function () {});
      this.route('alvaras', function () {});

      this.route('reclamante-contratos', function () {});

      this.route('agendamentos', function () {
        this.route('advogados', function () {
          this.route('novo');
          this.route('agendamento', { path: 'agendamento/:agendamento_id' });
        });
        this.route('homologacoes', function () {
          this.route('novo');
          this.route('agendamento', { path: 'agendamento/:agendamento_id' });
        });
      });

      this.route('feriados', function () {});
      this.route('configuracao-homologacoes', function () {});

      this.route('atendimentos', function () {
        this.route('advogados', function () {
          this.route('novo', { path: "agendamento/:agendamento_id/novo" });
          this.route('atendimento', { path: "atendimento/:atendimento_id" });
        });
        this.route('homologacoes', function () {
          this.route('novo');
          this.route('atendimento', { path: "atendimento/:atendimento_id" });
        });
      });
      this.route('inicio', function () {
        this.route('admin', function () {});
        this.route('advogado', function () {
          this.route('pastas');
          this.route('agendamentos');
          this.route('atendimentos');
        });
        this.route('funcionario-empresa', function () {});
        this.route('funcionario-juridico', function () {});
        this.route('profissional', function () {});
        this.route('escritorio', function () {});
      });
      this.route('escritorios', function () {
        this.route('escritorio', { path: 'escritorio/:escritorio_id' });
      });
      this.route('advogados', function () {});
    });

    this.route('dasboard', function () {
      this.route('inicio', function () {});
    });
  });

  exports['default'] = Router;
});