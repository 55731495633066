define('juridico-sengerj/routes/dashboard/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.session.get("currentUser");
    },

    afterModel: function afterModel(usuario) {
      var perfil = usuario.getProperties('isAdmin', 'isAdvogado', 'isFuncionarioEmpresa', 'isFuncionarioJuridico', 'isPessoa', 'isEscritorio', 'isAdvogadoPleno');

      if (perfil.isAdmin) {
        this.transitionTo('dashboard.inicio.admin');
      } else if (perfil.isEscritorio) {
        this.transitionTo('dashboard.inicio.escritorio');
      } else if (perfil.isAdvogado) {
        this.transitionTo('dashboard.inicio.advogado.agendamentos');
      } else if (perfil.isAdvogadoPleno) {
        this.transitionTo('dashboard.inicio.advogado.agendamentos');
      } else if (perfil.isFuncionarioEmpresa) {
        this.transitionTo('dashboard.inicio.funcionario-empresa');
      } else if (perfil.isFuncionarioJuridico) {
        this.transitionTo('dashboard.inicio.funcionario-juridico');
      } else if (perfil.isPessoa) {
        this.transitionTo('dashboard.inicio.profissional');
      }
    }
  });
});