define('juridico-sengerj/components/tables/table-reclamantes', ['exports', 'ember', 'juridico-sengerj/config/environment'], function (exports, _ember, _juridicoSengerjConfigEnvironment) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports['default'] = _ember['default'].Component.extend({
    columns: ["Nome", "CPF", "Matrícula Sócio", "Percentual Cobrado", "Percentual Cobrado Tramitação"],

    briefColumns: ["Nome", "CPF"],

    filters: {},

    newReclamantes: [],

    reclamantes: [],

    'on-create': function onCreate() {},

    'on-delete': function onDelete() {},

    isBrief: false,

    hasSelection: false,

    hasActions: true,

    onlyOneReclamante: false,

    remoteSave: true,

    foundProfissional: null,

    showSecundarioColumn: false,

    showAutorizadoColumn: false,

    _isOpenReclamanteModal: false,

    _choosenReclamante: null,

    contratoAssinadoModalVisible: false,

    reclamanteModal: null,

    store: _ember['default'].inject.service(),

    reclamantesUrl: _juridicoSengerjConfigEnvironment['default'].ApiHost + '/v1/juridico/reclamantes/',

    actions: {

      openModalContratoAssinado: function openModalContratoAssinado(reclamante) {
        var reclamanteContrato = this.get("store").createRecord('juridico/reclamante-contrato', { reclamante: reclamante });

        this.setProperties({ contratoAssinadoModalVisible: true, reclamanteContrato: reclamanteContrato, reclamanteModal: reclamante });
      },

      inserirReclamanteContrato: function inserirReclamanteContrato() {
        var _this = this;

        this.reclamanteContrato.save().then(function () {
          _this.set("contratoAssinadoModalVisible", false);
          _this.reclamanteModal.reload();

          _ember['default'].$.niftyNoty({
            type: 'success',
            icon: 'fa fa-exclamation-triangle fa-2x',
            container: "floating",
            title: 'Contrato assinado inserido com sucesso.',
            timer: 3000
          });
        });
      },

      saveReclamante: function saveReclamante(reclamante) {
        this._saveReclamante(reclamante);
      },

      removeReclamante: function removeReclamante(reclamante) {
        var _this2 = this;

        reclamante.destroyRecord().then(function () {
          _ember['default'].get(_this2, "on-delete")(reclamante);
        });
      },

      changeSecundarioState: function changeSecundarioState(reclamante) {
        reclamante.toggleProperty("isSecundario");

        if (!reclamante.get("isNew")) {
          this._saveReclamante(reclamante);
        }
      },

      setChoosenReclamante: function setChoosenReclamante(reclamante) {
        var motivo;
        return regeneratorRuntime.async(function setChoosenReclamante$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.next = 2;
              return regeneratorRuntime.awrap(reclamante.get("motivoAfastamento"));

            case 2:
              motivo = context$1$0.sent;

              if (motivo === null) {
                reclamante.set("motivoAfastamento", this.get("store").createRecord("juridico/motivo-afastamento", { reclamante: reclamante }));
              }

              this.set("_choosenReclamante", reclamante);

            case 5:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      },

      removeAfastamento: function removeAfastamento(reclamante) {
        var motivo;
        return regeneratorRuntime.async(function removeAfastamento$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.next = 2;
              return regeneratorRuntime.awrap(reclamante.get("motivoAfastamento"));

            case 2:
              motivo = context$1$0.sent;

              motivo.destroyRecord().then(function () {
                _ember['default'].$.niftyNoty({
                  type: 'success',
                  icon: 'fa fa-exclamation-triangle fa-2x',
                  container: "floating",
                  title: 'O afastamento do reclamante foi cancelado com sucesso.',
                  timer: 3000
                });
              });

            case 4:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      },

      saveMotivoAfastamento: function saveMotivoAfastamento() {
        return this.get("_choosenReclamante.motivoAfastamento").then(function (motivo) {
          return motivo.save();
        }, _ember['default'].$.niftyNoty({
          type: 'success',
          icon: 'fa fa-exclamation-triangle fa-2x',
          container: "floating",
          title: 'O reclamante foi afastado com sucesso.',
          timer: 3000
        }));
      }
    },

    contratoAssinadoModalTitle: _ember['default'].computed("contratoAssinadoModal", function () {
      return 'Inserir Contrato Assinado';
    }),

    _saveReclamante: function _saveReclamante(reclamante) {
      if (this.remoteSave) {
        if (reclamante.get("isNew")) {
          _ember['default'].get(this, 'on-create')();
        }
        reclamante.save();
      }
    },

    reclamavel: _ember['default'].computed("reclamantes.content.record", function () {
      return this.get("reclamantes.content.record").getRecord();
    }),

    _reclamantes: _ember['default'].computed("filters", "reclamavel", "reclamantes.[]", "reclamavel.isNew", function () {
      if (this.get("reclamavel.isNew")) {
        return this.get("reclamantes");
      } else {
        return this.get("_reclamantesQuery");
      }
    }),

    _reclamantesQuery: _ember['default'].computed("filters", function () {
      return this.get("store").query("juridico/reclamante", this.filters);
    }),

    init: function init() {
      this._super.apply(this, arguments);
      if (this.showSecundarioColumn) {
        this.set("columns", [].concat(_toConsumableArray(this.get("columns")), ["Secundário?"]));
        this.set("briefColumns", [].concat(_toConsumableArray(this.get("briefColumns")), ["Secundário?"]));
      }

      if (this.showAutorizadoColumn) {
        this.set("columns", [].concat(_toConsumableArray(this.get("columns")), ["Autorizado?"]));
        this.set("briefColumns", [].concat(_toConsumableArray(this.get("briefColumns")), ["Autorizado?"]));
      }
    }
  });
});