define('juridico-sengerj/models/juridico/pasta', ['exports', 'ember', 'juridico-sengerj/models/abstract-model', 'ember-data', 'npm:string-just', 'ember-cp-validations'], function (exports, _ember, _juridicoSengerjModelsAbstractModel, _emberData, _npmStringJust, _emberCpValidations) {
	var computed = _ember['default'].computed;
	var isPresent = _ember['default'].isPresent;

	var Validations = (0, _emberCpValidations.buildValidations)({
		// numeroProcesso:  [
		// 	validator('presence', false),
		// 	validator('unique', { scope: 'porNumeroProcesso', message: 'Esse número de processo já se encontra no sistema'})
		// ],
		ramoDireito: (0, _emberCpValidations.validator)('presence', true),
		tipo: (0, _emberCpValidations.validator)('presence', true),
		advogado: (0, _emberCpValidations.validator)('presence', true),
		escritorio: (0, _emberCpValidations.validator)('presence', true),
		tipoReclamacoes: (0, _emberCpValidations.validator)('presence-array', { message: 'É necessário informar pelo um Tipo da Ação' }),
		objetosAcao: (0, _emberCpValidations.validator)('presence-array', { message: 'É necessário informar pelo um Objeto da Ação' }),
		reclamantes: (0, _emberCpValidations.validator)('presence-array', {
			message: 'Informe um reclamante',
			disabled: _ember['default'].computed.not('model.isIndividual')
		})
	});

	exports['default'] = _juridicoSengerjModelsAbstractModel['default'].extend(Validations, {
		codigo: _emberData['default'].attr('string'),
		createdAt: _emberData['default'].attr('date'),
		status: _emberData['default'].attr('enum'),
		jurisdicao: _emberData['default'].attr('enum', { readOnly: true }),
		reclamantesCount: _emberData['default'].attr('number'),
		reclamantesCountString: _emberData['default'].attr('string'),
		reclamantesExibeNome: _emberData['default'].attr('string'),
		ocorrenciasCount: _emberData['default'].attr('number'),
		ocorrenciasExcluidasCount: _emberData['default'].attr('number'),
		reusCount: _emberData['default'].attr('number'),
		reclamantesSecundariosCount: _emberData['default'].attr('number'),
		herdeirosCount: _emberData['default'].attr('number'),
		reclamantesTerceirosCount: _emberData['default'].attr('number'),
		possuiArquivoAcompanhamento: _emberData['default'].attr('boolean'),
		ganhoPecuniario: _emberData['default'].attr('boolean'),
		percentualCobradoSocioEmDia: _emberData['default'].attr('number'),
		percentualCobradoSocioNaoEmDiaOuProfissional: _emberData['default'].attr('number'),
		dataLimiteFiliacaoAlteracaoDinamicaPercentualTfs: _emberData['default'].attr('date'),
		atendimento: _emberData['default'].belongsTo('juridico/advogado/atendimento'),
		pastaAvulsa: _emberData['default'].belongsTo('juridico/pasta-avulsa'),
		encerramento: _emberData['default'].belongsTo('juridico/pasta-encerrada'),
		orgaoJustica: _emberData['default'].belongsTo('juridico/orgao-justica'),
		ocorrencias: _emberData['default'].hasMany('juridico/ocorrencia'),
		objetosAcao: _emberData['default'].hasMany('juridico/objeto-acao'),
		reus: _emberData['default'].hasMany('juridico/reu'),
		tipoReclamacoes: _emberData['default'].hasMany('juridico/tipo-reclamacao'),
		percentualRepasseTfsEscritorioAdvocacia: _emberData['default'].attr('number'),
		percentualRepasseTfsEscritorioCalculo: _emberData['default'].attr('number'),
		percentualRepasseTfsSenge: _emberData['default'].attr('number'),
		percentualRepasseSengeParaAdvocacia: _emberData['default'].attr('number'),
		valorCondenacao: _emberData['default'].attr('number'),
		percentualRepasseSengeParaCalculo: _emberData['default'].attr('number'),
		percentualRepasseSucumbenciaAdvocaciaParaSenge: _emberData['default'].attr('number'),
		percentualCobradoTramitacaoTsbProfissional: _emberData['default'].attr('number'),
		percentualCobradoTramitacaoTsbSocio: _emberData['default'].attr('number'),
		prolabore: _emberData['default'].attr('number'),
		parcelasProlabore: _emberData['default'].attr('number'),
		sociosEmDiaCountString: _emberData['default'].attr('string'),
		sociosNaoEmDiaCountString: _emberData['default'].attr('string'),
		naoSociosCountString: _emberData['default'].attr('string'),
		numeroProcesso: _emberData['default'].attr('string'),

		reclamantes: computed.alias("dadosAtendimento.reclamantes"),

		tipo: computed.alias("dadosAtendimento.tipo"),

		ramoDireito: computed.alias("dadosAtendimento.ramoDireito"),

		advogado: computed.alias("dadosAtendimento.advogado"),

		escritorio: computed.alias("dadosAtendimento.escritorio"),

		isIndividual: computed.equal("tipo.value", "individual"),

		reclamadaPrincipal: computed.alias("reus.firstObject.nome"),

		objetosAcaoPrincipal: computed.alias("objetosAcao.firstObject.nome"),

		cabecaProcesso: computed('reclamantes', 'tipo', function () {
			if (this.get("tipo.value") === "coletivo") {
				return "SENGE-RJ";
			} else {
				return this.get("reclamantes.firstObject.nome");
			}
		}),

		reusEmpresas: computed('reus', function () {
			return this.get("reus").filterBy("isPessoa", false);
		}),

		verificaAvulsa: computed("hasPastaAvulsa", function () {
			return this.get("hasPastaAvulsa") ? "Pasta Migrada" : this.get("atendimento.agendamento.mostrarCodigo");
		}),

		dadosAtendimento: computed("hasPastaAvulsa", function () {
			return this.get("hasPastaAvulsa") ? this.get("pastaAvulsa") : this.get("atendimento.agendamento");
		}),

		showCode: computed("codigo", function () {
			if (this.get('createdAt')) {
				return _npmStringJust['default'].rjust(this.get('codigo'), 4, '0') + '/' + this.get('createdAt').getFullYear().toString().slice(2, 4);
			}
		}),

		simpleCode: computed("codigo", function () {
			return 'P' + _npmStringJust['default'].rjust(this.get('codigo'), 4, '0') + this.get('createdAt').getFullYear().toString().slice(2, 4);
		}),

		hasPastaAvulsa: computed("pastaAvulsa", function () {
			return isPresent(this.belongsTo("pastaAvulsa").id()) || isPresent(this.get("pastaAvulsa.content"));
		})
	});
});