define('juridico-sengerj/initializers/raven', ['exports', 'juridico-sengerj/config/environment'], function (exports, _juridicoSengerjConfigEnvironment) {
  exports.initialize = initialize;

  function initialize() {
    var application = arguments[1] || arguments[0];
    var _config$sentry$serviceName = _juridicoSengerjConfigEnvironment['default'].sentry.serviceName;
    var serviceName = _config$sentry$serviceName === undefined ? 'raven' : _config$sentry$serviceName;

    var lookupName = 'service:' + serviceName;
    var _config$sentry$exposedPropertyName = _juridicoSengerjConfigEnvironment['default'].sentry.exposedPropertyName;
    var exposedPropertyName = _config$sentry$exposedPropertyName === undefined ? 'raven' : _config$sentry$exposedPropertyName;

    application.inject('route', exposedPropertyName, lookupName);
    application.inject('component', exposedPropertyName, lookupName);
    application.inject('controller', exposedPropertyName, lookupName);
    application.inject('model', exposedPropertyName, lookupName);
  }

  exports['default'] = {
    initialize: initialize,
    name: 'raven'
  };
});