define("juridico-sengerj/templates/components/simple-image-upload", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 0
          }
        },
        "moduleName": "juridico-sengerj/templates/components/simple-image-upload.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "wrapper");
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("img");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        dom.setAttribute(el2, "class", "texto-upload-perfil");
        var el3 = dom.createTextNode("Clique Para Alterar a Foto");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element0, 'src');
        morphs[1] = dom.createAttrMorph(element0, 'class');
        morphs[2] = dom.createElementMorph(element0);
        morphs[3] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        return morphs;
      },
      statements: [["attribute", "src", ["get", "src", ["loc", [null, [2, 36], [2, 39]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["get", "imageClasses", ["loc", [null, [2, 50], [2, 62]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["cloneBotao"], [], ["loc", [null, [2, 6], [2, 29]]], 0, 0], ["inline", "simple-uploader", [], ["progress", ["subexpr", "@mut", [["get", "progress", ["loc", [null, [5, 27], [5, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "isUploading", ["subexpr", "@mut", [["get", "isUploading", ["loc", [null, [5, 48], [5, 59]]], 0, 0, 0, 0]], [], [], 0, 0], "finished-uploading", ["subexpr", "@mut", [["get", "finished-uploading", ["loc", [null, [5, 79], [5, 97]]], 0, 0, 0, 0]], [], [], 0, 0], "folder", ["subexpr", "@mut", [["get", "folder", ["loc", [null, [5, 105], [5, 111]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "hideb uploader"], ["loc", [null, [5, 0], [5, 136]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});