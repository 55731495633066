define('juridico-sengerj/components/toggleable-row-table/row', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: "tr",
    classNames: ['table-visible-row'],
    "on-click": function onClick() {},

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.$("td:first").prepend('<span class="pull-left"> <i class="fa toggleable-icon fa-lg fa-plus-circle"></i> </span>');
    },

    click: function click() {
      this.$("span i.toggleable-icon").toggleClass("fa-plus-circle").toggleClass("fa-minus-circle");
      _ember['default'].get(this, "on-click")();
    }
  });
});