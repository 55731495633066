define("juridico-sengerj/components/panels/panel-reclamantes", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    reclamantes: null,
    remoteSave: true,
    required: true,
    color: "first",
    filters: {},

    tipoReclamavel: _ember["default"].computed.alias("_reclamavel.tipo.value"),

    reclamavelIndividual: _ember["default"].computed.equal("tipoReclamavel", "individual"),

    actions: {
      addReclamante: function addReclamante(reclamante) {
        if (!this.get("remoteSave")) {
          this.get("reclamantes").pushObject(reclamante);
        }
      }
    },

    isAddReclamanteDisabled: _ember["default"].computed("tipoReclamavel", "reclamantes.[]", function () {
      var reclamantesLength = this.get("reclamantes.length");

      if (this.get("tipoReclamavel") == "plurimo") {
        return reclamantesLength >= 10;
      } else {
        return reclamantesLength >= 1;
      }
    }),

    _reclamavel: _ember["default"].computed("reclamantes", function () {
      return this.get("reclamantes.content.record").getRecord();
    })
  });
});