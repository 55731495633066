define('juridico-sengerj/routes/dashboard/escritorios/escritorio', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(_ref) {
      var escritorio_id = _ref.escritorio_id;

      return this.store.findRecord("juridico/escritorio", escritorio_id, {
        include: 'emails, telefones, advogados.usuario.pessoa, empresa, responsaveis.pessoa'
      });
    },

    actions: {
      escritorioSaved: function escritorioSaved() {
        _ember['default'].$.niftyNoty({
          type: 'success',
          icon: 'fa fa-exclamation-triangle fa-2x',
          container: "floating",
          title: 'O Escritório foi alterado.',
          timer: 3000
        });

        this.get('router').transitionTo('dashboard.escritorios.index');
      }
    }
  });
});