define('juridico-sengerj/components/button-export-agendamentos-homologacao', ['exports', 'ember', 'juridico-sengerj/components/button-export-model', 'juridico-sengerj/helpers/format-date', 'juridico-sengerj/helpers/format-time'], function (exports, _ember, _juridicoSengerjComponentsButtonExportModel, _juridicoSengerjHelpersFormatDate, _juridicoSengerjHelpersFormatTime) {
  exports['default'] = _juridicoSengerjComponentsButtonExportModel['default'].extend({
    columns: {
      Profissional: "nomeProfissional",
      Empresa: "nomeFantasiaEmpresa",
      CPF: "cpf",
      Data: function Data(a) {
        return (0, _juridicoSengerjHelpersFormatDate.formatDate)([a.get("dataHorario")]);
      },
      "Horário": function HorRio(a) {
        return (0, _juridicoSengerjHelpersFormatTime.formatTime)([a.get("dataHorario")]);
      },
      Status: "statusReserva.text"
    },

    longTextColumns: ["Empresa", "Profissional"],

    title: "Agendamentos Homologação",

    include: "profissional.pessoa, profissional_unidade.unidade.empresa"
  });
});