define('juridico-sengerj/controllers/dashboard/feriados/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: ['porId'],

    columnsTitle: ["Nome", "Data"],

    feriado: null,
    feriadoModalVisible: false,

    actions: {

      openModalFeriado: function openModalFeriado() {

        var feriado = this.store.createRecord('juridico/homologacao/feriado');

        this.setProperties({ feriadoModalVisible: true, feriado: feriado });
      },

      criarFeriado: function criarFeriado() {
        var _this = this;

        this.feriado.save().then(function () {
          _this.set("feriadoModalVisible", false);

          _this.container.lookup("route:dashboard.feriados").refresh();

          _ember['default'].$.niftyNoty({
            type: 'success',
            icon: 'fa fa-exclamation-triangle fa-2x',
            container: "floating",
            title: 'O feriado ' + _this.feriado.get('nome') + ' foi criado com sucesso.',
            timer: 3000
          });
        });
      },

      removerFeriado: function removerFeriado(feriado) {
        feriado.destroyRecord().then(function () {

          _ember['default'].$.niftyNoty({
            type: 'success',
            icon: 'fa fa-exclamation-triangle fa-2x',
            container: "floating",
            title: 'O feriado foi removido com sucesso.',
            timer: 3000
          });
        });
      },

      saveFeriado: function saveFeriado(feriado) {
        feriado.save();
      }
    }
  });
});