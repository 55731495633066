define("juridico-sengerj/components/route-components/form-contrato-escritorio", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    contrato: null,
    "on-saved": function onSaved() {},

    _tipoContrato: _ember["default"].computed.alias("contrato.tipo"),
    contratoValid: _ember["default"].computed.and("contrato.validations.isValid"),

    actions: {
      saveContrato: function saveContrato() {
        var _this = this;

        this.get("contrato").save().then(function (contrato) {
          _ember["default"].get(_this, "on-saved")(contrato);
        });
      }
    }
  });
});