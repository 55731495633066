define('juridico-sengerj/helpers/format-currency', ['exports', 'ember'], function (exports, _ember) {
    exports.formatCurrency = formatCurrency;

    function formatCurrency(params) {
        if (_ember['default'].isPresent(params)) {
            var n = params[0],
                c = isNaN(c = Math.abs(params[1])) ? 2 : c,
                d = ',',
                t = '.',
                i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + '',
                j = (j = i.length) > 3 ? j % 3 : 0;

            return 'R$' + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : '');
        }
    }

    exports['default'] = _ember['default'].Helper.helper(formatCurrency);
});