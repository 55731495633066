define("juridico-sengerj/utils/format/date/full", ["exports"], function (exports) {
  exports["default"] = formatDateFull;

  function formatDateFull(date) {
    if (date == null) {
      date = new Date();
    }

    var twoDigit = function twoDigit(string) {
      return ("0" + string.toString()).slice(-2);
    };

    return twoDigit(date.getDate()) + "/" + twoDigit(date.getMonth() + 1) + "/" + date.getFullYear() + " " + twoDigit(date.getHours()) + "h:" + twoDigit(date.getMinutes()) + "min";
  }
});